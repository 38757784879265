import React, { useEffect, useState } from "react";
import "./discoveryCoaches.scss";
import { useNavigate } from "react-router-dom";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import { Box, CircularProgress, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  discoverySlice,
  setCurrentAssessmentData,
} from "../../redux/features/discoverySlice";
import customAxios from "../../utils/customAxios";
import { Button, Typography } from "@mui/material";
import { display, textAlign } from "@mui/system";

export default function DiscoveryAndCoaches() {
  const [dashboardData, setDashboardData] = useState([]);
  const [percentageData, setPercentageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const getAssessmentData = async () => {
    // console.log(sessionStorage.getItem('cohortId'),"cohortId")
    // if(sessionStorage.getItem('cohortId') === (null || undefined) || sessionStorage.getItem('cohortId') === ("null" || "undefined"))
    // return;
    try {
      setLoading(true);
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.findAllAssesmentMasterList,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          business_owner_id: sessionStorage.getItem("userId"),
        },
      });
      if (res.data.statusCode === 200) {
        setDashboardData(res?.data?.result?.assesmentDetails);
        setPercentageData(res?.data?.result?.assessmentPercentage);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong.");
      console.log(error);
    }
  };

  const getAssessmentVersion = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.findAllAssesmentVersionControlListing,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          business_owner_id: sessionStorage.getItem("userId"),
          assesment_id: sessionStorage.getItem("assessmentId"),
        },
      });
      if (res.data.statusCode === 200) {
        let saveVersion =
          Object.values(res.data.result).length === 0
            ? 1
            : Object.values(res.data.result).length;
        sessionStorage.setItem("version", saveVersion);
        console.log(saveVersion);
        navigate(
          `/discovery-coaches/${sessionStorage.getItem("assessmentId")}`
        );

        // sessionStorage.setItem(
        //   "is_report_generated",
        //   res.data.result["1"][0]?.is_report_generated === true ? 1 : 0
        // );
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserDetails = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getUserDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        setUserDetails(res?.data?.result);
        console.log(res?.data?.result, "resssssss");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
    getAssessmentData();
  }, []);

  const evaluatePercentage = (id, i) => {
    const foundElement = percentageData.filter((element) => {
      if (element?.assesmentMasterId === id) {
        return element;
      }
    });
    console.log(foundElement);
    if (foundElement?.length > 0) {
      if (foundElement[0]?.calculatedPercentage > 99) {
        return 100;
      } else if (foundElement[0]?.calculatedPercentage === 0 || null) {
        return 0;
      } else {
        return foundElement[0]?.calculatedPercentage?.toFixed(0);
      }
    }

    // return null;
  };

  const evaluateDataToFilter = (id, i) => {
    const foundElement = percentageData.filter((element) => {
      if (element?.assesmentMasterId === id) {
        return element;
      }
    });

    if (foundElement[0]?.version_control_count?.version_control == 0) {
      return false;
    }
    return true;
  };

  return (
    <div
      style={{
        backgroundColor: currentTheme === "DARK" ? "#151515" : "#fff",
        // height: "100vh",
      }}
    >
      {" "}
      <Container maxWidth="xl">
        <div className="discoveryWrapper">
          <div className="header">
            <h2 style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}>
              Discovery & Coaches
            </h2>
            <p style={{ color: currentTheme === "DARK" ? "#a3a3a3" : "#000" }}>
              Core Business Assessment
            </p>
          </div>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="cards">
              {dashboardData.length > 0 &&
                dashboardData
                  .filter((item) => {
                    return item?.is_active;
                  })
                  .map((item, i) => {
                    return (
                      <div
                        style={{
                          backgroundColor:
                            currentTheme === "DARK" ? "#000" : "#fff",
                        }}
                        className="card"
                      >
                        <div className="inner1">
                          <span></span>
                          <p
                            style={{
                              color: currentTheme === "DARK" ? "#FFF" : "#000",
                              wordBreak: "break-all",
                            }}
                          >
                            {item?.assesment_name}
                          </p>
                        </div>
                        <div className="inner2">
                          <div>
                            <span
                              style={{
                                width: `${evaluatePercentage(item.id, i)}%`,
                              }}
                            ></span>
                          </div>
                          {evaluatePercentage(item?.id, i) === 0 && (
                            <p
                              style={{
                                color:
                                  currentTheme === "DARK" ? "#FFF" : "#000",
                              }}
                            >
                              Your assessment has not been started yet.
                            </p>
                          )}
                          {evaluatePercentage(item?.id, i) > 0 && (
                            <p
                              style={{
                                color:
                                  currentTheme === "DARK" ? "#FFF" : "#000",
                              }}
                            >{`Your assessment has been ${evaluatePercentage(
                              item?.id,
                              i
                            )}% completed.`}</p>
                          )}
                          {/* {percentageData[i]?.calculatedPercentage === 100 && (
                    <p>Your assessment has been completed</p>
                  )} */}
                        </div>
                        <div className="inner3">
                          {evaluateDataToFilter(item?.id, i) && (
                            <button
                              style={{
                                color:
                                  currentTheme === "DARK" ? "#FFF" : "#000",
                                background: "transparent",
                                borderColor: "#2b1053",
                              }}
                              onClick={() => {
                                sessionStorage.setItem(
                                  "assessmentId",
                                  item?.id
                                );
                                sessionStorage.setItem("fromPage", true);
                                navigate(`/core-business-document`, {
                                  state: {
                                    assessmentName: item?.assesment_name,
                                  },
                                });
                              }}
                            >
                              View
                            </button>
                          )}

                          <button
                            onClick={() => {
                              // navigate("/discovery-coaches/assessment-versions");
                              sessionStorage.setItem("fromPage", true);
                              sessionStorage.setItem("assessmentId", item?.id);
                              getAssessmentVersion();
                              sessionStorage.setItem(
                                "percentage",
                                evaluatePercentage(item?.id, i)
                              );
                            }}
                            style={{
                              width: !evaluateDataToFilter(item?.id, i)
                                ? "57%"
                                : "100%",
                              margin: "auto",
                            }}
                          >
                            {evaluateDataToFilter(item?.id, i)
                              ? "Update "
                              : "Create "}
                            Now
                          </button>
                        </div>
                      </div>
                    );
                  })}
              <div></div>

              {dashboardData.filter((item) => item.is_active).length === 0 && (
                <div
                  style={{
                    margin: "auto",
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "600",
                    color: currentTheme === "DARK" ? "#fff" : "#000",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50%",
                    }}
                  >
                    <img src="/images/nodata.png" alt="logo" />
                  </Box>
                  {userDetails.cohort_id === null ||
                  userDetails.cohort_id === "null"
                    ? "No cohort assigned yet"
                    : "No assessment found for this cohort !"}
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

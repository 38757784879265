import React, { useState, useEffect } from "react";
import { ApiConfig } from "../../services/ApiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "./common.scss";
import { Box, Button, Typography, CircularProgress, Backdrop, IconButton, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
export default function VideoUpload({
    formField,
    setAnswersDetails,
    answersDetails,
    key,
    cohortName,
    disabled,
    customFormName,
    serialNumber
}) {
    const currentTheme = useSelector((state) => state.themeSwitcher.mode);
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const existingVideo = answersDetails[formField?.id]?.answer;
        if (existingVideo) {
            setVideos(existingVideo.split(";"));
        }
    }, [answersDetails, formField?.id]);
    const fileUploader = async (file) => {
        if (disabled) return;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("cohortName", cohortName);
        formData.append("formName", customFormName);
        setIsLoading(true);
        try {
            const res = await axios({
                method: "POST",
                url: ApiConfig.uploadImage,
                headers: {
                    token: sessionStorage.getItem("userToken"),
                },
                data: formData,
            });
            if (res?.data?.statusCode === 200) {
                toast.success("Video Uploaded Sucessfully")
                return res?.data?.result;
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
        finally {
            setIsLoading(false);

        }
    };
    const handleVideoChange = async (e) => {
        if (disabled) return;
        const selectedFiles = Array.from(e.target.files);

        const uploadedVideos = [];
        for (const file of selectedFiles) {
            const videoUrl = await fileUploader(file);
            if (videoUrl) {
                uploadedVideos.push(videoUrl);
            }
        }

        const allVideos = [...videos, ...uploadedVideos];
        setVideos(allVideos);
        const version = parseInt(sessionStorage.getItem("version"));
        setAnswersDetails({
            ...answersDetails,
            [formField?.id]: {
                question_id: formField?.id,
                assesment_id: formField?.assesment_master_id,
                assesment_category_id: formField?.assesment_category_,
                answer: allVideos.join(";"),
                category_id: formField?.category_id,
                version_control: version || formField?.version_control,
            },
        });
    };
    const handleRemoveVideo = (index) => {
        if (disabled) return;
        const updatedVideos = videos.filter((_, i) => i !== index);
        setVideos(updatedVideos);
        setAnswersDetails({
            ...answersDetails,
            [formField?.id]: {
                ...answersDetails[formField?.id],
                answer: updatedVideos.join(";"),
            },
        });
    };
    return (
        <Box sx={{ mt: 2 }}>
            {formField?.question && (
                            <p
  style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
  className="heading"
>
  <span
    style={{
      fontWeight: "bold",
      color: currentTheme === "DARK" ? "#FFD700" : "#000", 
      fontSize: "1.2em",
      marginRight: "8px",
    }}
  >
    {serialNumber + 1}.
  </span>
  {formField?.question}
</p>
            )}
            <Button
                variant="contained"
                component="label"
                startIcon={!isLoading && <CloudUploadIcon />} 
                sx={{
                    width: "100%",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    "&:hover": { backgroundColor: "#2c387e" },
                }}
                disabled={isLoading || disabled}
            >
             {isLoading ? (
      "  Uploading Videos..."
    ) : (
        "Upload Videos"
    )}
                <input
                    type="file"
                    accept="video/mp4"
                    multiple
                    onChange={handleVideoChange}
                    hidden
                />
            </Button>

            <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                {videos?.map((video, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box sx={{ position: "relative", width: "100%", height: "200px", border: "1px solid #ccc" }}>
                            <video
                                alt={`Uploaded ${index + 1}`}
                                src={video}
                                controls
                                autoPlay
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            />
                            <IconButton
                                disabled={disabled}
                                onClick={() => handleRemoveVideo(index)}
                                sx={{ position: "absolute", top: 5, right: 5, backgroundColor: "rgba(0,0,0,0.5)", color: "#fff" }}
                            >
                                <Delete />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

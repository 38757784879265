import React, { useEffect, useState } from "react";
import "./../discoveryCoaches/discoveryCoaches.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import { Box, CircularProgress, Container, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    discoverySlice,
    setCurrentAssessmentData,
} from "../../redux/features/discoverySlice";
import customAxios from "../../utils/customAxios";
import { Button, Typography } from "@mui/material";
import { display, textAlign } from "@mui/system";
export const AssessmentType = {
    ADD: "ADD",
    EDIT: "EDIT",
    VIEW: "VIEW",
};
export default function CustomFormCategories() {

    const location = useLocation();
    console.log("location: ", location);
    const cohortId = location.state?.data;
    const cohortName = location.state?.cohortName;
    const fullName = location.state?.fullName;
    const [percentageData, setPercentageData] = useState([]);
    console.log("percentageData: ", percentageData);
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentTheme = useSelector((state) => state.themeSwitcher.mode);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [customFormCategoryData, setCustomFormCategoryData] = useState([]);
    console.log("customFormCategoryData: ", customFormCategoryData);
    const getAllCustomFormCategories = async () => {
        try {
            setIsDataLoading(true);
            const res = await customAxios({
                method: "GET",
                url: ApiConfig.getAllCustomFormCategories,
                headers: {
                    token: sessionStorage.getItem("userToken"),
                },
                params: {
                    cohort_id: cohortId,
                    page: currentPage,
                    limit: rowsPerPage,
                },
            });

            if (res?.data?.statusCode === 200) {
                setCustomFormCategoryData(res?.data?.result?.formCategories || []);
                setPercentageData(res?.data?.result?.customForms?.answerPercentage || []);
                setTotalQuestions(res?.data?.result?.totalPages || 0);
            }
            setIsDataLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllCustomFormCategories();
    }, []);

    return (
        <div
            style={{
                backgroundColor: currentTheme === "DARK" ? "#151515" : "#fff",
            }}
        >
            {" "}
            <Container maxWidth="xl">
                <div className="discoveryWrapper">
                    <div className="header">
                        <h2 style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}>
                            Form Categories
                        </h2>
                    </div>
                    {loading ? (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "43vh",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="cards">
                            {customFormCategoryData.length > 0 &&
                                customFormCategoryData
                                    .filter((item) => {
                                        return item?.is_active;
                                    })
                                    .map((item, i) => {
                                        return (
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        currentTheme === "DARK" ? "#000" : "#fff",
                                                }}
                                                className="card"
                                            >
                                                <div className="inner1">
                                                        <span></span>
                                                    <p
                                                        style={{
                                                            color: currentTheme === "DARK" ? "#FFF" : "#000",
                                                            wordBreak: "break-all",
                                                        }}
                                                    >
                                                        {item?.categoryName}
                                                    </p>
                                                </div>
                                                <div className="inner2">
  <Tooltip title={item?.description || ""} placement="bottom" arrow>
    <p
      style={{
        color: currentTheme === "DARK" ? "#FFF" : "#000",
        wordBreak: "break-word",
        cursor: "pointer",
      }}
    >
      {item?.description?.length > 150
        ? `${item.description.slice(0, 150)}...`
        : item?.description}
    </p>
  </Tooltip>
</div>
                                                <div className="inner3">
                                                    <button
                                                        onClick={() => {
                                                            navigate(`/custom-forms-list`, {
                                                                state: {
                                                                    cohortId,
                                                                    cohortName,
                                                                    fullName,
                                                                    customFormCategoryTitle: item?.categoryName,
                                                                    customFormCategoryLogo: item?.categoryLogo,
                                                                    categoryId: item?.id,
                                                                    categoryDescription: item?.description,
                                                                },
                                                            });
                                                        }}
                                                        style={{
                                                            width: "100%",
                                                            margin: "auto",
                                                        }}
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                            <div></div>

                            {customFormCategoryData.filter((item) => item.is_active).length === 0 && (
                                <div
                                    style={{
                                        margin: "auto",
                                        fontSize: "20px",
                                        textAlign: "center",
                                        fontWeight: "600",
                                        color: currentTheme === "DARK" ? "#fff" : "#000",
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "50%",
                                        }}
                                    >
                                        <img src="/images/nodata.png" alt="logo" />
                                    </Box>
                                    {userDetails.cohort_id === null ||
                                        userDetails.cohort_id === "null"
                                        ? "No cohort assigned yet"
                                        : "No Custom form categories found for this cohort !"}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
}

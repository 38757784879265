import React, { useState, useEffect } from "react";
import { ApiConfig } from "../../services/ApiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import {
    Box,
    Button,
    Typography,
    CircularProgress,
    Backdrop,
    IconButton,
    Card,
    CardContent,
    CardActions,
    Avatar,
    Tooltip,
    Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Delete from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from "react-redux";
import "./common.scss";
import { Visibility } from "@mui/icons-material";
export default function DocumentUpload({
    formField,
    setAnswersDetails,
    answersDetails,
    serialNumber,
    disabled,
    customFormName,
    cohortName,

}) {
    const currentTheme = useSelector((state) => state.themeSwitcher.mode);
    const [documents, setDocuments] = useState([]);
    console.log("documents: ", documents);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const existingDocument = answersDetails[formField?.id]?.answer;
        if (existingDocument) {
            setDocuments(existingDocument.split(";"));
        }
    }, [answersDetails, formField?.id]);

    const fileUploader = async (file) => {
        if (disabled) return;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("cohortName", cohortName);
        formData.append("formName", customFormName);
        setIsLoading(true);
        try {
            const res = await axios({
                method: "POST",
                url: ApiConfig.uploadImage,
                headers: {
                    token: sessionStorage.getItem("userToken"),
                },
                data: formData,
            });
            if (res?.data?.statusCode === 200) {
                toast.success("Document Uploaded Successfully");
                return res.data.result;
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to upload document");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDocumentChange = async (e) => {
        if (disabled) return;
        const selectedFiles = Array.from(e.target.files);

        const uploadedDocs = [];
        for (const file of selectedFiles) {
            const documentUrl = await fileUploader(file);
            if (documentUrl) {
                uploadedDocs.push(documentUrl);
            }
        }

        const allDocs = [...documents, ...uploadedDocs];
        setDocuments(allDocs);

        const version = parseInt(sessionStorage.getItem("version"), 10);
        setAnswersDetails({
            ...answersDetails,
            [formField?.id]: {
                question_id: formField?.id,
                assesment_id: formField?.assesment_master_id,
                assesment_category_id: formField?.assesment_category_,
                answer: allDocs.join(";"),
                category_id: formField?.category_id,
                version_control: version || formField?.version_control,
            },
        });
    };

    const handleRemoveDocument = (index) => {
        if (disabled) return;
        const updatedDocs = documents.filter((_, i) => i !== index);
        setDocuments(updatedDocs);
        setAnswersDetails({
            ...answersDetails,
            [formField?.id]: {
                ...answersDetails[formField?.id],
                answer: updatedDocs.join(";"),
            },
        });
    };

    return (
        <Box sx={{ mt: 2 }}>
            {formField?.question && (
               <p
  style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
  className="heading"
>
  <span
    style={{
      fontWeight: "bold",
      color: currentTheme === "DARK" ? "#FFD700" : "#000", 
      fontSize: "1.2em",
      marginRight: "8px",
    }}
  >
    {serialNumber + 1}.
  </span>
  {formField?.question}
</p>

            )}
            <Button
                variant="contained"
                component="label"
                startIcon={!isLoading && <CloudUploadIcon />} 
                sx={{
                    width: "100%",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    "&:hover": { backgroundColor: "#2c387e" },
                      position: "relative", 
                }}
                disabled={isLoading || disabled}
            >
               {isLoading ? (
      "Uploading Documents..."
    ) : (
        "Upload Documents"
    )}
    <input
        type="file"
        accept=".pdf,.doc,.docx,.txt"
        multiple
        onChange={handleDocumentChange}
        hidden
    />
            </Button>

            <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                {documents?.map((doc, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card variant="outlined" sx={{ display: "flex", alignItems: "center", p: 1 }}>
                            <Avatar sx={{ bgcolor: "#3f51b5", width: 36, height: 36 }}>
                                <DescriptionIcon fontSize="small" />
                            </Avatar>
                            <Box sx={{ flexGrow: 1, ml: 1, overflow: "hidden" }}>
                                <Tooltip title={doc.split("/").pop().split("_").slice(1).join("_")} placement="top">
          <Typography variant="body2" noWrap>
            {doc.split("/").pop().split("_").slice(1).join("_")}
          </Typography>
        </Tooltip>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Tooltip title="View Document">
                                    <IconButton
                                        component="a"
                                        href={doc}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        size="small"
                                        sx={{ color: "primary.main" }}
                                    >
                                        <Visibility fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Document">
                                    <IconButton
                                        onClick={() => handleRemoveDocument(index)}
                                        disabled={disabled}
                                        size="small"
                                        sx={{ color: "error.main" }}
                                    >
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

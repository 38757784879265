import styled from "@emotion/styled";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Button,
  tableContainerClasses,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiConfig } from "../../services/ApiConfig";
import { Link } from "react-router-dom";
import { TonalitySharp } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { VisibilityOff, RemoveRedEye } from "@mui/icons-material";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import axios from "axios";
import platform from "platform";
import { DateTime } from "luxon";

const LoginWrapper = styled("div")({
  overflowY: "scroll",
  height: "100dvh",
  width: "45%",
  overflow: "hidden",
  marginTop: "170px",
  marginRight: "100px",

  "@media(max-width:1270px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    marginTop: "200px",
    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginTop: "120px",
    marginRight: "0px",
  },
  // backgroundColor: "yellow",

  "& .MuiCheckbox-root": {
    padding: "0px 2px 0px 9px",
  },
});

const FormContainer = styled(Form)({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",

  "@media(max-width:640px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const FormControlContainer = styled(FormControl)({
  marginTop: "20px",
  fontWeight: 400,
});

const FormTextField = styled(TextField)({
  height: "60px!important",
  width: "35vw",
  marginTop: "4px",
  borderRadius: "12px",
  fontWeight: 400,

  "@media(max-width:1024px)": {
    width: "50vw !important",
    // margin: "10px 0px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },
});

// const initialValues = {
//   email: initialEmail ? initialEmail : "",
//   password: "",
// };

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email address is required.")
    .matches(
      /^[a-zA-Z0-9._%+$-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address."
    )
    .min(8, "Email address must be at least 8 characters."),
  password: Yup.string().required("Password is required."),
});

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("🚀 ~ Login ~ locationsweewew:", location);
  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isRememberMeEnable, setisRememberMeEnable] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [initialEmail, setInitialEmail] = useState("");
  const [initialPassword, setInitialPassword] = useState("");
  const [sessionDetails, setSessionDetails] = useState({
    ip_address: "",
    login_from: "",
    location: "",
    source: "web",
  });
  console.log("🚀 ~ Login ~ sessionDetails:", sessionDetails);
  const [errorMessage, setErrorMessage] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [errorMessage1, setErrorMessage1] = useState("");
  function rememberMe() {
    if (!isRememberMe) {
      setIsRememberMe(true);
      const email = document.getElementById("email");
      const password = document.getElementById("password");
      window.sessionStorage.setItem("email", email?.value);
      window.sessionStorage.setItem("password", password?.value);
    } else {
      setIsRememberMe(false);
      window.sessionStorage.removeItem("email");
      window.sessionStorage.removeItem("password");
    }
  }

  useEffect(() => {
    if (window.sessionStorage.getItem("email")) {
      setIsRememberMe(true);
    } else {
      setIsRememberMe(false);
    }
  }, [window.sessionStorage.getItem("email")]);
  async function getBrowserAndLocation() {
    const browserName = detectBrowser();
    console.log(browserName, "browserName is trgierrign again n again");
    const ipAddress = await getIpAddress();
    console.log(ipAddress, "ipAddress");
    setSessionDetails({
      ...sessionDetails,
      ip_address: ipAddress,
      login_from: browserName,
    });
  }
  async function getIpAddress() {
    const response = await fetch("https://api64.ipify.org?format=json");
    const data = await response.json();
    console.log("Koca: data ", data);
    return data.ip;
  }

  const userAgent = navigator.userAgent;
  console.log(userAgent, "userAgent");

  function detectBrowser() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Chrome")) {
      return "Google Chrome";
    } else if (userAgent.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (userAgent.includes("Safari")) {
      return "Apple Safari";
    } else if (userAgent.includes("Edge")) {
      return "Microsoft Edge";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
      return "Internet Explorer";
    } else {
      return "Unknown Browser";
    }
  }

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        return reject(
          new Error("Geolocation is not supported by this browser.")
        );
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const getDetailedLocation = async (latitude, longitude) => {
    try {
      console.log("Fetching detailed location...");

      // Make an API request to get location details based on lat/lon
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );

      const address = response.data.address;

      // Extract relevant location details like suburb, city, and state
      const suburb = address.suburb || address.village || "";
      const cityDistrict = address.city_district || "";
      const city = address.city || address.town || address.state_district || "";
      const state = address.state || "";
      const country = address.country || "";

      // Format the location to show only relevant details
      let location = "";

      if (suburb) {
        location = suburb; // e.g., Okhla Phase 1
      }
      if (city) {
        location = `${location ? location + ", " : ""}${city}`; // e.g., Okhla Phase 1, South East Delhi
      }
      if (state) {
        location = `${location ? location + ", " : ""}${state}`; // e.g., Okhla Phase 1, South East Delhi, Delhi
      }
      if (country) {
        location = `${location ? location + ", " : ""}${country}`; // e.g., Okhla Phase 1, South East Delhi, Delhi
      }

      console.log("Formatted Location:", location);

      return location; // Shortened location format
    } catch (error) {
      console.error("Error fetching detailed location:", error);
      return null;
    }
  };

  const getCountryAndBrowser = async () => {
    try {
      console.log(
        "getCountryAndBrowsergetCountryAndBrowsergetCountryAndBrowser"
      );
      // Get the user's timezone
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log(currentTimezone, "currentTimezone"); // Store the timezone in sessionStorage
      sessionStorage.setItem("timeZone", currentTimezone);
      const browser = platform?.name;
      const position = await getCurrentLocation();
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      const detailedLocation = await getDetailedLocation(latitude, longitude);
      console.log("🚀 ~ getCountryAndBrowser ~ browser:", browser);
      console.log("Location:", detailedLocation);
      if (detailedLocation) {
        setSessionDetails((prevDetails) => ({
          ...prevDetails,
          login_from: browser,
          location: detailedLocation,
          timezone: currentTimezone,
        }));
      } else {
        console.log("Failed to retrieve detailed location.");
      }
    } catch (error) {
      console.log("Error fetching location:", error.message);
    }
  };
  useEffect(() => {
    const initializeSessionDetails = async () => {
      await getBrowserAndLocation(); // Get browser and IP
      await getCountryAndBrowser(); // Get country and browser
    };

    initializeSessionDetails(); // Initialize session details on component mount
  }, [location.pathname]);

  const loginHandler = async (values) => {
    setLoading(true);
    const currentTimezone = sessionStorage.getItem("timeZone");
    console.log(currentTimezone, "currentTimezone");

    // Get the current date and time in the specified time zone
    const currentDate = DateTime.now().setZone(currentTimezone);
    console.log(currentDate.toISO(), "currentDateISO");

    // Convert the date to the user's timezone (dynamic)
    const userTimeZoneDate = currentDate.setZone(currentTimezone);
    const formattedDate = userTimeZoneDate.toFormat("yyyy-MM-dd HH:mm:ss ZZZZ");
    console.log(formattedDate, "formattedDate in user's timezone");

    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userLogin,
        data: {
          userEmail: values?.email?.toLowerCase(),
          password: values?.password,
          login_from: sessionDetails?.login_from,
          ip_address: sessionDetails?.ip_address
            ? sessionDetails?.ip_address
            : "",
          location: sessionDetails?.location
            ? sessionDetails?.location
            : "Location Permission Not Granted",
          source: sessionDetails?.source,
          date_time: formattedDate,
        },
      });
      console.log("response", res);
      if (res?.data?.statusCode === 200) {
        if (res?.data.result.status === "BLOCKED") {
          // Display error message if user is blocked
          toast.error("This business owner has been blocked by the admin.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          navigate("/");
        } else if (res.data.result.user_type === "BUSINESS_OWNER") {
          if (
            res?.data?.result?.is_email_2fa === true ||
            res?.data?.result?.is_sms_2fa === true
          ) {
            sessionStorage.removeItem("userToken");

            navigate("/otp", {
              state: {
                email: values?.email,
                otp: values?.otp,
                status: "2faemail",
                data: res?.data?.result,
              },
            });
          } else {
            if (!res?.data?.result?.cohort_exist) {
              navigate("/my-cohort");
            } else navigate("/dashboard");
          }
          sessionStorage.setItem("userToken", res?.data?.result.session_token);
          sessionStorage.setItem("userId", res.data.result.user_id);
          sessionStorage.setItem("userType", res.data.result.user_type);
          sessionStorage.setItem("userEmail", res.data.result.email);
          sessionStorage.setItem("userMobille", res.data.result.mobile);
          sessionStorage.setItem("cohort", res.data.result.cohort_exist);
          sessionStorage.setItem(
            "userUniqueId",
            res.data.result.user_unique_id
          );
          console.log(res?.data?.result, "idddd");
          sessionStorage.setItem("user", JSON.stringify(res.data.result));
          setLoading(false);
        } else {
          setLoading(false);
          // toast.error("User not found.");
        }
      } else {
        setLoading(false);
        setErrorMessage(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      console.log("response", error?.response?.data);
      if (error?.response?.data?.responseCode === 402) {
        console.log("dataerror");
        if (error?.response?.data?.responseMessage === "email not verified.") {
          console.log("login");
          navigate("/otp", {
            state: { email: values?.email, otp: values?.otp, type: "signup" },
          });
        } else {
          setErrorMessage("Incorrect login credentials provided.");
          setLoading(false);
        }
      } else if (error?.response?.data?.responseCode === 404) {
        if (
          error?.response?.data?.responseMessage ===
          "Sorry, this email doesn't exist in our system. Please enter your registered email."
        ) {
          setErrorMessage1(
            "Sorry, this email doesn't exist in our system. Please enter your registered email."
          );
          setLoading(false);
        }
      } else {
        setLoading(false);
        setErrorMessage(
          error?.response?.data?.responseMessage ||
            "Something went wrong. Please try again."
        );
      }
    }
  };

  const preFillValueHandler = () => {
    if (JSON.parse(sessionStorage.getItem("cred"))) {
      setInitialEmail(JSON.parse(sessionStorage.getItem("cred")));
      console.log(JSON.parse(sessionStorage.getItem("cred")));
      console.log(initialEmail);

      // setInitialPassword(JSON.parse(sessionStorage.getItem("cred"))?.password);
      // setisRememberMeEnable(true);
    }
  };

  useEffect(() => {
    preFillValueHandler();
  }, [sessionStorage.getItem("cred")]);

  return (
    <LoginWrapper
      style={{
        backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
      }}
    >
      <Typography
        color={currentTheme == "DARK" ? "#fff" : "#000"}
        style={{ textAlign: "left" }}
        variant="h4"
        fontWeight={"500"}
      >
        Welcome Back
      </Typography>
      <Typography
        color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
        sx={{
          textAlign: "left",
          fontSize: "18px",
        }}
        variant="body1"
        fontWeight={"400"}
        mt={2}
      >
        Don't have an account?{" "}
        <Link style={{ textDecoration: "none" }} to="/signup">
          <span
            style={{
              color: "#523168",
              fontWeight: "600",
              color: currentTheme == "DARK" ? "#fff" : "#523168",
            }}
          >
            SignUp
          </span>
        </Link>
      </Typography>

      <Formik
        initialValues={{
          password: window.sessionStorage.getItem("password")
            ? window.sessionStorage.getItem("password")
            : "",
          email: window.sessionStorage.getItem("email")
            ? window.sessionStorage.getItem("email")
            : "",
        }}
        validationSchema={validationSchema}
        enableReinitialize={
          window.sessionStorage.getItem("password") ? true : false
        }
        onSubmit={(values) => {
          loginHandler(values);
        }}
      >
        {({ errors, touched, handleChange, handleBlur, values }) => (
          <FormContainer>
            <FormControlContainer>
              <label
                style={{
                  textAlign: "left",
                  fontSize: "18px",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                Email
              </label>
              <FormTextField
                style={{ backgroundColor: "#fff", height: "20px" }}
                value={values?.email}
                inputProps={{
                  maxLength: 60,

                  style: {
                    width: "500px",
                    marginBottom: "0px",
                  },
                }}
                onInput={(e) => {
                  let value = e.target.value;

                  value = value.replace(/\s/g, "");

                  e.target.value = value.toLowerCase();
                  setErrorMessage("");
                  setErrorMessage1("");
                  window.sessionStorage.setItem("email", e.target.value);
                }}
                variant="outlined"
                name="email"
                id="email"
                placeholder="Enter your email address"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
              />
            </FormControlContainer>
            <Typography
              sx={{
                textAlign: "left",
                color: "#d32f2f",
                fontSize: "14px",
                width: "35vw",
              }}
              fontWeight={"500"}
            >
              {errorMessage1}
            </Typography>
            <FormControlContainer>
              <label
                style={{
                  textAlign: "left",
                  fontSize: "18px",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                Password
              </label>
              <FormTextField
                style={{ backgroundColor: "#fff", height: "20px" }}
                inputProps={{
                  style: {},
                  maxLength: 20,
                }}
                onInput={(e) => {
                  const regex = /^[^\s]*$/;

                  if (!regex.test(e.target.value)) {
                    e.target.value = e.target.dataset.previousValue || "";
                  } else {
                    e.target.dataset.previousValue = e.target.value;
                  }
                  setErrorMessage("");
                  setErrorMessage1("");
                  window.sessionStorage.setItem("password", e.target.value);
                }}
                value={values.password}
                variant="outlined"
                name="password"
                id="password"
                type={isHidden ? "text" : "password"}
                placeholder="Enter password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsHidden((p) => !p)}>
                        {isHidden ? <RemoveRedEye /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControlContainer>
            <Typography
              sx={{
                textAlign: "left",
                color: "#d32f2f",
                fontSize: "14px",
                width: "35vw",
              }}
              fontWeight={"500"}
            >
              {errorMessage}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                // width: "400px",
                width: "35vw",
                "@media(max-width:1024px)": {
                  width: "50vw !important",
                  // margin: "10px 0px",
                },
                "@media(max-width:434px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color: "#C7340D",
                        padding: "0px, 2px 0px 9px",
                        color: currentTheme == "DARK" ? "#fff" : "#C7340D",
                      }}
                      checked={isRememberMe}
                      onChange={() => rememberMe()}
                    />
                  }
                  label={
                    <span
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      Remember me
                    </span>
                  }
                />
              </Box>

              <Typography
                component={Button}
                variant="body1"
                color={"#523168"}
                fontWeight={"500"}
                sx={{
                  textDecoration: "underline",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#523168",
                }}
                onClick={() => {
                  console.log("adfaf");
                  navigate("/forgetpassword");
                }}
              >
                Forgot Password?
              </Typography>
            </Box>
            <Box
              mt={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: { lg: "90%" },
              }}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  borderRadius: "10px",
                  fontSize: "18px",
                  paddingX: "40px",
                  paddingY: "10px",

                  textTransform: "none",
                }}
                disabled={loading}
                variant="contained"
                type="submit"
              >
                Login Now
                {loading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      marginLeft: "10px",
                      color: "white",
                    }}
                  />
                )}
              </Button>
            </Box>
          </FormContainer>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default Login;

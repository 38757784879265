import "./App.scss";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Home from "./pages/home";

import MainLayout from "./layout/MainLayout";
import LoginLayout from "./layout/LoginLayout";
import Forget from "./pages/auth/Forget";
import OtpVerification from "./pages/auth/OtpVerification";
import ResetPassword from "./pages/auth/ResetPassword";
import SignUp from "./pages/auth/SignUp";
import SignUpLayout from "./layout/SignUpLayout";
import AboutUs from "./pages/guestUser/AboutUs";
import Faq from "./pages/guestUser/Faq";
import DataComponent from "./pages/guestUser/DataComponent";
import ContactUs from "./pages/guestUser/ContactUs";
import Accelarator from "./pages/guestUser/Accelarator";
import PartnerProjects from "./pages/guestUser/PartnerProjects";
import Guestuserhome from "./pages/guestUser/Guestuserhome";
import AssessmentPage from "./pages/discoveryCoaches/assessment/AssessmentPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CoreBusinessDocument from "./pages/coreBusinessDocument/CoreBusinessDocument";
import Login from "./pages/auth/Login.jsx";
import AllCategory from "./pages/allCategory/AllCategory";
import MyProfile from "./pages/coreProfile/MyProfile";
import PasswordSecurity from "./pages/coreProfile/passwordAndSecurity/PasswordSecurity";
import FindingFunders from "./pages/findingFunders/FindingFunders";
import FundersList from "./pages/findingFunders/fundersList/FundersList";
import FundersAssessment from "./pages/findingFunders/funderAssessment/FundersAssessment";
import SessionManagemant from "./pages/coreProfile/sessionManagement/SessionManagemant";
import CoreBusinessInfo from "./pages/coreProfile/coreBusinessInformation/CoreBusinessInfo";
import ScheduleAndCall from "./pages/actionplanning/ScheduleAndCall";
import CohortLayout from "./layout/CohortLayout";
import Cohort from "./pages/cohort/Cohort";
import CohortCategory from "./pages/cohort/CohortCategory";
import MasterMindCommunity from "./pages/community/MasterMindCommunity";
import GuestCohort from "./pages/guestUser/GuestCohort";
import LearningLayout from "./layout/LearningLayout";
import LearningLives from "./pages/learningLives/LearningLives";
import GuestMasterMindCommunity from "./pages/guestUser/GuestMasterMindCommunity.js";
import { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AuthGuard from "./routes/authGuard.js";
import "overlayscrollbars/overlayscrollbars.css";
import customAxios from "./utils/customAxios.jsx";
import JoinCourseDetail from "./pages/learningLives/JoinCourseDetail.jsx";
import CourseModuleView from "./pages/learningLives/CourseModuleView.jsx";
import CousreVideos from "./pages/learningLives/CousreVideos.jsx";
import QuizManagement from "./pages/learningLives/QuizManagement";
import CohortPosts from "./pages/cohort/CohortPosts";
import { useSelector } from "react-redux";
import Surveymanagement from "./pages/cohort/Surveymanagement";
import CohortAssessmentManagement from "./pages/cohort/CohortAssessmentManagement";
import MyCohortTopics from "./pages/cohort/MyCohortTopics";
import MyCohortPosts from "./pages/cohort/MyCohortPosts";
import QuizResponse from "./pages/learningLives/QuizResponse";
import PdfView from "./pages/learningLives/PdfView";
import MonitorMeasure from "./pages/monitorMeasure";
import MonitorAssessment from "./pages/monitorMeasure/MonitorAssessment";
import AdvisorChat from "./pages/advisorChat/AdvisorChat";
import VersionHistory from "./pages/monitorMeasure/versionHistory";
import MonitorAssessmentPage from "./pages/monitorMeasure/MonitorAssessmentPage";
import MonitorrMeasureStatistics from "./pages/monitorMeasure/MonitorrMeasureStatistics";
import Header from "./components/header/Header";
import LogoutTimer from "./components/LogoutTimer.jsx";
import CreateHBOForm from "./pages/HBO/CreateHBOForm.jsx";
import CustomFormTab from "./pages/HBO/CustomFormTab.jsx";
import DiscoveryAndCoaches from "./pages/discoveryCoaches/index.js";
import CustomFormCategories from "./pages/HBO/CustomFormCategories.jsx";

//
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "outfit",
    },
  },
});

function App() {
  const getIpAddress = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: "http://ip-api.com/json",
      });
      if (res?.data) {
        localStorage.setItem("ip", JSON.stringify(res?.data?.query));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("ip")) {
      getIpAddress();
    }
  }, []);

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <LogoutTimer />
            <Routes>
              {/* Non-protected routes */}
              <Route
                path="/login"
                element={
                  <LoginLayout>
                    <Login />
                  </LoginLayout>
                }
              />
              <Route
                path="/forgetpassword"
                element={
                  <LoginLayout>
                    <Forget />
                  </LoginLayout>
                }
              />
              <Route
                path="/otp"
                element={
                  <LoginLayout>
                    <OtpVerification />
                  </LoginLayout>
                }
              />
              <Route
                path="/resetPassword"
                element={
                  <LoginLayout>
                    <ResetPassword />
                  </LoginLayout>
                }
              />

              <Route
                path="/signUp"
                element={
                  <SignUpLayout>
                    <SignUp />
                  </SignUpLayout>
                }
              />
              <Route
                index
                element={
                  <MainLayout>
                    <Guestuserhome />
                  </MainLayout>
                }
              />
              <Route
                path="/aboutus"
                element={
                  <MainLayout>
                    <AboutUs />
                  </MainLayout>
                }
              />
              <Route
                path="/termsandconditions"
                element={
                  <MainLayout>
                    <DataComponent heading="termsAndCondition" />
                  </MainLayout>
                }
              />
              <Route
                path="/privacypolicy"
                element={
                  <MainLayout>
                    <DataComponent heading="privacyPolicy" />
                  </MainLayout>
                }
              />
              <Route
                path="/cookiespolicy"
                element={
                  <MainLayout>
                    <DataComponent heading="cookiesPolicy" type={true} />
                  </MainLayout>
                }
              />
              <Route
                path="/faq"
                element={
                  <MainLayout>
                    <Faq />
                  </MainLayout>
                }
              />
              <Route
                path="/contactus"
                element={
                  <MainLayout>
                    <ContactUs />
                  </MainLayout>
                }
              />
              <Route
                path="/accelarator"
                element={
                  <MainLayout>
                    <Accelarator />
                  </MainLayout>
                }
              />
              <Route
                path="/categories"
                element={
                  <MainLayout>
                    <AllCategory />
                  </MainLayout>
                }
              />
              <Route
                path="/partners"
                element={
                  <MainLayout>
                    <PartnerProjects />
                  </MainLayout>
                }
              />
              <Route
                path="/guestuser"
                element={
                  <MainLayout>
                    <Guestuserhome />
                  </MainLayout>
                }
              />
              <Route
                path="/cohort"
                element={
                  <CohortLayout>
                    {" "}
                    <GuestCohort />{" "}
                  </CohortLayout>
                }
              />
              <Route
                path="/guest-community/:id"
                element={
                  <MainLayout>
                    <GuestMasterMindCommunity />
                  </MainLayout>
                }
              />

              {/* protected route */}

              <Route element={<AuthGuard />}>
                <Route
                  path="/dashboard"
                  element={
                    <MainLayout>
                      <Home />
                    </MainLayout>
                  }
                />
                <Route
                  path="/advisor-chat"
                  element={
                    <MainLayout>
                      <AdvisorChat />
                    </MainLayout>
                  }
                />

                <Route
                  path="discovery-coaches"
                  element={
                    <MainLayout>
                      <DiscoveryAndCoaches />
                    </MainLayout>
                  }
                />
                <Route
                  path="custom-form-categories"
                  element={
                    <MainLayout>
                      <CustomFormCategories />
                    </MainLayout>
                  }
                />
                <Route
                  path="custom-forms-list"
                  element={
                    <MainLayout>
                      <CustomFormTab />
                    </MainLayout>
                  }
                />
                <Route
                  path="discovery-coaches/:id"
                  element={
                    <MainLayout>
                      <AssessmentPage />
                    </MainLayout>
                  }
                />
                <Route
                  path="core-business-document"
                  element={
                    <MainLayout>
                      <CoreBusinessDocument />
                    </MainLayout>
                  }
                />
                <Route
                  path="findingFunders"
                  element={
                    <MainLayout>
                      <FindingFunders />
                    </MainLayout>
                  }
                />
                <Route
                  path="findingFunders/fundersAssessment"
                  element={
                    <MainLayout>
                      <FundersAssessment />
                    </MainLayout>
                  }
                />
                <Route
                  path="findingFunders/fundersAssessment/fundersList"
                  element={
                    <MainLayout>
                      <FundersList />
                    </MainLayout>
                  }
                />

                <Route
                  path="/my-profile"
                  element={
                    <MainLayout>
                      <MyProfile />
                    </MainLayout>
                  }
                />
                <Route
                  path="/core-business-information"
                  element={
                    <MainLayout>
                      <CoreBusinessInfo />
                    </MainLayout>
                  }
                />
                <Route
                  path="/session-management"
                  element={
                    <MainLayout>
                      <SessionManagemant />
                    </MainLayout>
                  }
                />
                <Route
                  path="/password-and-security"
                  element={
                    <MainLayout>
                      <PasswordSecurity />
                    </MainLayout>
                  }
                />

                <Route
                  path="/action-planning"
                  element={
                    <MainLayout>
                      <ScheduleAndCall />
                    </MainLayout>
                  }
                />
                <Route
                  path="/cohort-assessment"
                  element={
                    <MainLayout>
                      <CohortAssessmentManagement />
                    </MainLayout>
                  }
                />

                <Route
                  path="/my-cohort"
                  element={
                    <CohortLayout>
                      {" "}
                      <Cohort />{" "}
                    </CohortLayout>
                  }
                />

                <Route
                  path="/cohort-topics"
                  element={
                    <CohortLayout>
                      {" "}
                      <CohortCategory />{" "}
                    </CohortLayout>
                  }
                />
                <Route
                  path="/cohort-topics/my-topics"
                  element={
                    <CohortLayout>
                      {" "}
                      <MyCohortTopics />{" "}
                    </CohortLayout>
                  }
                />
                <Route
                  path="/cohort-posts"
                  element={
                    <CohortLayout>
                      {" "}
                      <CohortPosts />{" "}
                    </CohortLayout>
                  }
                />
                <Route
                  path="/cohort-posts/my-posts"
                  element={
                    <CohortLayout>
                      {" "}
                      <MyCohortPosts />{" "}
                    </CohortLayout>
                  }
                />
                <Route
                  path="/survey"
                  element={
                    <MainLayout>
                      {" "}
                      <Surveymanagement />{" "}
                    </MainLayout>
                  }
                />
                <Route
                  path="/createCustomForm"
                  element={
                    <MainLayout>
                      <CreateHBOForm />
                    </MainLayout>
                  }
                />
                <Route
                  path="/learning&lives"
                  element={
                    <LearningLayout>
                      <LearningLives />
                    </LearningLayout>
                  }
                />
                <Route
                  path="/learning-detail"
                  element={
                    <LearningLayout>
                      <JoinCourseDetail />
                    </LearningLayout>
                  }
                />
                <Route
                  path="/learning-detail/module"
                  element={
                    <LearningLayout>
                      <CourseModuleView />
                    </LearningLayout>
                  }
                />
                <Route
                  path="/learning-detail/module/videos"
                  element={
                    <LearningLayout>
                      <CousreVideos />
                    </LearningLayout>
                  }
                />
                <Route
                  path="/learning-detail/module/pdf"
                  element={
                    <LearningLayout>
                      <PdfView />
                    </LearningLayout>
                  }
                />
                <Route
                  path="/learning-detail/module/quizResponse"
                  element={
                    <LearningLayout>
                      <QuizResponse />
                    </LearningLayout>
                  }
                />

                <Route
                  path="/learning-detail/module/videos/quiz"
                  element={
                    <MainLayout>
                      <QuizManagement />
                    </MainLayout>
                  }
                />
                <Route
                  path="/community/:id"
                  element={
                    <>
                      <MainLayout>
                        {" "}
                        <MasterMindCommunity />
                      </MainLayout>{" "}
                      {/* <Header />
                      <MasterMindCommunity /> */}
                    </>
                  }
                />
                <Route
                  path="/monitor-measure/assessment/version-history"
                  element={
                    <MainLayout>
                      <VersionHistory />
                    </MainLayout>
                  }
                />
                <Route
                  path="/monitor-measure"
                  element={
                    <MainLayout>
                      <MonitorMeasure />
                    </MainLayout>
                  }
                />
                <Route
                  path="/monitor-measure/assessment"
                  element={
                    <MainLayout>
                      <MonitorAssessmentPage />
                    </MainLayout>
                  }
                />
                <Route
                  path="/monitor-measure/statististics"
                  element={
                    <MainLayout>
                      <MonitorrMeasureStatistics />
                    </MainLayout>
                  }
                />
              </Route>
            </Routes>

            <ToastContainer position="top-right" autoClose={1500} />
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react";
import PasswordResetModal from "../PasswordResetModal";

import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "./sidebar.scss";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BusinessIcon from "@mui/icons-material/Business";
import { Email, EmailOutlined } from "@mui/icons-material";
import "react-image-crop/dist/ReactCrop.css";
import CropImage from "../Crop/CropImage";
import PasswordEmailChangeModal from "../modal/PasswordEmailChangeModal";
import axios from "axios";

const Sidebar = ({
  profileImage,
  setProfileImage,
  display,
  sidebarRes,
  triggerSideBarUpdate,
}) => {
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isTestimonialModalOpen, setTestimonialModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [description, setDescription] = useState("");
  const imageFieldRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [imageBlob, setImageBlob] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [currentType, setCurrentType] = useState("email");
  const [changeModal, setChangeModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentData, setCurrentData] = useState("");

  let binaryData;
  let fileName = "testFile.png";
  const [psotImage64, setPsotImage64] = useState();

  const fileUploader = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log(formData);
    // const id = toast.loading("Uploading Media");
    setIsLoading(true);
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.uploadImage,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: formData,
      });
      if (res.data.statusCode === 200) {
        console.log(res?.data?.result, "abcdef");
        await updateImageDetails(res?.data?.result);
        setProfileImage(res?.data?.result);

        setIsLoading(false);
        setSelectedFile(null);

        // sessionStorage.setItem("userImageUrl", res.data.result);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (psotImage64) {
      if (typeof window !== "undefined") {
        binaryData = atob(psotImage64.split(",")[1]);
      } else {
        binaryData = Buffer.from(psotImage64.split(",")[1], "base64").toString(
          "binary"
        );
      }
      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: "image/png" }); // Adjust the type based on your image format
      // setImageBlob(blob);
      fileUploader(blob);
      // console.log("dfshdjksfhdsf", imageBlob);
      // Create a download link and trigger a click to download the file
      // const a = document.createElement("a");
      // a.href = URL.createObjectURL(blob);
      // a.download = fileName;
      // a.click();

      // console.log(`File '${fileName}' created successfully.`);
    }
  }, [psotImage64]);
  const handleOpenLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const handleOpenTestimonialModal = () => {
    setDescription("");
    setTestimonialModalOpen(true);
  };
  const sessionLogout = async () => {
    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.userLogout,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          session_id: userDetails?.id,
        },
      });
      if (res.data.status.flexDirectionde === 200) {
        // setUserDetails(res?.data?.result);
        // setRows(res?.data?.result);
        console.log(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseModals = () => {
    setLogoutModalOpen(false);
    setTestimonialModalOpen(false);
    setChangeModal(false);
    // You can perform additional actions on modal close if needed
  };
  const handleCloseTestimonialModal = () => {
    setTestimonialModalOpen(false);
    // You can perform additional actions on modal close if needed
  };

  const getUserDetails = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getUserDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        setUserDetails(res?.data?.result);
        sessionStorage.setItem("userId", res?.data?.result?.id);
        sessionStorage.setItem("customForm", res?.data?.result?.cohort?.is_custom_form);
        sessionStorage.setItem("navbarCompliance", res?.data?.result?.cohort?.is_navbarCompliance);
        sessionStorage.setItem("cohortCompliance", res?.data?.result?.cohort?.is_cohortCompliance);
        {
          res?.data?.result?.is_email_2fa == true
            ? sessionStorage.setItem("is_email_2fa", "true")
            : sessionStorage.setItem("is_email_2fa", "false");
        }
        {
          res?.data?.result?.is_sms_2fa == true
            ? sessionStorage.setItem("is_sms_2fa", "true")
            : sessionStorage.setItem("is_sms_2fa", "false");
        }
        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const giveTestimonials = async () => {
    setIsLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.createTestimonials,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          description: description,
          image_url: userDetails.image_url,
          designation: userDetails?.title,
          founder_name: userDetails?.first_name,
        },
      });
      if (res.data.statusCode === 200) {
        console.log(res, "abcdef");
        setIsLoading(false);
        // toast.success(res.data.responseMessage);
        handleCloseTestimonialModal();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateImageDetails = async (img_url) => {
    console.log(img_url, "ajshfa");
    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.updateUser,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          user_id: sessionStorage.getItem("userId"),
          image_url: img_url,
        },
      });
      if (res.data.statusCode === 200) {
        console.log(res);
        await getUserDetails();
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fileUploader = async (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   console.log(formData);
  //   // const id = toast.loading("Uploading Media");
  //   setIsLoading(true);
  //   try {
  //     const res = await customAxios({
  //       method: "POST",
  //       url: ApiConfig.uploadImage,
  //       headers: {
  //         token: sessionStorage.getItem("userToken"),
  //       },
  //       data: formData,
  //     });
  //     if (res.data.statusCode === 200) {
  //       console.log(res?.data?.result, "abcdef");
  //       await updateImageDetails(res?.data?.result);
  //       setProfileImage(res?.data?.result);

  //       setIsLoading(false);

  //       // sessionStorage.setItem("userImageUrl", res.data.result);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log(error);
  //   }
  // };

  // const fileUploader = async (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   try {
  //     const res = await customAxios.post(ApiConfig.uploadImage, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         token: sessionStorage.getItem("userToken"),
  //       },
  //     });

  //     if (res.data.statusCode === 200) {
  //       console.log(res, "Image uploaded successfully");
  //       // Add any additional logic after a successful upload
  //     }
  //   } catch (error) {
  //     console.error("Error uploading image", error);
  //   }
  // };

  // fileUploader();

  const emailUpdateHandler = (email) => {
    //
  };

  useEffect(() => {
    getUserDetails();
  }, [triggerSideBarUpdate]);

  return (
    <>
      {/* {!userDetails ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : ( */}

      {selectedFile && (
        <CropImage
          selectedFile={selectedFile && selectedFile}
          setCropData={setPsotImage64}
        // fileUploader={fileUploader}
        />
      )}
      <Box
        sx={{
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
          display: { display },

          overflow: "auto",
          alignItems: "center",
          gap: "2rem",
          flexDirection: "column",
          "@media(max-width:768px)": {
            ml: "0px",
          },
        }}
      >
        <div className="profile-sidebar">
          <div className="hero-section">
            {/* <img className="profile-img" src={userDetails.image_url} alt="" /> */}
            <Grid
              container
              item
              sm={12}
              lg={5}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Grid container item sm={12} lg={5} justifyContent={"center"}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <IconButton
                      disabled={isLoading}
                      style={{ background: "#523168" }}
                      onClick={() => {
                        imageFieldRef.current?.click();
                        console.log(imageFieldRef.current.files[0]);
                      }}
                      sx={{ color: "#fff" }}
                    >
                      <input
                        ref={imageFieldRef}
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) => {
                          console.log(e);
                          // fileUploader(imageFieldRef.current.files[0]);
                          setSelectedFile(imageFieldRef.current.files[0]);
                          imageFieldRef.current.value = null;
                        }}
                      />
                      <EditIcon />
                    </IconButton>
                  }
                >
                  <Avatar
                    alt="User Image"
                    className="profile-img"
                    src={
                      isLoading
                        ? // <Box>
                        //   {/* {toast.loading("Uploading Media", {
                        //     autoClose: 5000,
                        //   })} */}
                        //   <CircularProgress />
                        // </Box>
                        "https://media1.tenor.com/m/Tu0MCmJ4TJUAAAAC/load-loading.gif"
                        : userDetails?.image_url
                          ? userDetails?.image_url
                          : "/images/avatar.png"
                    }
                  />
                </Badge>
              </Grid>
              <FormHelperText
                sx={{
                  color: "#d12f2f",
                  mt: 2,
                }}
              >
                {/* {imageResolutionError} */}
              </FormHelperText>
            </Grid>
            <div
              style={{
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              className="owner-name"
            >
              {userDetails?.full_name ? userDetails?.full_name : "N/A"}
            </div>
            <div
              style={{
                color: currentTheme == "DARK" ? "#a3a3a3" : "#4d4d4d",
              }}
              className="owner-designation"
            >
              {userDetails?.title ? userDetails?.title : "N/A"}
            </div>
          </div>
          <div className="contact-details">
            <li style={{ listStyle: "none" }} className="contact-item">
              <BusinessIcon
                style={{ color: currentTheme == "DARK" ? "#9c9c9c" : "#000" }}
              />
              <span
                style={{
                  marginLeft: "10px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",

                  color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                }}
              >
                {userDetails.business_name ? userDetails.business_name : "--"}
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
              }}
              className="contact-item"
            >
              <LocalPhoneIcon
                style={{ color: currentTheme == "DARK" ? "#9c9c9c" : "#000" }}
              />

              <span
                style={{
                  marginLeft: "10px",
                  color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                }}
              >
                {/* {userDetails.mobile
                  ? `+${
                      userDetails.country_code ? userDetails.country_code : ""
                    }-${userDetails.mobile?.replace(
                      userDetails?.country_code,
                      ""
                    )}`
                  : "--"} */}
                {userDetails.country_code
                  ? `+${userDetails.country_code}-`
                  : ""}
                {userDetails.mobile
                  ? userDetails.mobile.replace(userDetails?.country_code, "")
                  : ""}
              </span>
              <IconButton
                disabled={isLoading}
                // style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                onClick={() => {
                  setChangeModal(true);
                  setCurrentType("mobile");
                  setCurrentData(userDetails?.mobile);
                  setCurrentMessage("Phone number update");
                }}
                sx={{
                  color: "#523168",
                  height: "26px",
                  width: "26px",
                  ml: "auto",
                }}
              >
                <EditIcon sx={{ height: "16px", width: "16px" }} />
              </IconButton>
            </li>
            <li
              style={{
                listStyle: "none",
              }}
              className="contact-item"
            >
              <EmailOutlined
                style={{ color: currentTheme == "DARK" ? "#9c9c9c" : "#000" }}
              />
              <span
                style={{
                  marginLeft: "10px",
                  marginLeft: "10px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                }}
              >
                {userDetails.email ? userDetails.email : "--"}
              </span>
              <IconButton
                disabled={isLoading}
                // style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                onClick={() => {
                  setChangeModal(true);
                  setCurrentType("email");
                  setCurrentData(userDetails?.email);
                  setCurrentMessage("Email Update");
                }}
                sx={{
                  color: "#523168",
                  height: "26px",
                  width: "26px",
                  ml: "auto",
                }}
              >
                <EditIcon sx={{ height: "16px", width: "16px" }} />
              </IconButton>
            </li>
          </div>
          <div className="bottom-div">
            <div
              onClick={
                // sessionStorage.removeItem("userToken");
                // navigate("/");
                handleOpenTestimonialModal
              }
              style={{
                border:
                  currentTheme == "DARK"
                    ? " 1px dashed #fff"
                    : " 1px dashed #000",
              }}
              className="give-testimonial"
            >
              <div>
                {" "}
                <img src="/images/manmessage.png" alt="" />
              </div>
              <h2
                style={{
                  color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                }}
              >
                Give Testimonial
              </h2>
            </div>
            <div
              onClick={handleOpenLogoutModal}
              style={{ cursor: "pointer" }}
              className="logout"
            >
              <div>
                {" "}
                <img src="/images/logout.png" alt="" />
              </div>

              <h2>LOGOUT</h2>
            </div>
          </div>
        </div>
        <PasswordResetModal
          type="logout"
          open={isLogoutModalOpen}
          onClose={handleCloseModals}
        />
        <PasswordResetModal
          isLoading={isLoading}
          type="testimonial"
          giveTestimonials={giveTestimonials}
          description={description}
          setDescription={setDescription}
          open={isTestimonialModalOpen}
          onClose={handleCloseModals}
        />
        <PasswordResetModal
          open={changeModal}
          type="emailChange"
          currentStatus={currentType}
          onClose={handleCloseModals}
        />
        {/* <PasswordEmailChangeModal
          open={changeModal}
          setOpen={setChangeModal}
          funcToExecute={emailUpdateHandler}
          message={currentMessage}
          type={currentType}
          currentData={currentData}
        /> */}
      </Box>
      {/* )} */}
    </>
  );
};

export default Sidebar;

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Menu,
  CircularProgress,
  Container,
  Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaRegCommentDots } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { IoMdMore } from "react-icons/io";
import "./cohortTypes.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TbMinusVertical } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
import dayjs from "dayjs";
import { ConfirmationNumber } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import EmojiPicker from "emoji-picker-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PasswordResetModal from "../../components/PasswordResetModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReportIcon from "@mui/icons-material/Report";
import DeleteIcon from "@mui/icons-material/Delete";
const myStyle = {
  width: "f70%",
  textAlign: "center",
  fontFamily: "Outfit",
  fontSize: "16px",
  fontWeight: 400,
  marginTop: "11px",
  color: "black",
};
const commentsCss = {
  width: "fit-content",
  fontFamily: "Outfit",
  fontSize: "16px",
};

function CohortPosts() {
  const [open, setOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isDeleteCommentPopupOpen, setIsDeleteCommentPopupOpen] =
    useState(false);
  const [isReportCommentPopupOpen, setIsReportCommentPopupOpen] =
    useState(false);

  const [selectDivision, setSelectDivision] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [loadingPost, setLoadingPost] = useState(false);
  const [postList, setPostList] = useState();
  // const [value, setValue] = useState("");
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState();
  const [openEmoji, setOpenEmoji] = useState(false);
  const [likes, setLikes] = useState(Array(postList?.length).fill(false));
  const navigate = useNavigate();
  const { state } = useLocation();
  const [inputStr, setInputStr] = useState("");
  const [anchorEl, setAnchorEl] = useState(false);
  const [postIdd, setPostIdd] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cohortIdd, setCohortIdd] = useState("");
  const [reportString, setReportString] = useState("");
  const [reportCommentString, setReportCommentString] = useState("");
  const [commentPostId, setCommentPostId] = useState("");
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [idx, setIdx] = useState();
  const [commentId, setCommentId] = useState("");
  const [topicId, setTopicId] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const cohortID = state.cohortId;
  console.log(state);

  const userID = sessionStorage.getItem("userId");

  const handleClick = (index) => {
    setAnchorEl(!anchorEl);
    setIdx(index);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(false);
  };

  const handleOpenLogoutModal = (id) => {
    console.log(id);
    setIsPopupOpen(true);
    setPostIdd(id);
  };

  const handleOpenDeleteModal = (id) => {
    console.log(id);
    setIsDeletePopupOpen(true);
    setPostIdd(id);
  };

  const handleCloseModals = () => {
    setIsPopupOpen(false);
    setIsDeletePopupOpen(false);
    setIsDeleteCommentPopupOpen(false);
    setIsReportCommentPopupOpen(false);
    // You can perform additional actions on modal close if needed
  };

  const toggleComments = (index) => {
    setSelectedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChangeSelect = (event) => {
    setSelectDivision(event.target.value);
  };

  const handleClickOpen = () => {
    setSelectedRowIndex(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    console.log("first", selectDivision);
  };

  // const toggleLike = (index) => {
  //   const newLikes = [...likes];
  //   newLikes[index] = !newLikes[index];
  //   setLikes(newLikes);
  // };

  const onEmojiClick = (event) => {
    setInputStr((prevInput) => prevInput + event.emoji);
    // setOpenEmoji(false);
  };
  const onEmojiClickComments = (event) => {
    setComment((prevInput) => prevInput + event.emoji);
    // setOpenEmoji(false);
  };

  const getCohortPosts = async () => {
    setIsLoading(true);
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllPost,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: { topic_id: state?.topicID, page: page, limit: 10 },
      });
      if (res.data.statusCode === 200) {
        setPostList(res?.data?.result?.data);
        setCohortIdd(res?.data?.result[0]?.cohort_id);
        setNoOfPages(res?.data?.result?.totalPages);
        setIsLoading(false);

        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setNoOfPages(1);
    }
  };

  const addCohortPosts = async () => {
    setLoadingPost(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.createPost,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          cohort_id: state?.cohortID,
          topic_id: state?.topicID,
          user_id: sessionStorage.getItem("userId"),
          description: inputStr,
        },
      });
      if (res.data.statusCode === 200) {
        await getCohortPosts();
        setLoadingPost(false);
        handleClose();
        setInputStr("");
        setOpenEmoji(!openEmoji);
      } else {
        setLoadingPost(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingPost(false);
    }
  };

  const getCohortComments = async (postId) => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllComment,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: { topic_id: state?.topicID, post_id: postId },
      });
      if (res.data.statusCode === 200) {
        setCommentList(res?.data?.result?.data);
        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addComments = async (postid) => {
    // setLoadingPost(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.addComment,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          cohort_id: state?.cohortID,
          topic_id: state?.topicID,
          user_id: sessionStorage.getItem("userId"),
          comment: comment,
          post_id: postid,
        },
      });
      if (res.data.statusCode === 200) {
        await getCohortPosts();
        await getCohortComments(postid);
        setComment("");
        // setLoadingPost(false);

        handleClose();
      } else {
        // setLoadingPost(false);
      }
    } catch (error) {
      console.log(error);
      // setLoadingPost(false);
    }
  };
  const addLike = async (postid, status) => {
    // setLoadingPost(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.likePost,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          topic_id: state?.topicID,
          user_id: sessionStorage.getItem("userId"),
          post_id: postid,
          is_like: status,
        },
      });
      if (res.data.statusCode === 200) {
        // toggleLike();
        await getCohortPosts();
        // setLoadingPost(false);
      } else {
        // setLoadingPost(false);
      }
    } catch (error) {
      console.log(error);
      // setLoadingPost(false);
    }
  };

  const reportPost = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.reportPost,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          post_id: postIdd,
          user_id: sessionStorage.getItem("userId"),
          message: reportString,
        },
      });

      if (res.data?.statusCode === 200) {
        toast.success("Post reported successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setReportString("");
        await getCohortPosts();
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const reportComment = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.reportComment,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          post_id: commentPostId,
          comment_id: commentId,
          topic_id: topicId,
          message: reportCommentString,
        },
      });

      if (res.data?.statusCode === 200) {
        toast.success("Comment reported successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        await getCohortComments(commentPostId);
        await getCohortPosts();
        setReportCommentString("");
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteComment = async () => {
    try {
      const res = await customAxios({
        method: "DELETE",
        url: ApiConfig.deleteComment,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          // post_id: commentPostId,
          comment_id: commentId,
        },
      });

      if (res.data?.statusCode === 200) {
        toast.success("Comment deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        await getCohortComments(commentPostId);

        await getCohortPosts();
        handleCloseModals();
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deletePost = async () => {
    try {
      const res = await customAxios({
        method: "DELETE",
        url: ApiConfig.deletePost,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          post_id: postIdd,
        },
      });
      console.log(res);
      handleCloseModals();
      getCohortPosts();
    } catch (error) {
      //   if (res.data?.statusCode === 200) {
      //     console.log(res);
      //     // toast.success("OTP sent successsfully.");
      //     // setLoading(false);
      //     // setErrorMessage("");
      //     // actions.resetForm({
      //     //   values: {
      //     //     oldPassword: "",
      //     //     password: "",
      //     //     confirmPassword: "",
      //     //   },
      //     // });
      //   } else {
      //     console.log("erroeeeeeerrrrrrrrrr");
      //     // setErrorMessage(res.data?.responseMessage || "Something went wrong");
      //     // setLoading(false);
      //     return null;
      //   }
      // }
      console.log(error);
      // setErrorMessage(
      //   error?.response?.data?.responseMessage || "Something went wrong"
      // );
      // setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  // console.log("noOfPages", noOfPages);
  const buttonStyle = {
    color: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.12)",
  };
  const PrevbuttonStyle = {
    color: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
  };
  const handleNext = () => {
    console.log("before", noOfPages, page);
    if (page < noOfPages) {
      setPage(page + 1); // Increment the page only if it's not the last page
      console.log("after", noOfPages, page);
    }
  };

  useEffect(() => {
    getCohortPosts();
  }, [page]);

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        width={"100vw"}
        height={"80vh"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
      >
        <CircularProgress
          sx={{ height: "180px", width: "180px", color: "#ce4920" }}
        />
      </Grid>
    );
  }

  return (
    <div
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      <Container maxWidth="lg">
        <div className="main-wrapper">
          <Grid
            container
            xs={12}
            spacing={2}
            style={{
              fill: "#FFF",
              strokeWidth: "1px",
              stroke: "rgba(82, 49, 104, 0.25)",
              // filter: 'drop-shadow(0px 9px 15px rgba(0, 0, 0, 0.15))',
              padding: "25px",
              width: "100%",
            }}
          >
            <Grid
              sx={{ display: "flex", alignItems: "center" }}
              item
              lg={6}
              sm={12}
              md={12}
              xs={12}
            >
              <ArrowBackIcon
                onClick={() => {
                  console.log(cohortIdd);
                  return navigate("/cohort-topics", {
                    state: {
                      cohortId: sessionStorage.getItem("cohortId"),
                      cohortName: sessionStorage.getItem("cohortName"),
                    },
                  });
                }}
                // onClick={() => navigate("/my-cohort")}
                style={{
                  margin: "0px 10px 0px 0px",
                  cursor: "pointer",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              />
              <Typography
                color={"#262626"}
                fontWeight={"600"}
                fontSize="30px"
                variant="h5"
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              >
                {/* {state?.topicName} -{" "} */}
                {state?.topicName
                  ? state?.topicName.length > 35 // Use '>' instead of '<'
                    ? state?.topicName?.slice(0, 35) + "..."
                    : state?.topicName
                  : "NA"}

                {/* <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginBottom: "10px",
                  }}
                >
                  Post
                </span> */}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              sm={12}
              md={12}
              xs={12}
              // display={"flex"}
              // justifyContent={"flex-end"}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                "@media (max-width: 600px)": { justifyContent: "flex-start" },
                "@media (max-width: 1200px) and (min-width: 770px)": {
                  justifyContent: "flex-start",
                },
              }}
            >
              <Button
                onClick={() =>
                  navigate("/cohort-posts/my-posts", { state: state })
                }
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  fontSize: "16px",
                  textTransform: "none",
                  borderRadius: "10px",
                  mr: 2,
                }}
                variant="contained"
              >
                My Posts
              </Button>
              <Button
                onClick={handleClickOpen}
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  fontSize: "16px",
                  textTransform: "none",
                  borderRadius: "10px",
                  width: "227px",
                }}
                variant="contained"
              >
                Create Your Post
              </Button>
            </Grid>
            <Divider
              style={{
                width: "100%",
                border: "1px solid #DFDFDF",
                margin: "20px 0px 0px 8px",
              }}
            />

            {/* <Grid container xs={12}>
                <Grid item xs={2}>
                    B
                </Grid>
                <Grid container xs={8}>
                    <Grid item xs={12}>alksjsfsb alskjdfn </Grid>
                    <Grid item xs={12}>alksjsfsb alskjdfn laksndf a sfkj baskjdf akjbsd kjak jsbd kasjdb aksjdbd akkkkkkkkkkkkkk</Grid>
                    <Grid item xs={12}>alksjsfsb alskjdfn  khgads</Grid>
                </Grid>
                <Grid container xs={2}>
                    <Grid item xs={12}>
                        <Box style={{ display: "flex", gap: "10px" }}>
                            <p>m </p>
                            <p>y </p>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>12/12/3333</Grid>
                    <Grid item xs={12}></Grid>
                </Grid>

            </Grid> */}

            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {postList?.length === 0 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {" "}
                      <Typography mt={4} variant="h6" fontWeight={800}>
                        No posts are available at the moment.
                      </Typography>
                    </Box>
                  )}
                  {postList &&
                    postList
                      ?.filter((item) => item.is_active === true)
                      ?.map((row, index) => (
                        <Grid key={index} container xs={12}>
                          <TableRow
                            sx={{
                              "&:last-child td": { border: 0 },
                              backgroundColor:
                                index % 2 === 0
                                  ? "rgba(228, 103, 3, 0.04)"
                                  : "rgba(122, 105, 254, 0.04)",
                              borderRadius: "10px",
                              marginTop: "10px",
                              width: " 100%",

                              boxShadow:
                                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                            }}
                          >
                            <TableCell align="inherit">
                              <Typography
                                variant="h4"
                                style={{
                                  width: "70px",
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "70px",
                                  background: "#fff",
                                  border: "1px solid #E8E8E8",
                                  borderRadius: "100px",
                                  color: "#000",

                                  marginBottom: "70px",
                                }}
                              >
                                {row?.user_type === "BUSINESS_OWNER"
                                  ? "BO"
                                  : "SA"}
                              </Typography>
                            </TableCell>
                            <TableCell width="100%" align="left">
                              <div
                                style={{
                                  display: "flex",
                                  // flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color:
                                      currentTheme == "DARK" ? "#fff" : "#000",
                                  }}
                                >
                                  {row?.user_unique_id
                                    ? `${row?.user_unique_id}`
                                    : "NA"}
                                  {/* {row?.user_id
                                  ? `BO${row?.user_id.slice(0, 3)}`
                                  : "NA"} */}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "15px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {!row?.isPostLike ? (
                                    <Tooltip title="Like post">
                                      <FavoriteBorderIcon
                                        style={{
                                          color:
                                            currentTheme === "DARK"
                                              ? "#fff"
                                              : "#000",
                                        }}
                                        onClick={() => addLike(row?.id, true)}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Dislike post">
                                      <FavoriteIcon
                                        onClick={() => addLike(row?.id, false)}
                                        style={{ color: "red" }}
                                      />
                                    </Tooltip>
                                  )}
                                  <div>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        open ? "long-menu" : undefined
                                      }
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={() => handleClick(index)}
                                    >
                                      <MoreVertIcon
                                        style={{
                                          color:
                                            currentTheme == "DARK"
                                              ? "#fff"
                                              : "#000",
                                        }}
                                      />
                                    </IconButton>
                                    {anchorEl == true && idx === index ? (
                                      <div style={{ position: "relative" }}>
                                        {row?.user_id ===
                                        sessionStorage.getItem("userId") ? (
                                          <div
                                            style={{
                                              position: "absolute",
                                              width: "120px",
                                              backgroundColor: "#d3d3d3",
                                              right: "2px",
                                              padding: "10px 20px",
                                              borderRadius: "8px",
                                            }}
                                            onClick={() => {
                                              handleCloseAnchor();
                                              handleOpenDeleteModal(row?.id);
                                            }}
                                          >
                                            Delete post?
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              position: "absolute",
                                              width: "130px",
                                              backgroundColor: "#d3d3d3",
                                              right: "2px",
                                              padding: "10px 20px",
                                              borderRadius: "8px",
                                            }}
                                            onClick={() => {
                                              handleCloseAnchor();
                                              handleOpenLogoutModal(row?.id);
                                            }}
                                          >
                                            Report post?
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {/* <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    open={openAnchor}
                                    onClose={handleCloseAnchor}
                                    PaperProps={{
                                      sx: {
                                        boxShadow:
                                          "0 1px 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2)",
                                        backgroundColor: "#ebebeb",
                                      },
                                    }}
                                  >
                                    {row?.user_id ===
                                    sessionStorage.getItem("userId") ? (
                                      <MenuItem
                                        onClick={() => {
                                          handleCloseAnchor();
                                          handleOpenDeleteModal(row?.id);
                                        }}
                                      >
                                        Delete post?
                                      </MenuItem>
                                    ) : (
                                      <MenuItem
                                        onClick={() => {
                                          handleCloseAnchor();
                                          handleOpenLogoutModal(row?.id);
                                        }}
                                      >
                                        Report post?
                                      </MenuItem>
                                    )}
                                  </Menu> */}
                                  </div>
                                  {/* <IoMdMore style={{ fontSize: "20px" }} /> */}
                                </div>
                              </div>

                              <Grid
                                className="wordBreak"
                                sx={{ width: "100%" }}
                                item
                                xs={10}
                              >
                                <Typography
                                  variant="p"
                                  style={{
                                    fontSize: "16px",
                                    color:
                                      currentTheme == "DARK" ? "#fff" : "#000",
                                  }}
                                >
                                  {row?.description}
                                </Typography>
                              </Grid>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "18px",
                                  }}
                                  onClick={() => {
                                    getCohortComments(row?.id);
                                    toggleComments(index);
                                  }}
                                >
                                  <FaRegCommentDots
                                    style={{
                                      color: "#dc5806",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <Typography
                                    onClick={() => {
                                      setShowComment(!showComment);
                                    }}
                                    variant="h6"
                                    sx={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color:
                                        currentTheme == "DARK"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                  >
                                    {row?.totalComments}{" "}
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        color:
                                          currentTheme == "DARK"
                                            ? "#fff"
                                            : "#000",
                                      }}
                                    >
                                      Comments
                                    </span>
                                    <span
                                      style={{
                                        position: "relative",
                                        top: "7px",
                                      }}
                                    >
                                      {selectedRowIndex === index ? (
                                        <KeyboardArrowUpIcon />
                                      ) : (
                                        <ExpandMoreIcon />
                                      )}
                                    </span>
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    variant="p"
                                    fontSize="16px"
                                    style={{
                                      color:
                                        currentTheme == "DARK"
                                          ? "#fff"
                                          : "#000",
                                    }}
                                  >
                                    {row?.createdAt
                                      ? dayjs(row?.createdAt)?.format("ll")
                                      : "NA"}
                                  </Typography>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>

                          {selectedRowIndex === index && (
                            <Grid item style={{ margin: "0 6%" }}>
                              <Box sx={{ width: "100%", padding: "10px 30px" }}>
                                <Box
                                  sx={{ width: "100%", position: "relative" }}
                                >
                                  <Box
                                    sx={{ width: "75%" }}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="h5"
                                      style={{
                                        fontWeight: "600",
                                        padding: "10px 0px",
                                        color:
                                          currentTheme === "DARK"
                                            ? "#fff"
                                            : "#000",
                                      }}
                                    >
                                      Comments
                                    </Typography>
                                    {/* <IconButton
                                  sx={{ color: "#E46703" }}
                                  onClick={() => {
                                    setShowComment(!comment);
                                    toggleComments(index);
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton> */}
                                  </Box>
                                  <TextField
                                    value={comment}
                                    inputProps={{ maxLength: 200 }}
                                    onChange={(e) => setComment(e.target.value)}
                                    placeholder="Add Comments..."
                                    id="outlined-basic"
                                    sx={{ width: "50vw" }}
                                    InputProps={{
                                      style: {
                                        borderRadius: "10px",
                                        boxShadow:
                                          "0px 0px 26px 0px rgba(0, 0, 0, 0.10)",
                                        border: "none !important",
                                        outline: "none !important",
                                      },
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton>
                                            <SentimentSatisfiedAltIcon
                                              onClick={() =>
                                                setOpenEmoji(!openEmoji)
                                              }
                                            />
                                            <div
                                              style={{
                                                zIndex: 10,
                                                position: "absolute",
                                                top: "40px",
                                              }}
                                            >
                                              {openEmoji == true && (
                                                <EmojiPicker
                                                  onEmojiClick={
                                                    onEmojiClickComments
                                                  }
                                                  height={500}
                                                  width={440}
                                                />
                                              )}
                                            </div>
                                          </IconButton>
                                          {/* <Divider orienftation="vertical" flexItem style={{height:"20px", border:"1px solid #c6c6c6"}}/> */}
                                          {/* <TbMinusVertical
                                    style={{ fontSize: "x-large" }}
                                  /> */}
                                          <div
                                            style={{
                                              width: "1px",
                                              height: "29px",
                                              backgroundColor: "#EDEDED",
                                            }}
                                          ></div>
                                          <IconButton
                                            disabled={comment?.length < 1}
                                          >
                                            <SendIcon
                                              onClick={() => {
                                                addComments(row?.id);
                                                setOpenEmoji(false);
                                              }}
                                              style={{ color: "#E46703" }}
                                            />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box
                                height={"200px"}
                                sx={{
                                  overflowY: "scroll",
                                  // "&::-webkit-scrollbar": {
                                  //   display: "none", // For Safari and Chrome
                                  // },
                                }}
                                mt={2}
                              >
                                {" "}
                                {commentList
                                  ?.filter((item) => item.is_active === true)
                                  ?.map((comment, commentIndex) => (
                                    <Grid
                                      spacing={2}
                                      container
                                      key={commentIndex}
                                      // alignItems="center"
                                      sx={{
                                        width: "100%",
                                        padding: "10px 30px",
                                      }}
                                    >
                                      <Grid item>
                                        <Typography
                                          variant="h5"
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "40px",
                                            background: "#fff",
                                            border: "1px solid #E8E8E8",
                                            borderRadius: "100px",
                                            color: "#000",
                                          }}
                                        >
                                          {comment?.User?.user_type ===
                                          "BUSINESS_OWNER"
                                            ? "BO"
                                            : "SA"}
                                        </Typography>
                                        {/* <img
                                src="/images/umair.png"
                                style={{ width: "40px", height: "40px" }}
                              /> */}
                                      </Grid>
                                      <Grid md={10} item>
                                        <Typography
                                          variant="h3"
                                          fontWeight={600}
                                          fontSize={"14px"}
                                          display="flex"
                                          justifyContent="space-between"
                                          sx={{
                                            color:
                                              currentTheme == "DARK"
                                                ? "#fff"
                                                : "#000",
                                          }}
                                        >
                                          {" "}
                                          <span
                                            style={{
                                              fontSize: "15px",
                                            }}
                                          >
                                            {" "}
                                            {comment?.User?.user_unique_id
                                              ? `${comment?.User?.user_unique_id}`
                                              : "NA"}
                                          </span>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <span
                                              style={{
                                                fontSize: "11px",
                                              }}
                                            >
                                              {" "}
                                              {comment?.createdAt
                                                ? dayjs(
                                                    comment?.createdAt
                                                  )?.format("lll")
                                                : "NA"}
                                            </span>
                                            {comment.user_id ==
                                            sessionStorage.getItem("userId") ? (
                                              <Tooltip title="Delete Comment">
                                                <DeleteIcon
                                                  onClick={() => {
                                                    setIsDeleteCommentPopupOpen(
                                                      true
                                                    );
                                                    setCommentId(comment?.id);
                                                    setCommentPostId(
                                                      comment?.post_id
                                                    );
                                                  }}
                                                  style={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    color: "red",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </Tooltip>
                                            ) : (
                                              <Tooltip title="Report Comment">
                                                <ReportIcon
                                                  onClick={() => {
                                                    setIsReportCommentPopupOpen(
                                                      true
                                                    );
                                                    setCommentId(comment?.id);
                                                    setCommentPostId(
                                                      comment?.post_id
                                                    );
                                                    setTopicId(
                                                      comment?.topic_id
                                                    );
                                                  }}
                                                  style={{
                                                    fontSize: "16px",
                                                    cursor: "pointer",
                                                    color: "red",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </Tooltip>
                                            )}
                                          </Box>
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          style={{
                                            color:
                                              currentTheme == "DARK"
                                                ? "#fff"
                                                : "#000",
                                          }}
                                        >
                                          {comment?.comment}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  ))}
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Dialog
              open={open}
              // onClose={handleClose}
              className="dialogueB"
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "20px !important",
                  // backgroundColor: currentTheme == "DARK" ? "#a3a3a3" : "#fff",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  width: "500px",
                }}
              >
                <ArrowBackIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: "20px",
                  }}
                  onClick={handleClose}
                />
                <DialogTitle style={{ fontWeight: "600" }}>
                  Create Your Post
                </DialogTitle>
              </Box>
              <Divider style={{ width: "100%", border: "1px solid #CBCBCB" }} />
              <DialogContent>
                <DialogContentText>Topic</DialogContentText>
                <Divider />
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    multiline
                    rows={9}
                    value={inputStr}
                    onChange={(e) => setInputStr(e.target.value)}
                    inputProps={{ maxLength: 480 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <SentimentSatisfiedAltIcon
                      onClick={() => setOpenEmoji(!openEmoji)}
                    />{" "}
                    {`${inputStr.length}/480`}
                  </div>
                  <div style={{ zIndex: 10 }}>
                    {openEmoji == true && (
                      <EmojiPicker
                        onEmojiClick={onEmojiClick}
                        height={500}
                        width={440}
                      />
                    )}
                  </div>
                </FormControl>
              </DialogContent>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Button
                  disabled={loadingPost || inputStr?.length === 0}
                  onClick={addCohortPosts}
                  style={{
                    opacity: inputStr?.length === 0 ? "0.9" : "1",
                    background:
                      "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                    color: inputStr?.length === 0 ? "#00000042" : "#fff",
                    padding: "10px",
                    borderRadius: "10px",
                    maxWidth: "250px",
                    width: "100%",
                  }}
                >
                  Add
                </Button>
              </DialogActions>
            </Dialog>
            <PasswordResetModal
              reportPost={reportPost}
              reportString={reportString}
              setReportString={setReportString}
              type="report-post"
              open={isPopupOpen}
              onClose={handleCloseModals}
            />
            <PasswordResetModal
              deletePost={deletePost}
              type="delete-post"
              open={isDeletePopupOpen}
              onClose={handleCloseModals}
            />
            <PasswordResetModal
              reportComment={reportComment}
              reportCommentString={reportCommentString}
              setReportCommentString={setReportCommentString}
              type="report-comment"
              open={isReportCommentPopupOpen}
              onClose={handleCloseModals}
            />
            <PasswordResetModal
              deleteComment={deleteComment}
              type="delete-comment"
              open={isDeleteCommentPopupOpen}
              onClose={handleCloseModals}
            />
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="pagination-wrapper"
        >
          {" "}
          <Box
            mt={3}
            mb={1}
            style={{
              display: noOfPages > 1 ? "flex" : "none",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              style={PrevbuttonStyle}
              variant="outlined"
              disabled={page === 1}
              onClick={handlePrevious}
            >
              Previous
            </Button>
            <Pagination
              count={noOfPages}
              page={page}
              // className={
              //   // currentTheme == "DARK" ? "pagination-dark" : "pagination"
              // }
              onChange={handleChange}
              hideNextButton={true}
              hidePrevButton={true}
              // sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            />
            <Button
              variant="outlined"
              disabled={page === noOfPages}
              onClick={handleNext}
              style={buttonStyle}
            >
              Next
            </Button>
          </Box>{" "}
        </div>
      </Container>
    </div>
  );
}

export default CohortPosts;

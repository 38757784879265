import React, { useEffect, useState } from "react";
import "./cohort.scss";
import "../guestUser/guestCohort.scss";

import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Pagination,
  Divider,
  Container,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import customAxios from "../../utils/customAxios";
import { ApiConfig, socketUrl } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import PasswordResetModal from "../../components/PasswordResetModal";
import io from "socket.io-client";
import dayjs from "dayjs";

export default function Cohort() {
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [myCohort, setMyCohort] = useState();
  console.log("myCohort: ", myCohort);
  const [duration, setDuration] = useState(0);
  const [duration2, setDuration2] = useState(0);
  const [cohortId, setCohortId] = useState();
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [cohortList, setCohortList] = useState([]);
  const [joinCohortId, setJoinCohortId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requestSend, setRequestSend] = useState(false);
  const [alreadyInCohort, setAlreadyInCohort] = useState(false);
  const [inNoCohort, setInNoCohort] = useState(false);
  const [requestedDetails, setRequestedDetails] = useState({});
  const [requestedDuration, setRequestedDuration] = useState();
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [showComponent, setShowComponent] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isOfferingsOpen, setIsOfferingsOpen] = useState(false);
  const [offeringState, setOfferingState] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const [cohortRequestOpen, setCohortRequestOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const [flag, setFlag] = useState(false);
  const isCohortCompliance = sessionStorage.getItem("cohortCompliance") === "true";
  const handleOpenLogoutModal = () => {
    setLogoutModalOpen(true);
  };
  const handleOfferingsModalOpen = () => {
    setIsOfferingsOpen(true);
  };
  const handleDescriptionModalOpen = () => {
    setIsDescriptionOpen(true);
  };
  const handleCohortRequestOpenModal = () => {
    setCohortRequestOpen(true);
  };

  const handleCloseModals = () => {
    setLogoutModalOpen(false);
    setIsOfferingsOpen(false);
    setIsDescriptionOpen(false);
    setCohortRequestOpen(false);
  };

  const userApplyForCohorts = async (id) => {
    try {
      const token = sessionStorage.getItem("userToken");
      if (!token) {
        navigate("/login");
        return;
      }
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userApplyForCohort,
        headers: {
          token: token,
        },
        data: {
          cohort_id: id,
        },
      });
      console.log(res);
      if (res?.data?.statusCode === 200) {
        await cohortJoinRequest();
        setFlag(!flag);
        setInNoCohort(false);
      } else {
      }
      handleCloseModals();
    } catch (err) {
      toast.error(err?.response?.data?.responseMessage);
      console.log(err, "akjgasf");
    }
  };

  const initialDataLoader = async () => {
    const status = sessionStorage.getItem("userToken") ? "USER" : "GUEST";
    try {
      setIsLoading(true);
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllCohort,
        params: {
          status: status,
          page: page,
          limit: 10,
        },
      });
      setIsLoading(false);
      console.log(res, "akjsguafaf");
      if (!res?.data) {
        return;
      }
      if (res?.data?.statusCode == 200) {
        setCohortList(res?.data?.result?.cohorts);
        setNoOfPages(res?.data?.result?.totalPages);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err, "akjgasf");
    }
  };
  const DaysToMonthsConverter = (days) => {
    const daysInMonth = 30.44; // Average days in a month

    if (days < daysInMonth) {
      return `${days} days`; // If less than 30.44 days, return days
    } else {
      const months = days / daysInMonth;
      const durationInMonths = months.toFixed(1); // Convert to a string with two decimal places
      return `${durationInMonths} months`; // If 30.44 days or more, return months
    }
  };

  const DaysToMonthsConverter2 = (days) => {
    const daysInMonth = 30.44;
    const months = Number(days) / daysInMonth;
    let durationInMonths;

    if (months < 1) {
      durationInMonths = `${days} days`;
    } else {
      const month = Math.floor(Number(months));
      console.log(month);
      durationInMonths = `${month} ${month == 1 ? "month" : "months"}`;
      console.log(durationInMonths);
    }

    setDuration2(durationInMonths);
  };

  const DaysToMonthsConverter3 = (days) => {
    const daysInMonth = 30.44;
    const months = days / daysInMonth;

    if (months < 1) {
      return `${days} days `; // If less than 30 days, return 0.5 months
    } else {
      console.log(months);
      let month = Math.floor(Number(months));
      console.log(month);
      return `${month} ${month == 1 ? "month" : "months"}`;
    }
  };

  const cohortJoinRequest = async () => {
    const user_id = sessionStorage.getItem("userId");
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getCohortJoinRequest,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: { user_id: sessionStorage.getItem("userId") },
      });
      if (res.data.statusCode === 200) {
        console.log("odhhdhdhgjhuyuiuhioionn");
        if (res?.data?.result?.length === 0) {
          await initialDataLoader();
          setInNoCohort(true);
        } else {
          setRequestSend(true);
          console.log(res?.data?.result?.data[0]);
          setRequestedDetails(res?.data?.result?.data[0]);
          const durationdata = res?.data?.result?.data[0]?.cohort?.duration;
          if (durationdata !== undefined) {
            await DaysToMonthsConverter2(durationdata);
          } else {
            console.log("Duration is undefined");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getMyCohort = async () => {
    const user_id = sessionStorage.getItem("userId");
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getUserCohort,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        // params: { user_id: user_id },
      });
      if (res.data.statusCode === 200) {
        console.log(res);
        if (Object.keys(res?.data?.result || {})?.length === 0) {
          console.log("yessssss");

          await cohortJoinRequest();
        } else {
          sessionStorage.setItem("cohort", true);
          setRequestSend(false);
          setAlreadyInCohort(true);
          console.log("No data");
        }

        setFlag(!flag);
        setMyCohort(res?.data?.result);
        console.log(res?.data?.result?.createdAt);
        sessionStorage.setItem("cohortId", res?.data?.result?.id);
        sessionStorage.setItem("cohortName", res?.data?.result?.cohortName);
        setCohortId(res?.data?.result?.id);
        await DaysToMonthsConverter(res?.data?.result?.duration);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  // console.log("noOfPages", noOfPages);
  const buttonStyle = {
    color: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.12)",
  };
  const PrevbuttonStyle = {
    color: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
  };
  const handleNext = () => {
    console.log("before", noOfPages, page);
    if (page < noOfPages) {
      setPage(page + 1); // Increment the page only if it's not the last page
      console.log("after", noOfPages, page);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    initialDataLoader();
    console.log(cohortList);
  }, [page]);

  useEffect(() => {
    getMyCohort();
  }, []);
  useEffect(() => {
    if (requestSend) {
      handleCohortRequestOpenModal();
    }
  }, [requestSend]);

  useEffect(() => {
    const start = new Date(myCohort?.createdAt);
    const end = new Date(myCohort?.endDate);
    const datesToShowComponent = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      datesToShowComponent.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const filteredDates = datesToShowComponent.filter((date, index) => {
      return index % 7 === 0 && index !== 0; // Skip the first createdAt date
    });
    const currentDateIndex = datesToShowComponent.findIndex(
      (date) => new Date(date).toDateString() === new Date().toDateString()
    );

    if (currentDateIndex !== -1 && filteredDates.includes(currentDateIndex)) {
      setShowComponent(true);
    }
  }, [myCohort?.createdAt, myCohort?.endDate]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [customFormCategoryData, setCustomFormCategoryData] = useState([]);
  console.log("customFormCategoryData: ", customFormCategoryData);
  const getAllCustomFormCategories = async () => {
    try {
      setIsDataLoading(true);
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllCustomFormCategories,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          cohort_id: sessionStorage.getItem("cohortId"),
        },
      });

      if (res?.data?.statusCode === 200) {
        setCustomFormCategoryData(res?.data?.result?.formCategories || []);
      }
      setIsDataLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCustomFormCategories();
  }, []);

  const handleNavigation = () => {
    if (customFormCategoryData.length === 1) {
      navigate("/custom-forms-list", {
        state: {
          cohortId: cohortId,
          cohortName: sessionStorage.getItem("cohortName"),
          fullName: sessionStorage.getItem("ownerFullName"),
          customFormCategoryTitle: customFormCategoryData[0]?.categoryName,
          customFormCategoryLogo: customFormCategoryData[0]?.categoryLogo,
          categoryDescription: customFormCategoryData[0]?.description,
          categoryId: customFormCategoryData[0]?.id,
        },
      });
    } else if (customFormCategoryData.length > 1) {
      navigate("/custom-form-categories", {
        state: {
          data: cohortId,
          cohortName: myCohort?.cohortName,
          fullName: sessionStorage.getItem("ownerFullName"),
        },
      });
    }
  };

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        width={"100vw"}
        height={"80vh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress
          sx={{ height: "180px", width: "180px", color: "#ce4920" }}
        />
      </Grid>
    );
  }
  return (
    <>
      {inNoCohort && (
        <>
          <Container maxWidth="lg">
            <div className="main-wrapper">
              <h1 className="cohort-heading"></h1>
              {cohortList
                ?.filter((item, idx) => item?.cohort.is_active === true)
                ?.map((item, idx) => (
                  <div
                    style={{
                      backgroundColor:
                        idx % 2 === 0 ? "#f2f2f2" : "transparent",
                      borderRadius: "8px ",
                    }}
                    className="main-container"
                  >
                    <div className="first-section">
                      <h2>
                        {item?.cohort?.cohortName
                          ? item?.cohort?.cohortName
                          : "NA"}
                      </h2>

                      <div className="span-container">
                        <span>
                          {item.associatedMembers > 1
                            ? "Associated Members -"
                            : "Associated Member -"}

                          {item?.associatedMembers
                            ? item?.associatedMembers
                            : 0}
                        </span>
                        {/* <span className="price-span"> Joining Price - $500</span> */}
                      </div>
                    </div>
                    <div>
                      <Divider
                        style={{
                          width: "100%",
                          border: "1px solid #aeaeae",
                          margin: "20px 0px 20px 0px",
                        }}
                      />
                    </div>
                    <Grid style={{ marginTop: "30px" }} container fullWidth>
                      <Grid sx={{ height: "200px" }} md={4} item>
                        <img
                          style={{
                            width: "100%",
                            borderRadius: "15px",
                            height: "290px",
                            objectFit: "cover",
                          }}
                          src={item?.cohort?.image_url}
                        />
                      </Grid>
                      <Grid md={0.5} item></Grid>
                      <Grid mt={1} md={7.5} item>
                        <p className="wordBreak">
                          {item?.cohort?.description
                            ? item?.cohort?.description.length > 275
                              ? `${item?.cohort?.description.slice(0, 275)}` +
                              "..."
                              : item?.cohort?.description
                            : "NA"}
                          {item?.cohort?.description.length > 275 && (
                            <span
                              onClick={() => {
                                handleDescriptionModalOpen();
                                setDescriptionState(
                                  item?.cohort?.description
                                    ? item?.cohort?.description
                                    : "NA"
                                );
                              }}
                              style={{
                                color: "#523169",
                                cursor: "pointer",
                                fontWeight: 600,
                              }}
                            >
                              {" "}
                              {" Read more"}
                            </span>
                          )}
                        </p>
                        <h3>OFFERINGS</h3>
                        <p className="wordBreak" style={{ marginTop: "20px" }}>
                          {item?.cohort?.offerings
                            ? item?.cohort?.offerings.length > 275
                              ? `${item?.cohort?.offerings.slice(0, 275)} ` +
                              "..."
                              : item?.cohort?.offerings
                            : "NA"}
                          {item?.cohort?.offerings.length > 275 && (
                            <span
                              onClick={() => {
                                handleOfferingsModalOpen();
                                setOfferingState(
                                  item?.cohort?.offerings
                                    ? item?.cohort?.offerings
                                    : "NA"
                                );
                              }}
                              style={{
                                color: "#523169",
                                cursor: "pointer",
                                fontWeight: 600,
                              }}
                            >
                              {" "}
                              Read more
                            </span>
                          )}
                        </p>
                        <div className="third-section">
                          <div className="inner-container">
                            <h4>
                              <span>Duration:</span>

                              <h5>
                                {" "}
                                {/* ? Math.floor(duration2) */}
                                {item?.cohort?.duration
                                  ? item?.cohort?.duration
                                  : "NA"}{" "}
                                days
                                {/* {item?.cohort?.duration
                                ? `${DaysToMonthsConverter3(
                                    item?.cohort?.duration
                                  )} `
                                : "NA"}{" "} */}
                              </h5>
                            </h4>
                            <h4>
                              <span>Start Date:</span>

                              <h5>
                                {" "}
                                {item?.cohort?.startDate
                                  ? dayjs(item?.cohort?.startDate)?.format("ll")
                                  : "NA"}
                              </h5>
                            </h4>
                            <h4>
                              <span>End Date:</span>

                              <h5>
                                {" "}
                                {item?.cohort?.endDate
                                  ? dayjs(item?.cohort?.endDate)?.format("ll")
                                  : "NA"}
                              </h5>
                            </h4>
                          </div>

                          <div>
                            <Button
                              sx={{
                                background:
                                  "linear-gradient(98deg, #E46703 -1.68%, #C7290D 103.45%)",
                                marginX: "auto",
                                marginBottom: "20px",
                                width: "132px",
                                fontSize: "16px",
                                textTransform: "none",
                                borderRadius: "10px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                // userApplyForCohorts(item?.cohort?.id);
                                setJoinCohortId(item?.cohort?.id);
                                handleOpenLogoutModal();
                                // userApplyForCohort(item?.cohort?.id)
                                // handleOpenLogoutModal()
                                // navigate(`/guest-community/${item?.cohort?.id}`);
                                // navigate('/login')
                              }}
                              variant="contained"
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              <PasswordResetModal
                joinCohortId={joinCohortId}
                type="cohort"
                open={isLogoutModalOpen}
                onClose={handleCloseModals}
                userApplyForCohorts={userApplyForCohorts}
              />
            </div>

            <div className="pagination-wrapper">
              {" "}
              <Box
                mt={3}
                mb={1}
                style={{
                  display: noOfPages > 1 ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={PrevbuttonStyle}
                  variant="outlined"
                  disabled={page === 1}
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
                <Pagination
                  count={noOfPages}
                  page={page}
                  // className={
                  // // currentTheme == "DARK" ? "pagination-dark" : "pagination"
                  // }
                  onChange={handleChange}
                  hideNextButton={true}
                  hidePrevButton={true}
                // sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                />
                <Button
                  variant="outlined"
                  disabled={page === noOfPages}
                  onClick={handleNext}
                  style={buttonStyle}
                >
                  Next
                </Button>
              </Box>{" "}
            </div>
          </Container>
        </>
      )}

      {requestSend && (
        <>
          {" "}
          <Container maxWidth="lg">
            <div className="main-container" style={{ marginTop: "32px" }}>
              <h1 className="cohort-heading">My Cohort</h1>
              <Grid style={{ marginTop: "55px" }} container fullWidth>
                <Grid sx={{ borderRadius: "15px" }} md={4} item>
                  <img
                    style={{
                      width: "100%",
                      borderRadius: "15px",
                      height: "350px",
                      objectFit: "cover",
                    }}
                    src={requestedDetails?.cohort?.image_url}
                  />
                </Grid>
                <Grid md={0.5} item></Grid>
                <Grid md={7.5} item>
                  <div className="first-section">
                    <h2>{requestedDetails?.cohort?.cohortName || "NA"}</h2>
                    <span>
                      {requestedDetails?.associatedMembers > 1
                        ? "Associated Members -"
                        : "Associated Member -"}
                      {requestedDetails?.associatedMembers}
                    </span>
                  </div>
                  <p className="wordBreak">
                    {requestedDetails?.cohort?.description
                      ? (() => {
                        const description =
                          requestedDetails.cohort.description;
                        const maxCharacters = 290; // Maximum characters to display
                        const showReadMore =
                          description.length > maxCharacters;
                        const truncatedDescription = description.slice(
                          0,
                          maxCharacters
                        );
                        const lastSpaceIndex =
                          truncatedDescription.lastIndexOf(" ");
                        const displayedDescription =
                          lastSpaceIndex !== -1
                            ? truncatedDescription.slice(0, lastSpaceIndex)
                            : truncatedDescription;

                        return (
                          <>
                            {displayedDescription}
                            {showReadMore && (
                              <span
                                onClick={() => {
                                  handleDescriptionModalOpen();
                                  setDescriptionState(description);
                                }}
                                style={{
                                  color: "#523169",
                                  cursor: "pointer",
                                  fontWeight: 600,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {" "}
                                Read more
                              </span>
                            )}
                          </>
                        );
                      })()
                      : "NA"}
                  </p>

                  <h3>OFFERINGS</h3>
                  <p className="wordBreak">
                    {requestedDetails?.cohort?.offerings
                      ? requestedDetails?.cohort?.offerings?.length > 285
                        ? `${requestedDetails?.cohort?.offerings.slice(
                          0,
                          290
                        )}` + "..."
                        : requestedDetails?.cohort?.offerings
                      : "NA"}
                    {requestedDetails?.cohort?.offerings?.length > 285 && (
                      <span
                        onClick={() => {
                          handleOfferingsModalOpen();
                          setOfferingState(
                            requestedDetails?.cohort?.offerings
                              ? requestedDetails?.cohort?.offerings
                              : "NA"
                          );
                        }}
                        style={{
                          color: "#523169",
                          cursor: "pointer",
                          fontWeight: 600,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {" "}
                        Read more
                      </span>
                    )}
                  </p>
                  <div className="third-section">
                    <div className="inner-container">
                      <h4>
                        <span>Duration:</span>

                        <h5>
                          {requestedDetails?.cohort?.duration ? duration2 : 0}{" "}
                        </h5>
                      </h4>
                      <h4>
                        <span>Start Date:</span>

                        <h5>
                          {" "}
                          {requestedDetails?.cohort?.startDate
                            ? dayjs(
                              requestedDetails?.cohort?.startDate
                            )?.format("ll")
                            : "NA"}
                        </h5>
                      </h4>
                      <h4>
                        <span>End Date:</span>

                        <h5>
                          {" "}
                          {requestedDetails?.cohort?.endDate
                            ? dayjs(requestedDetails?.cohort?.endDate)?.format(
                              "ll"
                            )
                            : "NA"}
                        </h5>
                      </h4>
                    </div>

                    <div>
                      <Button
                        onClick={() =>
                          navigate("/cohort-topics", {
                            state: {
                              cohortId: cohortId,
                              cohortName: myCohort?.cohortName,
                            },
                          })
                        }
                        sx={{
                          background:
                            "linear-gradient(98deg, #E46703 -1.68%, #C7290D 103.45%)",
                          marginX: "auto",
                          marginBottom: "20px",
                          width: "132px",
                          fontSize: "16px",
                          textTransform: "none",
                          borderRadius: "10px",
                        }}
                        disabled={true}
                        variant="contained"
                      >
                        Request Sent
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
          {/* <Box
 sx={{
 backgroundImage: "url('/images/surveyimg.png')",
 height: "250px",
 backgroundRepeat: "no-repeat",
 display: "flex",
 flexDirection: "column",
 justifyContent: "center",
 alignItems: "center",
 }}
 src="/images/surveyimg.png"
 >
 <Typography
 sx={{ color: "#fff", fontWeight: "600", fontSize: "30px" }}
 >
 Share your experience with us
 </Typography>
 <Button
 sx={{
 background:
 "linear-gradient(98deg, #E46703 -1.68%, #C7290D 103.45%)",
 marginX: "30px",
 marginBottom: "40px",
 paddingX: "50px",
 paddingY: "10px",
 textTransform: "none",
 fontSize: "16px",
 marginTop: "25px",
 borderRadius: "10px",
 }}
 variant="contained"
 onClick={() => navigate("/survey")}
 >
 Continue with survey
 </Button>
 </Box> */}
        </>
      )}
      {alreadyInCohort && (
        <>
          <Box
            style={{
              backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            {" "}
            <Container maxWidth="lg">
              <div className="main-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <h1
                    className="cohort-heading"
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    My Cohort
                  </h1>
                  <div>
                    {" "}
                    {isCohortCompliance && (
                      <Button
                        variant="contained"
                        sx={{
                          background:
                            "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                          marginX: "auto",
                          marginBottom: "20px",
                          width: 'auto',
                          maxWidth: '350px', 
                          textOverflow: 'ellipsis', 
                          overflow: 'hidden',
                          whiteSpace: 'nowrap', 
                          fontSize: "16px",
                          textTransform: "none",
                          borderRadius: "10px",
                          mt: 8,
                          mr: 2,
                        }}
                        onClick={handleNavigation}
                      >
                        {customFormCategoryData.length === 1
                          ? customFormCategoryData[0]?.categoryName
                          : "Form Categories"}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      sx={{
                        background:
                          "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                        marginX: "auto",
                        marginBottom: "20px",
                        width: "200px",
                        fontSize: "16px",
                        textTransform: "none",
                        borderRadius: "10px",
                        mt: 8,
                        mr: 2,
                      }}
                      onClick={() =>
                        navigate("/learning&lives", {
                          state: { data: "myCourses" },
                        })
                      }
                    >
                      My Courses
                    </Button>
                    <Button
                      disabled={myCohort?.associatedMembers < 1}
                      sx={{
                        background:
                          "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                        marginX: "auto",
                        marginBottom: "20px",
                        width: "200px",
                        fontSize: "16px",
                        textTransform: "none",
                        borderRadius: "10px",
                        mt: 8,
                      }}
                      onClick={(e) => {
                        e.preventDefault();

                        // userApplyForCohort(item?.cohort?.id)
                        // handleOpenLogoutModal()
                        // navigate(`/guest-community/${item?.cohort?.id}`);
                        navigate(`/community/${cohortId}`);
                      }}
                      variant="contained"
                    >
                      Community Chat
                    </Button>
                  </div>
                </div>

                <Grid style={{ marginTop: "55px" }} container fullWidth>
                  <Grid md={4} sm={12} item>
                    <img
                      style={{
                        width: "100%",
                        height: "350px",
                        borderRadius: "15px",
                        objectFit: "cover",
                      }}
                      src={myCohort?.image_url}
                    />
                  </Grid>
                  <Grid md={0.5} item></Grid>
                  <Grid md={7.5} item>
                    <div
                      className="first-section"
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      <h2
                        className=""
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        {myCohort?.cohortName || "NA"}
                      </h2>
                      <span
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#523168",
                        }}
                      >
                        {myCohort?.associatedMembers > 1
                          ? "Associated Members -"
                          : "Associated Member -"}{" "}
                        {myCohort?.associatedMembers
                          ? myCohort?.associatedMembers
                          : "0"}
                      </span>
                    </div>
                    <p
                      className="wordBreak"
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      {myCohort?.description
                        ? myCohort?.description?.length > 290
                          ? `${myCohort?.description.slice(0, 290)}` + "..."
                          : myCohort?.description
                        : "NA"}
                      {myCohort?.description?.length > 290 && (
                        <span
                          onClick={() => {
                            handleDescriptionModalOpen();
                            setDescriptionState(
                              myCohort?.description
                                ? myCohort?.description
                                : "NA"
                            );
                          }}
                          style={{
                            color: "#523169",
                            cursor: "pointer",
                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          Read more
                        </span>
                      )}
                    </p>
                    <h3
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#523168",
                      }}
                    >
                      OFFERINGS
                    </h3>
                    <p
                      className="wordBreak"
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      {myCohort?.offerings
                        ? myCohort?.offerings?.length > 290
                          ? `${myCohort?.offerings.slice(0, 290)}` + "..."
                          : myCohort?.offerings
                        : "NA"}
                      {myCohort?.offerings?.length > 290 && (
                        <span
                          onClick={() => {
                            handleOfferingsModalOpen();
                            setOfferingState(
                              myCohort?.offerings ? myCohort?.offerings : "NA"
                            );
                          }}
                          style={{
                            color: "#523169",
                            cursor: "pointer",
                            fontWeight: 600,
                            // color: currentTheme == "DARK" ? "#0000FF" : "#FB3F"
                          }}
                        >
                          {" "}
                          Read more
                        </span>
                      )}
                    </p>

                    <div
                      style={{
                        display: "grid",
                        float: "right",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/cohort-topics", {
                            state: {
                              cohortId: cohortId,
                              cohortName: myCohort?.cohortName,
                            },
                          })
                        }
                        sx={{
                          background:
                            "linear-gradient(98deg, #E46703 -1.68%, #C7290D 103.45%)",
                          marginX: "auto",
                          marginBottom: "20px",
                          marginTop: "29px",
                          width: "200px",
                          fontSize: "16px",
                          textTransform: "none",
                          borderRadius: "10px",
                        }}
                        variant="contained"
                      >
                        View
                      </Button>
                      {/* <Button
                        sx={{
                          background:
                            "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                          marginX: "auto",
                          marginBottom: "20px",
                          width: "200px",
                          fontSize: "16px",
                          textTransform: "none",
                          borderRadius: "10px",
                        }}
                        // onClick={handleClick}
                        onClick={(e) => {
                          e.preventDefault();

                          // userApplyForCohort(item?.cohort?.id)
                          // handleOpenLogoutModal()
                          // navigate(`/guest-community/${item?.cohort?.id}`);
                          navigate(`/community/${cohortId}`);
                        }}
                        variant="contained"
                      >
                        Community Chat
                      </Button> */}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </Box>
          {showComponent && (
            <Box
              sx={{
                backgroundImage: "url('/images/surveyimg.png')",
                height: "250px",
                backgroundRepeat: "no-repeat",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              src="/images/surveyimg.png"
            >
              <Typography
                sx={{ color: "#fff", fontWeight: "600", fontSize: "30px" }}
              >
                Share your experience with us
              </Typography>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7290D 103.45%)",
                  marginX: "30px",
                  marginBottom: "40px",
                  paddingX: "50px",
                  paddingY: "10px",
                  textTransform: "none",
                  fontSize: "16px",
                  marginTop: "25px",
                  borderRadius: "10px",
                }}
                variant="contained"
                onClick={() =>
                  navigate("/survey", {
                    state: {
                      cohortId: cohortId,
                      cohortName: myCohort?.cohortName,
                    },
                  })
                }
              >
                Continue with survey
              </Button>
            </Box>
          )}
        </>
      )}
      <PasswordResetModal
        type="cohortRequest"
        open={cohortRequestOpen}
        onClose={handleCloseModals}
      />
      <PasswordResetModal
        type="offerings"
        open={isOfferingsOpen}
        onClose={handleCloseModals}
        offeringState={offeringState}
      />
      <PasswordResetModal
        type="descriptionCohort"
        open={isDescriptionOpen}
        onClose={handleCloseModals}
        descriptionState={descriptionState}
      />
    </>
  );
}

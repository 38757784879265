import React from "react";
import "./common.scss";
import { useSelector } from "react-redux";

export default function MultiTextField({
  formField,
  setAnswersDetails,
  answersDetails,
  disabled,
  serialNumber
}) {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleChange = (e) => {
    if (disabled) return;
    setAnswersDetails({
      ...answersDetails,
      [e.target?.name]: {
        question_id: formField?.id,
        assesment_id: formField?.assesment_master_id,
        assesment_category_id: formField?.assesment_category_,
        category_id: formField?.category_id,

        answer: e.target.value,
      },
    });
  };
  return (
    <div className="textAreaContainer">
      {formField?.question && (
        <p
          style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
          className="heading"
        >{formField?.question}
        </p>
      )}
      {formField?.answer.length > 0 &&
        formField?.answer.map((item, i) => {
          return <input placeholder={item} disabled={disabled} />;
        })}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  Typography,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  LinearProgress,
  Pagination,
  Link,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
import CheckOption from "../../components/common/CheckOption";
import TextField from "../../components/common/TextField";
import MultiTextField from "../../components/common/MultiTextField";
import TextArea from "../../components/common/TextArea";
import DropDown from "../../components/common/DropDown";
import RadioButton from "../../components/common/RadioButton";
import ImageUpload from "../../components/common/ImageUpload";
import AudioUpload from "../../components/common/AudioUpload";
import VideoUpload from "../../components/common/VideoUpload";
import DocumentUpload from "../../components/common/DocumentUpload";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import PasswordResetModal from "../../components/PasswordResetModal";
import { AssessmentType } from "./CustomFormTab";
import Stepper from "../../components/stepper/Stepper";
import { getApiHandlerWithTokenForBlob } from "../../services/apiWithToken/service";
const CreateHBOForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state: ", state);
  const cohortId = state?.cohortId;
  const questionCategoryId = state?.questionCategoryId;
  const fullName = sessionStorage.getItem("ownerName")
  const cohortName = state?.cohortName;
  const customFormId = state?.customFormId;
  const customFormName = state?.customFormTitle;
  const customFormLogo = state?.customFormLogo;
  const customFormBgLogo = state?.customFormBgLogo;
  const type = state?.type;
  const isReadOnly = type === AssessmentType.VIEW;
  const userId = sessionStorage.getItem("userId")
  const [answersDetails, setAnswersDetails] = useState({});
  console.log("answersDetails: ", answersDetails);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [ansPercentage, setAnsPercentage] = useState();
  const [stepper, setStepper] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changeModal, setChangeModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  console.log("activeStep: ", activeStep);
  console.log("stepper.length: ", stepper.length);
  const handleCloseModals = () => {
    setChangeModal(false);
  };
  const [categories, setCategories] = useState([]);
  console.log("categories: ", categories);
  const [questionsByCategory, setQuestionsByCategory] = useState({});
  console.log("questionsByCategory: ", questionsByCategory);
  const [initialAnswers, setInitialAnswers] = useState({});
  const getAllCustomQnsAns = async () => {
    try {
      setLoading(true);
      const res = await customAxios({
        method: "GET",
        url: `${ApiConfig.getCustomFormQuestionsWithAnswers}?cohort_id=${cohortId}&customForm_id=${customFormId}&user_id=${userId}`,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res?.data?.statusCode === 200) {
        const result = res?.data?.result?.categoryQuestionWithAnswer || [];
        setAnsPercentage(res?.data?.result?.answerPercentage);
        const categoryNames = result.map((item) => item.categoryName);
        setCategories(categoryNames);

        // Extract questions grouped by category
        const questionsGrouped = result.reduce((acc, item) => {
          acc[item.categoryName] = item.customFormQuestions;
          return acc;
        }, {});
        setQuestionsByCategory(questionsGrouped);
        handleFormData(result);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to load questions.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (cohortId && customFormId) {
      getAllCustomQnsAns();
    }
  }, [cohortId, customFormId]);
  const handleDownloadPdf = async () => {
  try {
    setLoading(true);
    const fileBlob = await getApiHandlerWithTokenForBlob("getCustomFromResponsePdf", {
      cohort_id: cohortId,
      customForm_id: customFormId,
      user_id: userId,
    });

    if (fileBlob) {
      const cleanFullName = fullName.replace(/\s+/g, "");
      const cleanFormTitle = customFormName.trim(); 
      const filename = `${cleanFullName}_${cleanFormTitle}_response.pdf`;
      const url = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error("Error during PDF download: ", error);
    toast.error("Failed to download the file.");
  } finally {
    setLoading(false);
  }
};
  const handleSubmitForm = async () => {
    setLoading(true);
    try {
      const answers = Object.values(questionsByCategory).flatMap((questions) =>
        questions.map((question) => {
          const userAnswer = answersDetails[question.id]?.answer || question.answer || [];
          return {
            customForm_id: question.customForm_id,
            category_id: question.category_id,
            cohort_id: question.cohort_id,
            question_id: question.id,
            answer: Array.isArray(userAnswer) ? userAnswer.join(",") : userAnswer,
          };
        })
      );
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.saveCustomFormAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: { answers },
      });

      if (res?.data?.statusCode === 200) {
        toast.success(state?.cohortThanksMessage);
        getAllCustomQnsAns();
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to submit answers.");
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateForm = async () => {
    try {
      const answers = Object.values(questionsByCategory).flatMap((questions) =>
        questions.map((question) => {
          const userAnswer = answersDetails[question.id]?.answer || question.answer || [];
          return {
            customForm_id: question.customForm_id,
            category_id: question.category_id,
            cohort_id: question.cohort_id,
            question_id: question.id,
            answer: Array.isArray(userAnswer) ? userAnswer.join(",") : userAnswer,
          };
        })
      );
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.saveCustomFormAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: { answers },
      });

      if (res?.data?.statusCode === 200) {
        console.log("Answer saved successfully!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to save the answer.");
    }
  };
  useEffect(() => {
    configureAnswerDetail();
  }, [questionsByCategory]);
  const configureAnswerDetail = () => {
    const answerDetailsObj = Object.values(questionsByCategory).flatMap((questions) =>
      questions.map((question) => ({
        [question.id]: {
          ...question, // All question details
          answer: question?.customFormAnswers?.[0]?.answer || "",
        },
      }))
    );
    const mergedAnswers = Object.assign({}, ...answerDetailsObj);
    console.log("answerDetailsObj:", answerDetailsObj);
    setAnswersDetails(mergedAnswers);
  };
const [debouncedAnswersDetails, setDebouncedAnswersDetails] = useState(answersDetails);

useEffect(() => {
  const timeout = setTimeout(() => {
    setDebouncedAnswersDetails(answersDetails); // Update debounced value after typing stops
  }, 500); // Adjust this value as needed for your debounce effect

  return () => {
    clearTimeout(timeout); // Clear timeout on component unmount or when typing continues
  };
}, [answersDetails]); // This will run whenever answersDetails change

// Now, only trigger the API call when debouncedAnswersDetails is updated
useEffect(() => {
  if (JSON.stringify(initialAnswers) !== JSON.stringify(debouncedAnswersDetails)) {
    handleUpdateForm();
  }
}, [debouncedAnswersDetails]);


  const handleRenderForm = (form, i) => {
    console.log("i: ", i);
    switch (form?.answer_type) {
      case "textArea":
        return (
          <TextArea
             serialNumber={i}
            placeHolder="Type here..."
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answersDetails}
            key={i}
            handleSubmitForm={handleSubmitForm}
            handleUpdateForm={handleUpdateForm}
            disabled={isReadOnly}
          />
        );
      case "radio":
        return (
          <RadioButton
            formField={form}
            serialNumber={i}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answersDetails}
            handleSubmitForm={handleSubmitForm}
            handleUpdateForm={handleUpdateForm}
            disabled={isReadOnly}
            key={i}
          />
        );
      case "dropDown":
        return (
          <DropDown
            formField={form}
              serialNumber={i}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answersDetails}
            handleSubmitForm={handleSubmitForm}
            handleUpdateForm={handleUpdateForm}
            key={i}
            disabled={isReadOnly}
          />
        );
      case "checkBox":
        return (
          <CheckOption
              serialNumber={i}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answersDetails}
            handleSubmitForm={handleSubmitForm}
            handleUpdateForm={handleUpdateForm}
            key={i}
            disabled={isReadOnly}
          />
        );
      case "textBar":
        if (form?.answer.length > 0) {
          return (
            <MultiTextField
                serialNumber={i}
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answersDetails}
              key={i}
              disabled={isReadOnly}
            />
          );
        } else {
          return (
            <TextField
                serialNumber={i}
              placeHolder="Type here..."
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answersDetails}
              handleSubmitForm={handleSubmitForm}
              handleUpdateForm={handleUpdateForm}
              key={i}
              disabled={isReadOnly}
            />
          );
        }
      case "image":
        return (
          <ImageUpload
              serialNumber={i}
            formField={form}
            cohortName={cohortName}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answersDetails}
            key={i}
            handleUpdateForm={handleUpdateForm}
            disabled={isReadOnly}
            customFormName={customFormName}
          />
        );
      case "video":
        return (
          <VideoUpload
              serialNumber={i}
            cohortName={cohortName}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answersDetails}
            key={i}
            handleUpdateForm={handleUpdateForm}
            disabled={isReadOnly}
            customFormName={customFormName}

          />
        );
      case "audio":
        return (
          <AudioUpload
            serialNumber={i}
            cohortName={cohortName}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answersDetails}
            key={i}
            disabled={isReadOnly}
            handleUpdateForm={handleUpdateForm}
            customFormName={customFormName}

          />
        );
      case "documents":
        return (
          <DocumentUpload
               serialNumber={i}
            cohortName={cohortName}
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answersDetails}
            key={i}
            handleUpdateForm={handleUpdateForm}
            disabled={isReadOnly}
            customFormName={customFormName}

          />
        );
      default:
        break;
    }
  };
  const handleFormData = (data) => {
    const stepperData = data
      .map((step, i) => ({
        step: i + 1,
        title: step?.categoryName,
      }));
    setStepper(stepperData);
  };
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    if (activeStep !== 1) {
      setActiveStep(activeStep - 1);
    }
  };
  const handleNavigate = () => {
    navigate("/action-planning");
  };
  return (
    <>
      {loading ? (
        <Grid
          container
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: currentTheme === "DARK" ? "#121212" : "#fff",
          }}
        >
          <CircularProgress
            sx={{ height: "100px", width: "100px", color: "#ce4920" }}
          />
          <Typography mt={6} variant="subtitle2" style={{color: currentTheme === "DARK" ? "#fff" : "#000",}} >
            Loading Questions...
          </Typography>
        </Grid>
      ) : (
        <Box
          sx={{
            padding: "2rem",
            paddingLeft: "0px",
            paddingRight: "0px",
            backgroundColor: currentTheme === "DARK" ? "#121212" : "#fff",
          }}
        >
          <Grid container justifyContent={"space-between"} padding={"0 50px 0 64px"}>
            <Grid
              item
              xs={12}
              md={8}
            >
              <Box className="discoveryContainer" sx={{ width: "100%" }}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                  }}>
                    <img
                      src={customFormLogo}
                      width={"60px"}  
                      height={"60px"}
                      alt="customFormLogo"
                      style={{
                        borderRadius: "50%",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                      onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
                      onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                    />
                    <h2 style={{
                      color: currentTheme === "DARK" ? "#fff" : "#000",
                      fontWeight: "bold",
                      fontSize: "1.6rem",
                    }}>
                      {customFormName}
                    </h2>
                    </Box>
                    <Tooltip 
      title="If you are having any trouble while submitting the Questions's Answers, click here to schedule a meeting with the advisor." 
      arrow
    >
      <Button
        variant="outlined"
        sx={{
          color: "#de5d04",
          border: "1px solid #de5d04",
          "&:hover": {
            backgroundColor: "#de5d04",
            color: "white",
            border: "1px solid #de5d04",
          },
        }}
          onClick={handleNavigate}
      >
        Need Help ?
      </Button>
    </Tooltip>
                </Box>
                  <Typography
                      variant="body1"
                      align="start"
                    sx={{
                      color: currentTheme === "DARK" ? "#ccc" : "#333",
                      fontSize: "1.1rem",
                      lineHeight: 1.6,
                      marginTop: "8px",
                    }}
                  >
                    Introduction: {state?.cohortIntroduction}
                  </Typography>

                <div className="stepperWrapper" >
                  <Stepper data={stepper} activeStep={activeStep} />
                  <Box
                    sx={{
                      width: "60%",
                      "@media screen and (max-width: 768px)": {
                        width: "100%",
                      },
                    }}
                  >
                    <div className="header">
                      <h3 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
                        {stepper[activeStep - 1]?.title}
                      </h3>
                    </div>
                    <Box>
                      <Box className="scrollBarCustomColor"
                        component={OverlayScrollbarsComponent}
                        sx={{
                          height: "58vh",
                          overflowY: "scroll",
                          marginY: "20px",
                          width: "100%",
                        }}>
                        <div style={{ width: '95%' }}>
                          {categories.length > 0 && questionsByCategory[categories[activeStep - 1]]?.length > 0 ? (
                            questionsByCategory[categories[activeStep - 1]]?.map((form, i) =>
                            (
                              <div key={i}>
                              {handleRenderForm(form, i)}
                              </div>
                            ))
                          ) : (

                            <p>No questions available for this category.</p>
                          )}
                        </div>
                      </Box>
                      {type !== "VIEW" && (
                        <Box display="flex" alignItems="center"
                          gap={1}
                          justifyContent={"end"}>
                          {activeStep !== 1 && (
                            <Button
                              variant="outlined"
                              sx={{
                                color: "#de5d04", border: "1px solid #de5d04", "&:hover": {
                                  backgroundColor: "#de5d04",
                                  color: "white",
                                  border: "1px solid #de5d04"
                                },
                              }}
                              onClick={handleBack}
                            >
                              Previous
                            </Button>
                          )}
                          {activeStep !== stepper.length ? (
                            <Button
                              variant="outlined"
                              sx={{
                                color: "#de5d04", border: "1px solid #de5d04", "&:hover": {
                                  backgroundColor: "#de5d04",
                                  color: "white",
                                  border: "1px solid #de5d04"
                                },
                              }}
                              disabled={activeStep === stepper.length} onClick={handleNext}
                            >
                              Next
                            </Button>

                          ) : (
                            <Button
                              sx={{
                                background: "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                                border: "1px solid #de5d04",
                              }}
                              variant="contained"
                              onClick={() => setChangeModal(true)}
                            >
                              Submit
                            </Button>
                          )}


                        </Box>
                      )}
                    </Box>
                  </Box>
                </div>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                gap:"70px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                textAlign: "center",
              }}
              >
                {type === "VIEW" && (
                  <Box mt={2} mb={4}>
                    <Button variant="outlined" onClick={handleDownloadPdf} disabled={loading}>
                      {loading ? "Downloading..." : "Download PDF"}
                    </Button>
                  </Box>
                )}

              <Box
                position="relative"
                display="inline-flex"
                mb={2}
                sx={{
                  width: 170,
                  height: 170,
                  borderRadius: "50%",
                  boxShadow: 2, 
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={170}
                  thickness={3}
                  sx={{
                    color: "#e0e0e0", 
                  }}
                />
                <CircularProgress
                  variant="determinate"
                  value={ansPercentage}
                  size={170}
                  thickness={3}
                  sx={{
                    color:
                      ansPercentage < 50
                        ? "#C7340D"
                        : ansPercentage < 75
                          ? "#F2A900"
                          : "#28A745", 
                    position: "absolute",
                    top: 0,
                    left: 0,
                    borderRadius: "50%",
                  }}
                />
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  sx={{
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Typography variant="h6" component="div" style={{ color: currentTheme == "DARK" ? "#fff" : "#000 "}}>
                    {ansPercentage?.toFixed(2)} %
                  </Typography>
                </Box>
              </Box>

              {/* Dynamic Image */}
              <Box sx={{ width: "100%", mt: 2 }}>
                <img
                  src={customFormBgLogo}
                  alt="Custom Form"
                  style={{
                    width: "100%",
                    borderRadius: "8px", 
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", 
                  }}
                />
              </Box>
            </Grid>

          </Grid>
        </Box>
      )}
      <PasswordResetModal
        open={changeModal}
        type="submitCustomForm"
        onClose={handleCloseModals}
        handleSubmitForm={handleSubmitForm}
      />
    </>
  );
};

export default CreateHBOForm;

import React, { useState, useEffect } from "react";
import "./Footer.scss";
import {
  Instagram,
  Language,
  Telegram,
  Twitter,
  Facebook,
  LinkedIn,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Container } from "@mui/system";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
const FooterStyled = styled("Box")(({ theme }) => ({
  "& .bottomStrip": {
    height: "100%",
    background: "#523168",
    padding: "24px 0px",
    "& .footerBg": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        justifyContent: "center",
        padding: "10px 0px",
      },
      "& span": {
        color: "#fff",
        fontSize: "14px",
        fontWeight: "400",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
        },
      },
      "& .sideText": {
        display: "flex",
        gap: "40px",
        "& span": {
          color: "rgba(255, 255, 255, 0.75)",
        },
        [theme.breakpoints.down("md")]: {
          display: "flex",
          // gap: "10px",
          columnGap: "15px",
          flexWrap: "wrap",
        },
        [theme.breakpoints.down("sm")]: {
          gap: "5px !important",
          justifyContent: "center",
        },
      },
    },
  },
  "& .MuiContainer-root": {
    paddingLeft: "60px",
    paddingRight: "60px",
  },
}));

function LandingFooter() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [contactContent, setContactContent] = useState("");
  console.log("Koca: contactContent ", contactContent);
  const location = useLocation();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [socialLinks, setSocialLinks] = useState([]);
  console.log("Koca: socialLinks ", socialLinks);

  const fetchStaticContent = async () => {
    try {
      const socialMediaResponse = await customAxios({
        method: "POST",
        url: ApiConfig.findStaticContentByType,
        data: { content_type: "socialMediaLinks" },
      });

      if (socialMediaResponse.data?.statusCode === 200) {
        const description = socialMediaResponse.data.result[0]?.description;

        if (description) {
          // Extracting URLs using a regular expression to capture the URLs after the platform name
          const urlPattern =
            /(?:Instagram Link:|Twitter Link:|LinkedIn Link:|Facebook Link:)\s*(https?:\/\/[^\s]+)/g;
          const matches = Array.from(description.matchAll(urlPattern));

          // Map matches to an array of link objects with platform names
          const links = matches.map((match) => ({
            platform: match[0].split(" ")[0], // Extracting platform name like "Instagram", "Twitter", etc.
            url: match[1], // The actual URL
          }));

          setSocialLinks(links);
        }
      }
    } catch (error) {
      console.error(
        "Error fetching content:",
        error?.response?.data?.responseMessage || "Something went wrong."
      );
    }
  };
  const [contactDescContent, setContactDescContent] = useState({});
  const [contactDetails, setContactDetails] = useState([]);
  console.log("Koca: contactDetails ", contactDetails);
  const fetchContactStaticContent = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.findStaticContentByType,
        data: { content_type: "contactUs" },
      });
      if (res.data?.statusCode === 200) {
        const content = res.data.result[0];
        const description = content.description;

        // Extract parts from the description using regex
        const emailMatch = description.match(/Email:\s?(.+?)\n/);
        const phoneMatch = description.match(/Phone No.:\s?(.+?)\n/);
        const addressMatch = description.match(/Address:\s?(.+)/);
        const mainDescriptionMatch = description.match(
          /Description:\s?(.+?)(?=\nEmail)/s
        );

        setContactDescContent({
          heading: content.heading,
          description: mainDescriptionMatch
            ? mainDescriptionMatch[1].trim()
            : "",
        });

        setContactDetails([
          {
            icon: "/images/locationicon.png",
            name: "Location",
            value: addressMatch ? addressMatch[1].trim() : "",
          },
          {
            icon: "/images/phoneicon1.png",
            name: "Phone Number",
            value: phoneMatch ? phoneMatch[1].trim() : "",
          },
          {
            icon: "/images/emailicon.png",
            name: "Email",
            value: emailMatch ? emailMatch[1].trim() : "",
          },
        ]);
      } else {
        console.log(
          res.data?.responseMessage || "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again."
      );
    }
  };

  useEffect(() => {
    fetchContactStaticContent();
    fetchStaticContent();
  }, []);
  const getIcon = (platform) => {
    switch (platform) {
      case "Instagram":
        return <Instagram />;
      case "Twitter":
        return <Twitter />;
      case "LinkedIn":
        return <LinkedIn />;
      case "Facebook":
        return <Facebook />;
      case "Telegram":
        return <Telegram />;
      case "Language":
        return <Language />;
      default:
        return null;
    }
  };

  return (
    <FooterStyled>
      <div
        style={{
          backgroundColor: currentTheme == "DARK" ? "#111111" : "#FAF7FC",
        }}
        className="footerContainer"
      >
        <Container maxWidth="xlg">
          <div className="footerInfo">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "50px",
              }}
              className="imgContainer"
            >
              <img
                alt="logo"
                src={
                  currentTheme === "DARK"
                    ? "/images/boostlogo2.png"
                    : "/images/boostlogo2.png"
                }
                className="companyImg"
              />
            </div>
            <div className="info1">
              <div className="inner1">
                <div className="icons">
                  {socialLinks.map((link, index) => (
                    <a
                      key={index}
                      href={link.url} // Ensure you're passing just the URL here
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{getIcon(link.platform)}</span>{" "}
                      {/* Pass platform to get respective icon */}
                    </a>
                  ))}
                </div>
              </div>
              <div className="inner2">
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => {
                    sessionStorage.getItem("userToken")
                      ? navigate("/dashboard")
                      : navigate("/");
                  }}
                >
                  Home
                </span>
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => navigate("/aboutUs")}
                >
                  About Us
                </span>
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => navigate("/")}
                >
                  Learning & Lives
                </span>
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => navigate("/")}
                >
                  Monitor & Measure
                </span>
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => navigate("/contactUs")}
                >
                  Contact Us
                </span>
              </div>
            </div>

            <div className="contactUS">
              <p
                style={{
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                {contactDescContent?.heading || "Contact Us"}
              </p>

              {contactDetails.map((detail, index) => (
                <span
                  key={index}
                  style={{
                    color:
                      currentTheme === "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                >
                  {detail.value}
                </span>
              ))}
            </div>
          </div>
        </Container>
        <Box className="bottomStrip">
          <Container maxWidth="xlg">
            <Box className="footerBg">
              <span>© 2024 Give Me a Boost | BOOST STRATEGY GROUP™ LLC</span>
              <Box className="sideText">
                <span onClick={() => navigate("/termsandconditions")}>
                  Terms & Conditions
                </span>
                <span onClick={() => navigate("/privacypolicy")}>
                  Privacy Policy
                </span>
                <span onClick={() => navigate("/cookiespolicy")}>
                  Cookies Policies
                </span>
                <span onClick={() => navigate("/faq")}>FAQs</span>
                <span onClick={() => navigate("/contactus")}>Contact Us</span>
              </Box>
            </Box>
          </Container>
        </Box>
      </div>
    </FooterStyled>
  );
}

export default LandingFooter;

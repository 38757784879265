import React, { useEffect, useState } from "react";
import "./common.scss";
import { useSelector } from "react-redux";

export default function DropDown({
  formField,
  error,
  key,
  setAnswersDetails,
  answersDetails,
  handleUpdateForm,
  disabled,
  serialNumber
}) {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [answerId, setAnswerId] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  useEffect(() => {
    if (answersDetails[formField?.id]?.answer) {
      setSelectedAnswer(answersDetails[formField?.id]?.answer);
    }
  }, [answersDetails, formField?.id]);
  const handleChange = (e) => {
    if (disabled) return;
    const selectedValue = e.target.value;
    setSelectedAnswer(selectedValue);
    console.log(selectedValue, "gasdjhagsjas");
    const version = parseInt(sessionStorage.getItem("version"));

    setAnswersDetails({
      ...answersDetails,
      [formField?.id]: {
        question_id: formField?.id,
        assesment_id: formField?.assesment_master_id,
        assesment_category_id: formField?.assesment_category_,
        answer: selectedValue,
        category_id: formField?.category_id,

        version_control: version || formField?.version_control,
      },
    });

    handleUpdateChange(selectedValue);
  };

  const handleUpdateChange = (selectedValue) => {
    if (disabled) return;
    if (selectedValue) {
      const data = {
        business_owner_id: sessionStorage.getItem("userId"),
        answer_id: answersDetails[formField?.id]?.answer_id,
        answer: selectedValue,

      };
      handleUpdateForm(data);
    }
  };

  return (
    <div className="dropDownContainer" key={key}>
      {formField?.question && (
                     <p
  style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
  className="heading"
>
  <span
    style={{
      fontWeight: "bold",
      color: currentTheme === "DARK" ? "#FFD700" : "#000", 
      fontSize: "1.2em",
      marginRight: "8px",
    }}
  >
    {serialNumber + 1}.
  </span>
  {formField?.question}
</p>
      )}
      <div>
        <select
          value={
            selectedAnswer
              ? selectedAnswer
              : answersDetails[formField?.id]?.answer
          }
          onChange={handleChange}
          onBlur={handleUpdateChange}
          disabled={disabled}
        >
          {formField?.answer.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <span>{error}</span>
    </div>
  );
}

import axios from "axios";
import { ApiConfig } from "../ApiConfig";
import { toast } from "react-toastify";

export const getApiHandlerWithTokenForBlob = async (url, params) => {
  const token = sessionStorage.getItem("userToken");
  try {
    const response = await axios({
      method: "GET",
      url: ApiConfig[url], 
      params: params,
      headers: {
        token: token,
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      return response.data; 
    } else {
      toast.error("Failed to download the file.");
      return null;
    }
  } catch (error) {
    console.error("Error during file download: ", error);
    toast.error("Something went wrong while downloading the file.");
    return null;
  }
};
import React from "react";
import "./layout.scss";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
export default function LearningLayout({ children }) {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  return (
    <div
      style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
      className="learningLayout"
    >
      <Header />
      <div
        className="banner"
        style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
      >
        {/* <img src="/images/learningBanner1.png" alt="" /> */}
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginY: { xs: "20px", lg: "0px" },
            }}
          >
            {/* <Typography
              mt={3}
              sx={{
                textAlign: "center",
                fontSize: { lg: "40px", xs: "22px", md: "30px" },
                background:
                  "var(--Linear, linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%))",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
              fontWeight="700"
            >
              Welcome!
            </Typography> */}
            <Typography
              mt={0}
              sx={{
                textAlign: "center",
                fontSize: { lg: "50px", xs: "18px", md: "20px" },
                color: currentTheme == "DARK" ? "white" : "#523168",
              }}
            >
              Business Boost Accelerator
            </Typography>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                component="img"
                src={
                  currentTheme === "DARK"
                    ? "/images/boostlogo2.png"
                    : "/images/boostlogo2.png"
                }
                className="company-img"
                sx={{
                  width: "150px",
                  // height: "50px",
                  marginTop: "30px",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </div>
      {children}
      <Footer />
    </div>
  );
}

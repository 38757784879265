import React, { useState, useEffect } from "react";
import { ApiConfig } from "../../services/ApiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { Box, Button, Typography, CircularProgress, Backdrop, IconButton, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import "./common.scss";
export default function ImageUpload({
    formField,
    setAnswersDetails,
    answersDetails,
    key,
    cohortName,
    customFormName,
    disabled,
    serialNumber,
}) {
    const currentTheme = useSelector((state) => state.themeSwitcher.mode);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const existingImages = answersDetails[formField?.id]?.answer;
        if (existingImages) {
            setImages(existingImages.split(";"));
        }
    }, [answersDetails, formField?.id]);

    const fileUploader = async (file) => {
        if (disabled) return;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("cohortName", cohortName);
        formData.append("formName", customFormName);
        setIsLoading(true);
        try {
            const res = await axios({
                method: "POST",
                url: ApiConfig.uploadImage,
                headers: {
                    token: sessionStorage.getItem("userToken"),
                },
                data: formData,
            });
            if (res?.data?.statusCode === 200) {
                toast.success("Image Uploaded Successfully");
                return res?.data?.result;
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to upload image");
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageChange = async (e) => {
        if (disabled) return;
        const selectedFiles = Array.from(e.target.files);

        const uploadedImages = [];
        for (const file of selectedFiles) {
            const imageUrl = await fileUploader(file);
            if (imageUrl) {
                uploadedImages.push(imageUrl);
            }
        }

        const allImages = [...images, ...uploadedImages];
        setImages(allImages);


        const version = parseInt(sessionStorage.getItem("version"));
        setAnswersDetails({
            ...answersDetails,
            [formField?.id]: {
                question_id: formField?.id,
                assesment_id: formField?.assesment_master_id,
                assesment_category_id: formField?.assesment_category_,
                answer: allImages.join(";"),
                category_id: formField?.category_id,
                version_control: version || formField?.version_control,
            },
        });
    };

    const handleRemoveImage = (index) => {
        if (disabled) return;

        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);

        setAnswersDetails({
            ...answersDetails,
            [formField?.id]: {
                ...answersDetails[formField?.id],
                answer: updatedImages.join(";"),
            },
        });
    };

    return (
        <Box sx={{ mt: 2 }}>
            {formField?.question && (
                <p
                    style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
                    className="heading"
                >
                    {serialNumber + 1}: {formField?.question}
                </p>
            )}

            <Button
                variant="contained"
                component="label"
               startIcon={!isLoading && <CloudUploadIcon />} 
                sx={{
                    width: "100%",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    "&:hover": { backgroundColor: "#2c387e" },
                }}
                disabled={isLoading || disabled}
            >
      {isLoading ? (
      "  Uploading Images..."
    ) : (
        "Upload Images"
    )}
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                    hidden
                />
            </Button>

            <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                {images?.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box sx={{ position: "relative", width: "100%", height: "200px", border: "1px solid #ccc" }}>
                            <img
                                src={image}
                                alt={`Uploaded ${index + 1}`}
                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            />
                            <IconButton
                                disabled={disabled}
                                onClick={() => handleRemoveImage(index)}
                                sx={{ position: "absolute", top: 5, right: 5, backgroundColor: "rgba(0,0,0,0.5)", color: "#fff" }}
                            >
                                <Delete />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            
        </Box>
    );
}

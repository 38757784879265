import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Typography,
  CssBaseline,
  Box,
  TextField,
  CircularProgress,
  FormControl,
  FormHelperText,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import styled from "@emotion/styled";
import ReportIcon from "@mui/icons-material/Report";
import EmojiPicker from "emoji-picker-react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Bedtime,
  Diversity1Sharp,
  FormatLineSpacingRounded,
  LockPerson,
  Logout,
  Menu,
  Notifications,
} from "@mui/icons-material/";
import { ApiConfig } from "../services/ApiConfig";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Picker from "emoji-picker-react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import customAxios from "../utils/customAxios";
import { useSelector } from "react-redux";
import axios from "axios";
import PasswordEmailChangeModal from "./modal/PasswordEmailChangeModal";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const PasswordResetModal = ({
  handleSubmitForm,
  // setIsFormSubmitted,
  resendOtp,
  open,
  onClose,
  type,
  setIsVerified,
  setIs2faSmsVerified,
  setIs2faSmsDisable,
  description,
  setDescription,
  giveTestimonials,
  sessionLogout,
  isLoading,
  setTopicName,
  topicName,
  addCohortTopics,
  loadingTopic,
  reportPost,
  deletePost,
  joinCohortId,
  userApplyForCohorts,
  setIs2faEmailVerified,
  authenticateEmail,
  authenticateSMS,
  descriptionState,
  offeringState,
  inputStr,
  setInputStr,
  reportString,
  setReportString,
  currentStatus,
  reportCommentString,
  setReportCommentString,
  reportComment,
  deleteComment,
  liveTitle,
  liveDescription,
  meetingDate,
  meetingTime,
  endMeetingTime,
}) => {
  const [otp, setOtp] = useState();
  const [newOtp, setNewOtp] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  // const [description, setDescription] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [openUpdated, setOpenUpdated] = useState(false);
  const [openUpdatedSms, setOpenUpdatedSms] = useState(false);
  const [emailUpdateModalOpen, setEmailUpdateModalOpen] = useState(false);
  const [changeModal, setChangeModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentData, setCurrentData] = useState("");
  const [currentType, setCurrentType] = useState("email");
  const [openEmoji, setOpenEmoji] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);

  const [minutes, setMinutes] = useState(
    sessionStorage.getItem("minutes") ? sessionStorage.getItem("minutes") : 2
  );

  const [seconds, setSeconds] = useState(
    sessionStorage.getItem("seconds") ? sessionStorage.getItem("seconds") : 0
  );
  const [emailMinutes, setEmailMinutes] = useState(
    sessionStorage.getItem("emailMinutes")
      ? sessionStorage.getItem("emailMinutes")
      : 2
  );
  const [emailSeconds, setEmailSeconds] = useState(
    sessionStorage.getItem("emailSeconds")
      ? sessionStorage.getItem("emailSeconds")
      : 0
  );
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [sessionDetails, setSessionDetails] = useState({
    ip_address: "",
    login_from: "",
    location: "",
    source: "web",
  });

  const handleOpen = () => {
    setOpenUpdated(true);
  };
  const handleOpenUpdatedSms = () => {
    setOpenUpdatedSms(true);
  };

  const handleClose = () => {
    setOpenUpdated(false);
    setOpenUpdatedSms(false);
  };

  const handleChangeEmail = () => {
    setEmailUpdateModalOpen(true);
    currentStatus === "email" ? reSendOtp1() : reSendOtp2();
    // onClose();
  };

  const handleChangeEmailClose = () => {
    setEmailUpdateModalOpen(false);
  };

  const onEmojiClick1 = (event) => {
    setInputStr((prevInput) => prevInput + event.emoji);
    // setOpenEmoji(false);
  };

  const email = sessionStorage.getItem("userEmail");
  const mobile = sessionStorage.getItem("userMobille");
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const onEmojiClick = (event, emojiObject) => {
    console.log(event.emoji, emojiObject);
    setChosenEmoji(event.emoji);
  };

  const FormTextField = styled(TextField)({
    marginTop: "4px",

    "@media(max-width:1024px)": {
      // width: "70vw !important",
      // margin: "10px 0px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid rgba(82, 49, 104, 0.60)",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid rgba(82, 49, 104, 0.60)",
      },
      "&:focus": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "2px solid rgba(82, 49, 104, 0.60)",
          outline: "none", // Set outline to none on focus
        },
      },
    },
  });

  const navigate = useNavigate();
  const handleKeyPress = (e) => {
    const isNumeric = /^\d+$/;

    if (!isNumeric.test(e.key)) {
      e.preventDefault();
      setErrorMessage("OTP must be a number.");
    } else {
      setErrorMessage("");
    }
  };

  const otpHandler = async (type) => {
    setLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url:
          type === "sms"
            ? ApiConfig.verify2faSMSOtp
            : ApiConfig.verify2faEmailOtp,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          otp: Number(otp),
        },
      });
      if (res.data?.statusCode === 200) {
        console.log(res, "assasasasas");
        if (type === "email") {
          setIsVerified(true);
        } else {
          setIs2faSmsVerified(true);
        }
        console.log("Verified successfully", res);
        onClose();
        setLoading(false);
        setOtp("");
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.responseMessage
          ? error?.response?.data?.responseMessage
          : "Something went wrong.Please try again."
      );
    }
  };

  const otpHandler1 = async () => {
    setLoading(true);

    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.verifyOtp,
        data: {
          email: sessionStorage.getItem("email"),
          otp: otp,
        },
      });
      console.log(res);
      if (res) {
        console.log(res);
        currentStatus === "email"
          ? (() => {
            setChangeModal(true);
            setCurrentType("email");
            setCurrentData(userDetails?.email);
            setCurrentMessage("Please update your email.");
          })()
          : (() => {
            setChangeModal(true);
            setCurrentType("mobile");
            setCurrentData(userDetails?.email);
            setCurrentMessage("Please update your mobile number.");
          })();
      } else {
        console.log(res);
        setErrorMessage(
          res?.data?.responseMessage ||
          "Something went wrong.Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);

        return null;
      }
    } catch (error) {
      setLoading(false);
      setOtp("");
      console.log(error);
      setErrorMessage(
        error?.response?.data?.responseMessage ||
        "Something went wrong.Please try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const updateUserDetails = async () => {
    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.updateUser,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },

        data: {
          email: sessionStorage.getItem("newEmail"),
          user_id: JSON.parse(sessionStorage.getItem("user"))?.user_id,
        },
      });
      if (res.data.statusCode === 200) {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const updateUserSmsDetails = async () => {
    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.updateUser,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },

        data: {
          mobile: sessionStorage.getItem("newSms"),
          user_id: JSON.parse(sessionStorage.getItem("user"))?.user_id,
        },
      });
      if (res.data.statusCode === 200) {
        console.log(res);
        sessionStorage.setItem("userMobille", sessionStorage.getItem("newSms"));
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const newEmailOtpHandler = async () => {
    setLoading(true);

    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.verifyOtp,
        data: {
          email: sessionStorage.getItem("newEmail"),
          otp: otp,
          user_id: sessionStorage.getItem("userId"),
        },
      });
      console.log(res);
      if (res?.data?.statusCode === 200) {
        console.log(res);

        await updateUserDetails();
        handleOpen();
        setOtp("");
      } else {
        console.log(res);
        setErrorMessage(
          res?.data?.responseMessage ||
          "Something went wrong.Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);

        return null;
      }
    } catch (error) {
      setLoading(false);
      setOtp("");
      console.log(error);
      setErrorMessage(
        error?.response?.data?.responseMessage ||
        "Something went wrong.Please try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  const newSmsOtpHandler = async () => {
    setLoading(true);

    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.verifyOtp,
        data: {
          email: sessionStorage.getItem("newSms"),
          otp: otp,
          user_id: sessionStorage.getItem("userId"),
        },
      });
      console.log(res);
      if (res?.data?.statusCode === 200) {
        console.log(res);

        await updateUserSmsDetails();
        handleOpenUpdatedSms();
        setOtp("");
      } else {
        console.log(res);
        setErrorMessage(
          res?.data?.responseMessage ||
          "Something went wrong.Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);

        return null;
      }
    } catch (error) {
      setLoading(false);
      setOtp("");
      console.log(error);
      setErrorMessage(
        error?.response?.data?.responseMessage ||
        "Something went wrong.Please try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const authenticateSms = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.send2faSms,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });

      if (res.data?.statusCode === 200) {
        // toast.success("OTP sent successsfully.");
        // setLoading(false);
        // setErrorMessage("");
        // actions.resetForm({
        //   values: {
        //     oldPassword: "",
        //     password: "",
        //     confirmPassword: "",
        //   },
        // });
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        // setErrorMessage(res.data?.responseMessage || "Something went wrong");
        // setLoading(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(
      //   error?.response?.data?.responseMessage || "Something went wrong"
      // );
      // setLoading(false);
    }
  };

  // const authenticateEmail = async () => {
  //   try {
  //     const res = await customAxios({
  //       method: "POST",
  //       url: ApiConfig.send2faEmail,
  //       headers: {
  //         token: sessionStorage.getItem("userToken"),
  //       },
  //     });

  //     if (res.data?.statusCode === 200) {
  //       // toast.success("OTP sent successsfully.");
  //       // setLoading(false);
  //       // setErrorMessage("");
  //       // actions.resetForm({
  //       //   values: {
  //       //     oldPassword: "",
  //       //     password: "",
  //       //     confirmPassword: "",
  //       //   },
  //       // });
  //     } else {
  //       console.log("erroeeeeeerrrrrrrrrr");
  //       // setErrorMessage(res.data?.responseMessage || "Something went wrong");
  //       // setLoading(false);
  //       return null;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     // setErrorMessage(
  //     //   error?.response?.data?.responseMessage || "Something went wrong"
  //     // );
  //     // setLoading(false);
  //   }
  // };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.trim().split(/\s+/);
    setWordCount(words.length);

    if (words.length > 299) {
      setLimitExceeded(true);
    } else {
      setLimitExceeded(false);
    }

    setDescription(inputValue);
  };

  const disableauthenticateSms = async () => {
    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.update2faSmsStatus,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });

      if (res.data?.statusCode === 200) {
        // setUserDetails(res?.data?.result);
        // toast.success("Details updated successfully");
        // await getUserDetails();
        // console.log(res?.data?.result);
        // setLoading(false);
        setIs2faSmsVerified(false);
      } else {
        // setErrorMessage(res.data?.responseMessage || "Something went wrong");
        // setLoading(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(
      //   error?.response?.data?.responseMessage || "Something went wrong"
      // );
      // setLoading(false);
    }
  };
  const disableauthenticateEmail = async () => {
    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.update2faEmailStatus,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });

      if (res.data?.statusCode === 200) {
        // toast.success("OTP sent successsfully.");
        // setLoading(false);
        // setErrorMessage("");
        // });

        setIsVerified(false);
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        // setErrorMessage(res.data?.responseMessage || "Something went wrong");
        // setLoading(false);
        return null;
      }
    } catch (error) {
      console.log(error);
      // setErrorMessage(
      //   error?.response?.data?.responseMessage || "Something went wrong"
      // );
      // setLoading(false);
    }
  };

  // const otpHandler1 = async () => {
  //   setLoading(true);

  //   try {
  //     const res = await axios({
  //       method: "POST",
  //       url: ApiConfig.verifyOtp,
  //       data: {
  //         email: location.state.email,
  //         otp: otp,
  //         ip_address: sessionDetails?.ip_address
  //           ? sessionDetails?.ip_address
  //           : "123.123.123.123",

  //         location: sessionDetails.location
  //           ? `${sessionDetails.location?.city},${sessionDetails.location?.country}`
  //           : `${"Delhi"},${"IN"}`,

  //         login_from: sessionDetails.login_from,
  //         // ip_address: sessionDetails.ip_address,
  //         // location: `${sessionDetails.location?.city},${sessionDetails.location?.country}`,

  //         source: sessionDetails.source,
  //       },
  //     });
  //     console.log(res);
  //     if (res) {
  //       console.log(res);
  //       sessionStorage.setItem("userToken", res.data.result.token);
  //       {
  //         location.state.type === "forget"
  //           ? console.log("")
  //           : location.state.type === "signup"
  //           ? toast.success("Account created successfully", {
  //               position: toast.POSITION.TOP_RIGHT,
  //             })
  //           : toast.success("Logged in successfully", {
  //               position: toast.POSITION.TOP_RIGHT,
  //             });
  //       }

  //       {
  //         location.state.type == "forget"
  //           ? navigate("/resetpassword", {
  //               state: { email: location.state.email },
  //             })
  //           : navigate("/");
  //       }
  //     } else {
  //       console.log(res);
  //       setErrorMessage(
  //         res?.data?.responseMessage ||
  //           "Something went wrong.Please try again.",
  //         {
  //           position: toast.POSITION.TOP_RIGHT,
  //         }
  //       );
  //       setLoading(false);

  //       return null;
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     setOtp("");
  //     console.log(error);
  //     setErrorMessage(
  //       error?.response?.data?.responseMessage ||
  //         "Something went wrong.Please try again.",
  //       {
  //         position: toast.POSITION.TOP_RIGHT,
  //       }
  //     );
  //   }
  // };

  const reSendOtp1 = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig?.resentOtp,
        data: {
          email: sessionStorage.getItem("userEmail"),
        },
      });
      console.log(res);
      if (res.data?.statusCode == 200) {
        setEmailMinutes(2);
        setEmailSeconds(0);
        console.log(res);

        // navigate("/dashboard");
      } else {
        setErrorMessage(res.data?.responseMessage || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return null;
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.responseMessage || "Something went wrong"
      );
    }
  };
  const reSendOtp2 = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig?.resentOtp,
        data: {
          mobile: sessionStorage.getItem("userMobille")
            ? sessionStorage.getItem("userMobille").toString()
            : "",
        },
      });
      console.log(res);
      if (res.data?.statusCode == 200) {
        setEmailMinutes(2);
        setEmailSeconds(0);
        console.log(res);

        // navigate("/dashboard");
      } else {
        setErrorMessage(res.data?.responseMessage || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return null;
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.responseMessage || "Something went wrong"
      );
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }
  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(interval);
  //       } else {
  //         setSeconds(59);
  //         setMinutes(minutes - 1);
  //       }
  //     }
  //   }, 1000);

  //   sessionStorage.setItem("minutes", minutes);
  //   sessionStorage.setItem("seconds", seconds);
  //   return () => {
  //     sessionStorage.removeItem("minutes");
  //     sessionStorage.removeItem("seconds");
  //     clearInterval(interval);
  //   };
  // }, [seconds]);
  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes > 0) {
            // Check if minutes is greater than 0
            setSeconds(59);
            setMinutes(minutes - 1);
          } else {
            clearInterval(interval);
          }
        }
      }, 1000);

      sessionStorage.setItem("minutes", minutes);
      sessionStorage.setItem("seconds", seconds);
      return () => {
        sessionStorage.removeItem("minutes");
        sessionStorage.removeItem("seconds");
        clearInterval(interval);
      };
    }
    return () => { };
  }, [open, minutes, seconds]);

  useEffect(() => {
    // Check if the popup is open before starting the interval
    if (open) {
      const interval = setInterval(() => {
        if (emailSeconds > 0) {
          setEmailSeconds(emailSeconds - 1);
        }
        if (emailSeconds === 0) {
          if (emailMinutes > 0) {
            setEmailSeconds(59);
            setEmailMinutes(emailMinutes - 1);
          } else {
            clearInterval(interval);
          }
        }
      }, 1000);

      // Save initial timer values to sessionStorage
      sessionStorage.setItem("emailMinutes", emailMinutes);
      sessionStorage.setItem("emailSeconds", emailSeconds);

      // Cleanup function to clear the interval and remove sessionStorage items
      return () => {
        clearInterval(interval);
        sessionStorage.removeItem("emailMinutes");
        sessionStorage.removeItem("emailSeconds");
      };
    }
    // If the popup is not open, return a cleanup function that does nothing
    return () => {
      setEmailMinutes(2);
      setEmailSeconds(0);
    };
  }, [open, emailMinutes, emailSeconds]);

  const logOutHandler = async () => {
    setUpdateLoading(true);
    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.userLogout,
        data: {
          session_id: JSON.parse(sessionStorage.getItem("user"))?.session_id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data?.statusCode === 200) {
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("userType");
        sessionStorage.removeItem("ownerName");
        sessionStorage.removeItem("userEmail");
        sessionStorage.removeItem("ownerFullName");
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("advisorId");
        sessionStorage.removeItem("userMobille");
        sessionStorage.removeItem("is_email_2fa");
        sessionStorage.removeItem("is_sms_2fa");
        sessionStorage.removeItem("advisorName");
        sessionStorage.removeItem("is_sms_2fa");
        sessionStorage.removeItem("newSms");
        sessionStorage.removeItem("newEmail");
        sessionStorage.removeItem("cohortId");
        sessionStorage.removeItem("cohortName");
        sessionStorage.removeItem("cohort");
        sessionStorage.removeItem("timeZone");

        // toast.success("OTP sent successsfully.");
        // setLoading(false);
        // setErrorMessage("");
        // });

        setUpdateLoading(false);
      } else {
        console.log("erroeeeeeerrrrrrrrrr");
        // setErrorMessage(res.data?.responseMessage || "Something went wrong");
        // setLoading(false);
        return null;
        setUpdateLoading(false);
      }
      sessionStorage.removeItem("userToken");
    } catch (error) {
      console.log(error, "askjba");
      setUpdateLoading(false);
    }
  };

  return (
    <>
      {" "}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px !important",
            backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
          },
        }}
        open={open}
        onClose={async () => {
          // if (type != "updateEmail") {
          //   onClose();
          // }
          if (type == "updatedLogin") {
            await logOutHandler();
            navigate("/login");
          }
        }}
      >
        {type === "logout" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "30px",
              // height: "350px",
              width: { sm: "600px", xs: "320px" },
              borderRadius: "20px",
              position: "relative",
              backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            {/* <Logout style={{ fontSize: "1.9em" }} /> */}
            <Box
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "15px",
                left: { sm: "560px", xs: "280px" },
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
                padding: "5px",
              }}
              src="/images/xx.png"
              component="img"
            />
            <img src="/images/logoutpopup.png" />
            <Typography
              sx={{
                fontSize: "28px",
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              width="300px"
              textAlign="center"
              fontWeight={"500"}
              mt={4}
            >
              Are you sure you want to logout?
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: "432px",
                paddingX: "30px",
                textAlign: "center",

                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              style={{
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
              }}
              variant="subtitle2"
              fontWeight={"500"}
            ></Typography>
            <Box
              display="flex"
              width={{ sm: "340px", xs: "300px" }}
              justifyContent="space-around"
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  logOutHandler();
                  sessionStorage.removeItem("userToken");
                  navigate("/");
                  onClose();
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        )}
        {type === "quizassessment" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: { sm: "600px", xs: "320px" },
              // height: "480px",
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/Frame.png"}
            />
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: "25px" }}
              textAlign="center"
              fontWeight={"600"}
            >
              Quiz Completed Successfully!
            </Typography>

            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={() => navigate(-1)}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "fillassessment" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: { sm: "600px", xs: "320px" },
              backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
              // height: "480px",
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/fundingwarning.png"}
            />
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              px={5}
              sx={{ fontSize: "18px", color: "#4d4d4d" }}
              textAlign="center"
              fontWeight={"400"}
            >
              Kindly fill in your information to search for the most suitable
              funders matching your profile.
            </Typography>

            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "40px",
                width: "500px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={onClose}
            >
              Proceed
            </Button>
          </Box>
        )}
        {type === "surveyAssessment" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: { sm: "600px", xs: "320px" },
              // height: "480px",
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/Frame.png"}
            />
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: "20px" }}
              textAlign="center"
              fontWeight={"500"}
            >
              Thank you for completing the survey! Your feedback is invaluable
              to us.
            </Typography>

            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={() => {
                navigate("/my-cohort");
              }}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "reset" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "50px",
              height: "450px",
              width: { sm: "600px", xs: "300px" },
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/Frame.png"}
            ></Box>
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: { sm: "30px", xs: "18px" } }}
              fontWeight={"600"}
            >
              Password reset successfully.
            </Typography>

            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                width: "200px",
                fontSize: "18px",
                marginTop: "40px",
                width: { sm: "200px", xs: "200px" },
                textTransform: "none",
                borderRadius: "10px",
              }}
              variant="contained"
              onClick={() => {
                navigate("/login");
                // toast.success("Password reset successfully", {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "assessment" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              width: { sm: "600px", xs: "320px" },
              // height: "480px",
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/Frame.png"}
            ></Box>
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{
                fontSize: "25px",
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              textAlign="center"
              fontWeight={"600"}
            >
              Assessment completed successfully.
            </Typography>

            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "432px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
                color: currentTheme == "DARK" ? "#fff" : "#",
              }}
              variant="subtitle2"
              fontWeight={"500"}
            >
              Thank you for taking the time to complete this assessment. Once we
              receive your responses, we will analyze the data using our and
              provide you with a customized market analysis report.
            </Typography>
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={() => {
                navigate("/discovery-coaches");
                // toast.success("Password reset successfully", {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "monitorassessment" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              width: { sm: "600px", xs: "320px" },
              // height: "480px",
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/Frame.png"}
            ></Box>
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{
                fontSize: "25px",
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              textAlign="center"
              fontWeight={"600"}
            >
              Assessment completed successfully.
            </Typography>

            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "432px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
                color: currentTheme == "DARK" ? "#fff" : "#",
              }}
              variant="subtitle2"
              fontWeight={"500"}
            >
              Thank you for taking the time to complete this assessment. Once we
              receive your responses, we will analyze the data using our and
              provide you with a customized market analysis report.
            </Typography>
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={() => {
                navigate("/monitor-measure");
                // toast.success("Password reset successfully", {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
                onClose();
              }}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "cohortassessment" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: { sm: "600px", xs: "320px" },
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              // height: "480px",
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/Frame.png"}
            ></Box>
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: "25px" }}
              textAlign="center"
              fontWeight={"600"}
            >
              Cohort Assesment Completed Successfully.
            </Typography>

            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "432px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            >
              Thank you for taking the time to submit your application. Your
              information is currently being reviewed. Please refer to your
              email for updates.
            </Typography>
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={() => {
                navigate("/my-cohort");
                // toast.success("Password reset successfully", {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "contactus" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: { sm: "600px", xs: "320px" },
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              // height: "480px",
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/Frame.png"}
            ></Box>
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: "25px" }}
              textAlign="center"
              fontWeight={"600"}
            >
              Your query has been submitted successfully.
            </Typography>

            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "432px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            >
              Thank you for taking the time to reach out to us. We appreciate
              your interest and will respond to your inquiry as soon as
              possible.
            </Typography>
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={onClose}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "offerings" && (
          <Box
            className="wordBreak"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: { sm: "600px", xs: "320px" },
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              // height: "480px",
            }}
          >
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: "25px" }}
              textAlign="center"
              fontWeight={"600"}
              color="#523168"
            >
              OFFERINGS
            </Typography>

            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "432px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            >
              {offeringState}
            </Typography>
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={onClose}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "descriptionCohort" && (
          <Box
            className="wordBreak"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: { sm: "600px", xs: "320px" },
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              // height: "480px",
            }}
          >
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: "25px" }}
              textAlign="center"
              fontWeight={"600"}
              color="#523168"
            >
              DESCRIPTION
            </Typography>

            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "432px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            >
              {descriptionState}
            </Typography>
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={onClose}
            >
              Ok
            </Button>
          </Box>
        )}

        {type === "authenticateSms" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px 20px",
              // height: "350px",
              minWidth: "500px",
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            <img
              onClick={onClose}
              style={{
                position: "absolute",
                top: "15px",
                left: "467px",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
                padding: "5px",
              }}
              src="/images/xx.png"
            />
            <Typography
              // sx={{ fontSize: "25px", marginRight: "60px" }}
              fontWeight={"600"}
            >
              Authenticate Your Account
            </Typography>
            <Typography
              sx={{
                color: "rgba(22, 30, 41, 0.75)",
                width: "432px",
                paddingX: "30px",
                fontSize: "14px",
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"300"}
              mt={2}
            >
              Please confirm your account by entering the authentication code
              sent to <span style={{ fontWeight: 600 }}>{mobile}.</span>
            </Typography>
            <OtpInput
              value={otp}
              onChange={(otpValue) => {
                setOtp(otpValue);
                setErrorMessage("");
              }}
              onBlur={setErrorMessage("")}
              numInputs={4}
              inputType="number"
              renderSeparator={
                <Box display={"inline"} mx={1}>
                  {" "}
                </Box>
              }
              containerStyle={{
                width: "100%",
                display: "flex",
                marginTop: "30px",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "30px 10px 0px 10px",
              }}
              inputStyle={{
                minWidth: "55px",
                height: "55px",
                borderRadius: "10px",
                border: "1px solid rgba(82, 49, 104, 0.60)",
              }}
              renderInput={(props) => (
                <input
                  style={{
                    width: "40px", // Set the width as per your design
                    height: "40px", // Set the height as per your design
                    fontSize: "16px", // Set the font size as per your design
                    border: "1px solid #ccc", // Set the border as per your design
                    borderRadius: "4px", // Set the border radius as per your design
                    margin: "4px", // Set the margin as per your design
                    textAlign: "center", // Center the text within the input
                    // Add any other styles you want to customize
                  }}
                  onKeyPress={handleKeyPress}
                  {...props}
                />
              )}
            />
            {minutes > 0 || seconds > 0 ? (
              <Box
                mt={2}
                mx="auto"
                sx={{
                  width: `calc(55px * 5)`,
                  textAlign: "right",
                  color: "purple",
                  fontSize: "18px",
                }}
              >
                {String(minutes).padStart(2, "0").replace("-", "")}:
                {String(seconds).padStart(2, "0")}
              </Box>
            ) : (
              <div></div>
            )}
            <Box
              display="flex"
              width="340px"
              justifyContent="space-around"
              mx="auto"
              mt={2}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  // marginBottom: "40px",
                  width: "111px",
                  fontSize: "16px",
                  textTransform: "none",
                  borderRadius: "10px",
                }}
                variant="contained"
                disabled={loading || otp.length < 4}
                onClick={() => {
                  otpHandler("sms");
                }}
              >
                Submit
                {loading && (
                  <CircularProgress
                    size={16} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Box>
            <Typography
              color="rgba(22, 30, 41, 0.75);

"
              style={{ marginTop: "20px" }}
            >
              I don’t receive a code.
            </Typography>
            {/* {minutes <= 0 ||
          (seconds <= 0 && ( */}
            <Button
              onClick={() => {
                // reSendOtp();
                // authenticateSms();
                reSendOtp2();
              }}
              mx="auto"
              variant="text"
              sx={{
                color: "purple",
                "&:hover": {
                  color: " #e55f18",
                },
              }}
              type="submit"
              disabled={seconds > 1 || minutes > 0}
            >
              RESEND?
            </Button>
            {/* ))} */}
          </Box>
        )}
        {type === "authenticateEmail" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px 20px",
              // height: "350px",
              width: { sm: "600px", xs: "300px" },
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            <Box
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "15px",
                left: { sm: "565px", xs: "270px" },
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
                padding: "5px",
              }}
              src="/images/xx.png"
              component="img"
            />
            <Typography
              sx={{
                fontSize: { sm: "25px", xs: "18px" },
                // marginRight: { sm: "60px", xs: "10px" },
              }}
              fontWeight={"600"}
            >
              Authenticate Your Account
            </Typography>
            <Typography
              sx={{
                color: "rgba(22, 30, 41, 0.75)",
                paddingX: "30px",
                fontSize: "14px",
                width: { sm: "432px", xs: "280px" },
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"300"}
              mt={2}
            >
              Please confirm your account by entering the authentication code
              send to <span style={{ fontWeight: 600 }}>{email}</span>
            </Typography>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              inputType="number"
              renderSeparator={
                <Box display={"inline"} mx={1}>
                  {" "}
                </Box>
              }
              containerStyle={{
                width: "100%",
                display: "flex",
                marginTop: "30px",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "30px 10px 0px 10px",
              }}
              inputStyle={{
                minWidth: "11%",
                height: "10%",
                borderRadius: "10px",
                border: "1px solid rgba(82, 49, 104, 0.60)",
              }}
              renderInput={(props) => (
                <input
                  style={{
                    width: "40px", // Set the width as per your design
                    height: "40px", // Set the height as per your design
                    fontSize: "16px", // Set the font size as per your design
                    border: "1px solid #ccc", // Set the border as per your design
                    borderRadius: "4px", // Set the border radius as per your design
                    margin: "4px", // Set the margin as per your design
                    textAlign: "center", // Center the text within the input
                    // Add any other styles you want to customize
                  }}
                  onKeyPress={handleKeyPress}
                  {...props}
                />
              )}
            />
            {emailMinutes > 0 || emailSeconds > 0 ? (
              <Box
                mt={2}
                mx="auto"
                sx={{
                  width: `calc(55px * 5)`,
                  textAlign: "right",
                  color: "purple",
                  fontSize: "18px",
                }}
              >
                {String(emailMinutes).padStart(2, "0").replace("-", "")}:
                {String(emailSeconds).padStart(2, "0")}
              </Box>
            ) : (
              <div></div>
            )}
            <Box
              display="flex"
              width={{ sm: "340px", xs: "255px" }}
              justifyContent="space-around"
              mx="auto"
              mt={2}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  // marginBottom: "40px",
                  width: "111px",
                  fontSize: "16px",
                  textTransform: "none",
                  borderRadius: "10px",
                }}
                disabled={loading || !otp || otp?.toString()?.length < 4}
                variant="contained"
                onClick={() => {
                  console.log(otp?.toString()?.length, "absvdfjkas");
                  if (!otp || otp?.toString()?.length < 4) {
                    toast.error("Otp is required");
                    return;
                  }
                  otpHandler();
                }}
              >
                Submit
                {loading && (
                  <CircularProgress
                    size={16} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Box>
            <Typography
              color="rgba(22, 30, 41, 0.75);

"
              style={{ marginTop: "20px" }}
            >
              I don’t receive a code.
            </Typography>
            {/* {emailMinutes <= 0 ||
          (emailSeconds <= 0 && ( */}
            <Button
              onClick={() => {
                // reSendOtp();
                // authenticateEmail();
                // toast.success("OTP resent successsfully.");
              }}
              mx="auto"
              variant="text"
              sx={{
                color: "purple",
                "&:hover": {
                  color: " #e55f18",
                },
              }}
              type="submit"
              disabled={emailSeconds > 1 || emailMinutes > 0}
            >
              RESEND?
            </Button>
            {/* ))} */}
          </Box>
        )}
        {type === "emailChange" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "30px",
              // height: "350px",
              width: { sm: "600px", xs: "320px" },
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            {/* <Logout style={{ fontSize: "1.9em" }} /> */}
            <Box
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "15px",
                left: { sm: "560px", xs: "280px" },
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
                padding: "5px",
              }}
              src="/images/xx.png"
              component="img"
            />
            {currentStatus === "email" ? (
              <ForwardToInboxIcon
                sx={{
                  fontSize: "50px",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              />
            ) : (
              <LocalPhoneIcon
                sx={{
                  fontSize: "50px",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              />
            )}

            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              sx={{ fontSize: "28px" }}
              width="300px"
              textAlign="center"
              fontWeight={"500"}
              mt={4}
            >
              {currentStatus === "email"
                ? " Are you sure you want to change your email?"
                : "Are you sure you want to change your mobile number?"}
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: "432px",
                paddingX: "30px",
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            ></Typography>
            <Box
              display="flex"
              width={{ sm: "340px", xs: "300px" }}
              justifyContent="space-around"
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  handleChangeEmail();
                  // onClose();
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        )}
        {type === "submitCustomForm" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "30px",
              // height: "350px",
              width: { sm: "600px", xs: "320px" },
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              sx={{ fontSize: "28px" }}
              width="300px"
              textAlign="center"
              fontWeight={"500"}
              mt={4}
            >
              Are you sure you want to submit form?
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: "432px",
                paddingX: "30px",
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            ></Typography>
            <Box
              display="flex"
              width={{ sm: "340px", xs: "300px" }}
              justifyContent="space-around"
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  // setIsFormSubmitted(true)
                  handleSubmitForm();
                  onClose();
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        )}

        {type === "authenticateDisableSms" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px 20px",
              // height: "350px",
              width: "100%",
              minWidth: "500px",
              borderRadius: "20px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              position: "relative",
              "@media(max-width:568px)": {
                width: "100%",
                minWidth: "0px",
              },
            }}
          >
            <img
              onClick={onClose}
              style={{
                position: "absolute",
                top: "15px",
                // left: "467px",
                right: "10px",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                padding: "5px",
              }}
              src="/images/xx.png"
            />
            <Box style={{ borderRadius: "50%" }}>
              <LockOpenIcon style={{ fontSize: "100px" }} />
            </Box>
            <Typography
              mt={4}
              sx={{ fontSize: "20px", textAlign: "center" }}
              fontWeight={"600"}
            >
              Are you sure you want to disable sms authentication?
            </Typography>

            <Box
              sx={{
                display: "flex",
                // width: "340px",
                gap: "20px",
                justifyContent: "space-around",
                "@media(max-width:468px)": {
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0px",
                },
              }}
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                disable={loading}
                // onClose={handleClose}
                onClick={() => {
                  disableauthenticateSms();
                  onClose();
                }}
              >
                Yes
                {loading && (
                  <CircularProgress
                    size={16} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        )}
        {type === "authenticate2faEmail" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px 20px",
              // height: "350px",
              minWidth: "500px",
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              "@media(max-width:568px)": {
                width: "100%",
                minWidth: "0px",
              },
            }}
          >
            <img
              onClick={onClose}
              style={{
                position: "absolute",
                top: "15px",
                // left: "467px",
                right: "10px",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                padding: "5px",
              }}
              src="/images/xx.png"
            />
            <Box
              style={{
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
              }}
            >
              <LockPerson style={{ fontSize: "100px" }} />
            </Box>
            <Typography
              sx={{
                fontSize: "18px",
                textAlign: "center",
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              fontWeight={"500"}
            >
              Before you proceed to enable email authentication for 2-factor
              login, please note that this will require you to verify your
              identity using a code sent to your email address every time you
              log in. Are you sure you want to enable email authentication?
            </Typography>

            <Box
              sx={{
                display: "flex",
                // width: "340px",
                gap: "20px",
                justifyContent: "space-around",
                "@media(max-width:468px)": {
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0px",
                },
              }}
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                disable={loading}
                onClick={() => {
                  authenticateEmail();
                  onClose();
                }}
              >
                Yes
                {loading && (
                  <CircularProgress
                    size={16} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        )}
        {type === "authenticate2faSMS" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px 20px",
              // height: "350px",
              minWidth: "500px",
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              "@media(max-width:568px)": {
                width: "100%",
                minWidth: "0px",
              },
            }}
          >
            <img
              onClick={onClose}
              style={{
                position: "absolute",
                top: "15px",
                // left: "467px",
                right: "10px",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                padding: "5px",
              }}
              src="/images/xx.png"
            />
            <Box
              style={{
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
              }}
            >
              <LockPerson style={{ fontSize: "100px" }} />
            </Box>
            <Typography
              sx={{
                fontSize: "18px",
                textAlign: "center",
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              fontWeight={"500"}
            >
              Before you proceed to enable mobile number authentication for
              2-factor login, please note that this will require you to verify
              your identity using a code sent to your mobile number every time
              you log in. Are you sure you want to enable mobile number
              authentication?
            </Typography>

            <Box
              sx={{
                display: "flex",
                // width: "340px",
                gap: "20px",
                justifyContent: "space-around",
                "@media(max-width:468px)": {
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0px",
                },
              }}
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                disable={loading}
                onClick={() => {
                  authenticateSMS();
                  onClose();
                }}
              >
                Yes
                {loading && (
                  <CircularProgress
                    size={16} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        )}
        {type === "testimonial" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "left",
              width: { sm: "600px", xs: "320px" },

              borderRadius: "20px",
              background: currentTheme == "DARK" ? "#00000075" : "#fff",
              position: "relative",
              paddingX: "30px",
            }}
          >
            <Box
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "15px",
                left: { sm: "560px", xs: "290px" },
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
                padding: "5px",
              }}
              component={"img"}
              src="/images/xx.png"
            />
            <Typography
              sx={{
                color: currentTheme == "DARK" ? "#fff" : "#000",
                fontSize: "25px",
                marginRight: { sm: "410px", xs: "16px" },
              }}
              fontWeight={"600"}
              mt={3}
            >
              Testimonial
            </Typography>
            <Typography
              mt={5}
              sx={{
                color: "black",
                width: { sm: "432px", xs: "300px" },
                paddingX: "30px",
                textAlign: "left",
                fontSize: { sm: "18px", xs: "15px" },
                marginRight: { sm: "170px", xs: "0px" },
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              fontWeight={"400"}
            >
              Additional Comments/description:
            </Typography>
            {/* 
        <FormTextField
          id="filled-multiline-flexible"
          placeholder="Type here..."
          fullWidth
          multiline
          inputProps={{
            style: {
              marginBottom: "0px",
              height: "26vh",
              width: "530px",
            },
          }}
          variant="outlined"
          // name="firstName"
          value={description}
          // onChange={handleChange}
          onChange={(e) => setDescription(e.target.value)}
          // onBlur={handleBlur}
          // error={touched.description && Boolean(errors.description)}
          // helperText={touched.description && errors.description}
          FormHelperTextProps={{
            style: {
              marginLeft: "0px",
            },
          }}
        /> */}
            <FormControl fullWidth>
              <TextField
                id="filled-multiline-flexible"
                placeholder="Type here..."
                fullWidth
                multiline
                rows={10}
                style={{ backgroundColor: "#fff" }}
                inputProps={{
                  maxLength: 300,
                  style: {
                    backgroundColor: "#fff",
                  },
                }}
                variant="outlined"
                value={description}
                onChange={handleDescriptionChange}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
              />
              {limitExceeded && (
                <FormHelperText sx={{ color: "red" }}>
                  Word limit exceeded (maximum 300 words).
                </FormHelperText>
              )}
            </FormControl>

            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginY: "30px",
                width: "110px",

                fontSize: "16px",
                textTransform: "none",
                borderRadius: "10px",
              }}
              variant="contained"
              disabled={isLoading || description.length < 1}
              onClick={() => {
                //
                giveTestimonials();
                // onClose();
              }}
            >
              Submit
              {isLoading && (
                <CircularProgress
                  size={16} // Set the size of CircularProgress
                  sx={{
                    marginLeft: "10px", // Half of the size for horizontal centering
                    color: "white", // Set the color of CircularProgress
                  }}
                />
              )}
            </Button>
          </Box>
        )}

        {type === "cohort" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "30px",
              // height: "350px",
              width: "600px",
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            {/* <Logout style={{ fontSize: "1.9em" }} /> */}
            <img
              onClick={onClose}
              style={{
                position: "absolute",
                top: "15px",
                left: "560px",
                cursor: "pointer",
              }}
            />
            <img width={"100vw"} src="/images/Frame.png" />

            <Typography
              sx={{ fontSize: "28px" }}
              width="300px"
              textAlign="center"
              fontWeight={"500"}
              mt={4}
            >
              Are you interested in joining this cohort?
            </Typography>

            <Box
              display="flex"
              width="340px"
              justifyContent="space-around"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  navigate("/cohort-assessment", {
                    state: { joinCohortId: joinCohortId },
                  });
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        )}
        {type === "cohortRequest" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "30px",
              // height: "350px",
              width: "600px",
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              color: currentTheme == "DARK" ? "#fff" : "#000",
              padding: "40px",
            }}
          >
            <img width={"60vw"} src="/images/check.png" />

            <Typography
              sx={{ fontSize: "24px" }}
              width="500px"
              textAlign="center"
              fontWeight={"500"}
              mt={4}
            >
              Thank you for submitting your request. Access to this cohort will
              be reviewed shortly.
            </Typography>

            <Box
              display="flex"
              width="340px"
              justifyContent="space-around"
              mt={4}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={onClose}
              >
                Okay
              </Button>
            </Box>
          </Box>
        )}

        {type === "cohort-category" && (
          <Box
            sx={{
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",

              // height: "350px",
              width: "600px",
              borderRadius: "20px",
              position: "relative",
              background: currentTheme == "DARK" ? "#494949" : "#fff",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", padding: "20px" }}
            >
              <div>
                <ArrowBackIcon
                  style={{
                    cursor: "pointer",
                    color: currentTheme == "DARK" ? "#fff" : "#000",
                  }}
                  onClick={() => onClose()}
                />
              </div>

              <Typography
                sx={{
                  fontSize: "24px",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                width="300px"
                textAlign="center"
                fontWeight={"700"}
              >
                Create Your Topic
              </Typography>
            </div>
            <hr />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: "3rem",
                marginTop: "40px",
              }}
            >
              {" "}
              <FormControl>
                <label
                  style={{
                    textAlign: "left",
                    color: currentTheme == "DARK" ? "#fff" : "#000",
                  }}
                >
                  Topic
                </label>
                <TextField
                  value={topicName}
                  onChange={(e) => {
                    setTopicName(e.target.value);
                    console.log(topicName?.length);
                  }}
                  // onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    maxLength: 50,
                    style: {
                      height: "10px",
                      backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
                    },
                  }}
                  variant="outlined"
                  name="name"
                  placeholder="Enter your topic"
                  // error={touched.name && Boolean(errors.name)}
                  // helperText={touched.name && errors.name}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
              <Box>
                <Typography>Post</Typography>
                <Divider />
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    multiline
                    rows={10}
                    value={inputStr}
                    onChange={(e) => setInputStr(e.target.value)}
                    inputProps={{ maxLength: 480 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <SentimentSatisfiedAltIcon
                      onClick={() => setOpenEmoji(!openEmoji)}
                    />{" "}
                    {`${inputStr?.length}/480`}
                  </div>
                  <div style={{ zIndex: 10 }}>
                    {openEmoji == true && (
                      <EmojiPicker
                        onEmojiClick={onEmojiClick1}
                        height={500}
                        width={440}
                      />
                    )}
                  </div>
                </FormControl>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Button
                  disabled={loadingPost || inputStr?.length === 0}
                  // onClick={addCohortPosts}
                  style={{
                    opacity: inputStr?.length === 0 ? "0.9" : "1",
                    background:
                      "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                    color: inputStr?.length === 0 ? "#00000042" : "#fff",
                    padding: "10px",
                    borderRadius: "10px",
                    maxWidth: "250px",
                    width: "100%",
                  }}
                >
                  Add
                </Button>
              </Box> */}
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginY: "40px",
                  width: "246px",
                  fontSize: "18px",
                  borderRadius: "15px",
                }}
                variant="contained"
                disabled={loadingTopic || topicName?.length === 0}
                onClick={() => {
                  addCohortTopics();
                }}
              >
                Add
                {loadingTopic && (
                  <CircularProgress
                    size={16}
                    sx={{
                      marginLeft: "10px",
                      color: "white",
                    }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        )}
        {type === "cohort-types" && (
          <Box
            sx={{
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",

              // height: "350px",
              width: "600px",
              borderRadius: "20px",
              position: "relative",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", padding: "20px" }}
            >
              <div
                onClick={() => {
                  onClose();
                }}
              >
                <ArrowBackIcon />
              </div>

              <Typography
                sx={{ fontSize: "20px" }}
                width="300px"
                textAlign="center"
                fontWeight={"700"}
              >
                Create Your Post
              </Typography>
            </div>
            <hr />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: "3rem",
                marginTop: "60px",
              }}
            >
              {" "}
              <FormControl>
                <TextField
                  value={chosenEmoji}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    style: {
                      height: "10px",
                    },
                  }}
                  variant="outlined"
                  name="name"
                  placeholder="Enter your topic"
                  // error={touched.name && Boolean(errors.name)}
                  // helperText={touched.name && errors.name}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0px",
                    },
                  }}
                />
                {/* {chosenEmoji ? (
                <span>You chose: {chosenEmoji}</span>
              ) : (
                <span>No emoji Chosen</span>
              )} */}
                <Picker onEmojiClick={onEmojiClick} />
              </FormControl>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginY: "40px",
                  width: "246px",
                  fontSize: "18px",
                  borderRadius: "15px",
                }}
                variant="contained"
              >
                Add
              </Button>
            </Box>
          </Box>
        )}
        {type === "report-post" && (
          <Box
            sx={{
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",

              // height: "350px",
              width: "600px",
              borderRadius: "20px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              position: "relative",
            }}
          >
            {/* <Logout style={{ fontSize: "1.9em" }} /> */}
            <Box
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "15px",
                left: { sm: "560px", xs: "280px" },
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                padding: "5px",
              }}
              src="/images/xx.png"
              component="img"
            />
            <div
              style={{ display: "flex", alignItems: "center", padding: "20px" }}
            >
              <div>
                <ArrowBackIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => onClose()}
                />
              </div>

              <Typography
                sx={{ fontSize: "24px" }}
                width="300px"
                textAlign="center"
                fontWeight={"700"}
              >
                Report Post
              </Typography>
            </div>
            <hr />
            <Typography
              sx={{ fontSize: "20px" }}
              fontWeight={"500"}
              mt={4}
              ml={4}
              color="gray"
            >
              Tell us why you'd like to report this post?
            </Typography>
            <FormControl
              component="fieldset"
              variant="outlined"
              sx={{ width: "100%", paddingX: "30px" }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%" }}
                multiline
                rows={9}
                value={reportString}
                onChange={(e) => setReportString(e.target.value)}
                inputProps={{ maxLength: 480 }}
              />
            </FormControl>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: "432px",
                paddingX: "30px",
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            ></Typography>
            <Box
              display="flex"
              width={{ sm: "340px", xs: "300px" }}
              justifyContent="space-around"
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  reportPost();

                  onClose();
                }}
              >
                Report
              </Button>
            </Box>
          </Box>
        )}
        {type === "report-comment" && (
          <Box
            sx={{
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",

              // height: "350px",
              width: "600px",
              borderRadius: "20px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              position: "relative",
            }}
          >
            {/* <Logout style={{ fontSize: "1.9em" }} /> */}
            <Box
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "15px",
                left: { sm: "560px", xs: "280px" },
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                padding: "5px",
              }}
              src="/images/xx.png"
              component="img"
            />
            <div
              style={{ display: "flex", alignItems: "center", padding: "20px" }}
            >
              <div>
                <ArrowBackIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => onClose()}
                />
              </div>

              <Typography
                sx={{ fontSize: "24px" }}
                width="300px"
                textAlign="center"
                fontWeight={"700"}
              >
                Report Comment
              </Typography>
            </div>
            <hr />
            <Typography
              sx={{ fontSize: "20px" }}
              fontWeight={"500"}
              mt={4}
              ml={4}
              color="gray"
            >
              Tell us why you'd like to report this comment?
            </Typography>
            <FormControl
              component="fieldset"
              variant="outlined"
              sx={{ width: "100%", paddingX: "30px" }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                sx={{ width: "100%" }}
                multiline
                rows={9}
                value={reportCommentString}
                onChange={(e) => setReportCommentString(e.target.value)}
                inputProps={{ maxLength: 480 }}
              />
            </FormControl>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: "432px",
                paddingX: "30px",
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            ></Typography>
            <Box
              display="flex"
              width={{ sm: "340px", xs: "300px" }}
              justifyContent="space-around"
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  reportComment();

                  onClose();
                }}
              >
                Report
              </Button>
            </Box>
          </Box>
        )}
        {type === "delete-post" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "30px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              // height: "350px",
              width: { sm: "600px", xs: "320px" },
              borderRadius: "20px",
              position: "relative",
            }}
          >
            {/* <Logout style={{ fontSize: "1.9em" }} /> */}
            <Box
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "15px",
                left: { sm: "560px", xs: "280px" },
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                padding: "5px",
              }}
              src="/images/xx.png"
              component="img"
            />
            <DeleteIcon sx={{ color: "purple", fontSize: "60px" }} />
            <Typography
              sx={{
                fontSize: "28px",
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              width="300px"
              textAlign="center"
              fontWeight={"500"}
              mt={4}
            >
              Are you sure you want to delete this post?
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: "432px",
                paddingX: "30px",
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            ></Typography>
            <Box
              display="flex"
              width={{ sm: "340px", xs: "300px" }}
              justifyContent="space-around"
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  deletePost();
                  console.log("ghghghg");

                  // onClose();
                  // toast.success("Post reported successfully", {
                  //   position: toast.POSITION.TOP_RIGHT,
                  // });
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        )}
        {type === "delete-comment" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "30px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              // height: "350px",
              width: { sm: "600px", xs: "320px" },
              borderRadius: "20px",
              position: "relative",
            }}
          >
            {/* <Logout style={{ fontSize: "1.9em" }} /> */}
            <Box
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "15px",
                left: { sm: "560px", xs: "280px" },
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                padding: "5px",
              }}
              src="/images/xx.png"
              component="img"
            />
            <DeleteIcon sx={{ color: "purple", fontSize: "60px" }} />
            <Typography
              sx={{
                fontSize: "28px",
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              width="300px"
              textAlign="center"
              fontWeight={"500"}
              mt={4}
            >
              Are you sure you want to delete this comment?
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: "432px",
                paddingX: "30px",
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            ></Typography>
            <Box
              display="flex"
              width={{ sm: "340px", xs: "300px" }}
              justifyContent="space-around"
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  deleteComment();
                  console.log("ghghghg");

                  // onClose();
                  // toast.success("Post reported successfully", {
                  //   position: toast.POSITION.TOP_RIGHT,
                  // });
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        )}
        {type === "liveAnnouncement" && (
          <Box
            className="wordBreak"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              width: { sm: "600px", xs: "320px" },
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              // height: "480px",
            }}
          >
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: "25px" }}
              textAlign="center"
              fontWeight={"600"}
              color="#523168"
            >
              {/* Session Details */}
            </Typography>

            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "500px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h6"
              fontWeight={"500"}
            >
              {liveTitle}
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "500px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            >
              {liveDescription}
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "500px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle1"
              fontWeight={"500"}
            >
              Date:- <span style={{ fontWeight: "400" }}>{meetingDate}</span>
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: { sm: "500px", xs: "300px" },
                paddingX: "30px",
                textAlign: "center",
                marginTop: "10px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle1"
              fontWeight={"500"}
            >
              Time:-{" "}
              <span style={{ fontWeight: "400" }}>
                {meetingTime}-{endMeetingTime}
              </span>
            </Typography>
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                fontSize: "18px",
                marginTop: "20px",
                width: "200px",
                textTransform: "none",
                borderRadius: "8px",
              }}
              variant="contained"
              onClick={onClose}
            >
              Ok
            </Button>
          </Box>
        )}
        {type === "sessionlogout" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "30px",
              // height: "350px",
              width: "600px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              borderRadius: "20px",
              position: "relative",
            }}
          >
            {/* <Logout style={{ fontSize: "1.9em" }} /> */}
            <img
              onClick={onClose}
              style={{
                position: "absolute",
                top: "15px",
                left: "560px",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                padding: "5px",
              }}
              src="/images/xx.png"
            />
            <img src="/images/logoutpopup.png" />
            <Typography
              sx={{
                fontSize: "28px",
                color: currentTheme == "DARK" ? "#fff" : "#000",
              }}
              width="300px"
              textAlign="center"
              fontWeight={"500"}
              mt={4}
            >
              Are you sure you want to logout this session?
            </Typography>
            <Typography
              mt={2}
              sx={{
                color: "rgba(32, 33, 35, 0.60)",
                width: "432px",
                paddingX: "30px",
                textAlign: "center",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"500"}
            ></Typography>
            <Box
              display="flex"
              width="340px"
              justifyContent="space-around"
              mx="auto"
              mt={4}
            >
              <Button
                sx={{
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  border: "0.747px solid var(--Linear, #E46703)",
                  color: "black",
                  textTransform: "none",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  marginBottom: "40px",
                  width: "140px",
                  fontSize: "18px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => {
                  sessionLogout();
                  // sessionStorage.removeItem("userToken");
                  // navigate("/");
                  onClose();
                  // toast.success("Session logged out successfully.", {
                  //   position: toast.POSITION.TOP_RIGHT,
                  // });
                }}
              >
                Ok
              </Button>
            </Box>
          </Box>
        )}
        {type === "updateDetails" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "50px",
              height: "450px",
              width: { sm: "600px", xs: "300px" },
              backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
            }}
          >
            <Box
              component="img"
              sx={{ width: "200px", marginBottom: "30px" }}
              src={"/images/Frame.png"}
            ></Box>
            <Typography
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="h4"
              sx={{ fontSize: { sm: "30px", xs: "18px" } }}
              fontWeight={"600"}
            >
              Details updated successfully.
            </Typography>

            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                width: "200px",
                fontSize: "18px",
                marginTop: "40px",
                width: { sm: "200px", xs: "200px" },
                textTransform: "none",
                borderRadius: "10px",
              }}
              variant="contained"
              onClick={() => {
                onClose();
                // toast.success("Password reset successfully", {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              }}
            >
              Ok
            </Button>
          </Box>
        )}

        {type === "updateEmail" && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "30px 20px",
                // height: "350px",
                background: currentTheme == "DARK" ? "#151515" : "#fff",
                width: { sm: "600px", xs: "300px" },
                borderRadius: "20px",
                position: "relative",
              }}
            >
              <Box
                onClick={onClose}
                sx={{
                  position: "absolute",
                  top: "15px",
                  left: { sm: "565px", xs: "270px" },
                  cursor: "pointer",
                  borderRadius: "50%",
                  backgroundColor: currentTheme == "DARK" ? "#fff" : "",
                }}
                src="/images/xx.png"
                component="img"
              />
              <Typography
                sx={{
                  fontSize: { sm: "25px", xs: "18px" },
                  // marginRight: { sm: "60px", xs: "10px" },
                }}
                fontWeight={"600"}
              >
                Authenticate your email
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#d32f2f",
                  fontSize: "18px",
                }}
                fontWeight={"500"}
                mt={2}
              >
                {errorMessage}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(22, 30, 41, 0.75)",
                  width: { sm: "432px", xs: "280px" },
                  paddingX: "30px",
                  fontSize: "14px",
                }}
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                variant="subtitle2"
                fontWeight={"300"}
                mt={2}
                className="wordBreak"
              >
                Please confirm your account by entering the authentication code
                sent to{" "}
                <span style={{ fontWeight: 600 }}>
                  {sessionStorage.getItem("newEmail")}
                </span>
              </Typography>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                inputType="number"
                renderSeparator={
                  <Box display={"inline"} mx={1}>
                    {" "}
                  </Box>
                }
                containerStyle={{
                  width: "100%",
                  display: "flex",
                  marginTop: "30px",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  margin: "30px 10px 0px 10px",
                }}
                inputStyle={{
                  minWidth: "11%",
                  height: "10%",
                  borderRadius: "10px",
                  border: "1px solid rgba(82, 49, 104, 0.60)",
                }}
                renderInput={(props) => (
                  <input
                    style={{
                      width: "40px", // Set the width as per your design
                      height: "40px", // Set the height as per your design
                      fontSize: "16px", // Set the font size as per your design
                      border: "1px solid #ccc", // Set the border as per your design
                      borderRadius: "4px", // Set the border radius as per your design
                      margin: "4px", // Set the margin as per your design
                      textAlign: "center", // Center the text within the input
                      // Add any other styles you want to customize
                    }}
                    onKeyPress={handleKeyPress}
                    {...props}
                  />
                )}
              />
              {emailMinutes > 0 || emailSeconds > 0 ? (
                <Box
                  mt={2}
                  mx="auto"
                  sx={{
                    width: `calc(55px * 5)`,
                    textAlign: "right",
                    color: "purple",
                    fontSize: "18px",
                  }}
                >
                  {String(emailMinutes).padStart(2, "0").replace("-", "")}:
                  {String(emailSeconds).padStart(2, "0")}
                </Box>
              ) : (
                <div></div>
              )}
              <Box
                display="flex"
                width={{ sm: "340px", xs: "255px" }}
                justifyContent="space-around"
                mx="auto"
                mt={2}
              >
                <Button
                  sx={{
                    background:
                      "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                    marginX: "auto",
                    // marginBottom: "40px",
                    width: "111px",
                    fontSize: "16px",
                    textTransform: "none",
                    borderRadius: "10px",
                  }}
                  disabled={loading || !otp || otp?.toString()?.length < 4}
                  variant="contained"
                  onClick={() => {
                    console.log(otp?.toString()?.length, "absvdfjkas");
                    if (!otp || otp?.toString()?.length < 4) {
                      toast.error("Otp is required");
                      return;
                    }
                    newEmailOtpHandler();
                  }}
                >
                  Submit
                  {loading && (
                    <CircularProgress
                      size={16} // Set the size of CircularProgress
                      sx={{
                        marginLeft: "10px", // Half of the size for horizontal centering
                        color: "white", // Set the color of CircularProgress
                      }}
                    />
                  )}
                </Button>
              </Box>
              <Typography
                color="rgba(22, 30, 41, 0.75);

"
                style={{ marginTop: "20px" }}
              >
                I don’t receive a code.
              </Typography>
              {/* {emailMinutes <= 0 ||
          (emailSeconds <= 0 && ( */}
              <Button
                onClick={() => {
                  resendOtp();
                  setErrorMessage("");
                  toast.success("OTP resent successsfully.");
                }}
                mx="auto"
                variant="text"
                sx={{
                  color: "purple",
                  "&:hover": {
                    color: " #e55f18",
                  },
                }}
                type="submit"
                disabled={emailSeconds > 1 || emailMinutes > 0}
              >
                RESEND?
              </Button>
              {/* ))} */}
            </Box>
          </>
        )}
        {type === "updateSms" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px 20px",
              height: "450px",
              width: "600px",
              background: currentTheme == "DARK" ? "#151515" : "#fff",
              borderRadius: "20px",
              position: "relative",
            }}
          >
            <img
              onClick={onClose}
              style={{
                position: "absolute",
                top: "15px",
                left: "550px",
                cursor: "pointer",
                borderRadius: "50%",
                backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
                padding: "5px",
              }}
              src="/images/xx.png"
            />
            <Typography
              // sx={{ fontSize: "25px", marginRight: "60px" }}
              fontWeight={"600"}
            >
              Authenticate your phone number
            </Typography>
            <Typography
              sx={{
                color: "rgba(22, 30, 41, 0.75)",
                width: "432px",
                paddingX: "30px",
                fontSize: "14px",
              }}
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              variant="subtitle2"
              fontWeight={"300"}
              mt={2}
            >
              Please confirm your account by entering the authentication code
              send to{" "}
              <span style={{ fontWeight: 600 }}>
                {sessionStorage.getItem("newSms")}.
              </span>
            </Typography>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              inputType="number"
              renderSeparator={
                <Box display={"inline"} mx={1}>
                  {" "}
                </Box>
              }
              containerStyle={{
                width: "100%",
                display: "flex",
                marginTop: "30px",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "30px 10px 0px 10px",
              }}
              inputStyle={{
                minWidth: "55px",
                height: "55px",
                borderRadius: "10px",
                border: "1px solid rgba(82, 49, 104, 0.60)",
              }}
              renderInput={(props) => (
                <input
                  style={{
                    width: "40px", // Set the width as per your design
                    height: "40px", // Set the height as per your design
                    fontSize: "16px", // Set the font size as per your design
                    border: "1px solid #ccc", // Set the border as per your design
                    borderRadius: "4px", // Set the border radius as per your design
                    margin: "4px", // Set the margin as per your design
                    textAlign: "center", // Center the text within the input
                    // Add any other styles you want to customize
                  }}
                  onKeyPress={handleKeyPress}
                  {...props}
                />
              )}
            />
            {emailMinutes > 0 || emailSeconds > 0 ? (
              <Box
                mt={2}
                mx="auto"
                sx={{
                  width: { sm: `calc(55px * 5)`, xs: `calc(40px * 5)` },
                  textAlign: "right",
                  color: "purple",
                  fontSize: "18px",
                }}
              >
                {String(emailMinutes).padStart(2, "0").replace("-", "")}:
                {String(emailSeconds).padStart(2, "0")}
              </Box>
            ) : (
              <div></div>
            )}
            <Box
              display="flex"
              width="340px"
              justifyContent="space-around"
              mx="auto"
              mt={2}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  // marginBottom: "40px",
                  width: "111px",
                  fontSize: "16px",
                  textTransform: "none",
                  borderRadius: "10px",
                }}
                disabled={loading || !otp || otp?.toString()?.length < 4}
                variant="contained"
                onClick={() => {
                  console.log(otp?.toString()?.length, "absvdfjkas");
                  if (!otp || otp?.toString()?.length < 4) {
                    toast.error("Otp is required");
                    return;
                  }
                  newSmsOtpHandler();
                }}
              >
                Submit
                {loading && (
                  <CircularProgress
                    size={16} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Box>
            <Typography
              color="rgba(22, 30, 41, 0.75);

"
              style={{ marginTop: "20px" }}
            >
              I don’t receive a code.
            </Typography>
            {/* {emailMinutes <= 0 ||
          (emailSeconds <= 0 && ( */}
            <Button
              onClick={() => {
                // reSendOtp();
                reSendOtp2();
                // toast.success("OTP resent successsfully.");
              }}
              mx="auto"
              variant="text"
              sx={{
                color: "purple",
                "&:hover": {
                  color: " #e55f18",
                },
              }}
              type="submit"
              disabled={seconds > 1 || minutes > 0}
            >
              RESEND?
            </Button>
            {/* ))} */}
          </Box>
        )}
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}
        open={openUpdated}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "50px",
            height: "450px",
            width: { sm: "600px", xs: "320px" },
            borderRadius: "20px",
          }}
        >
          <Box
            component="img"
            sx={{ width: "200px", marginBottom: "30px" }}
            src={"/images/Frame.png"}
          ></Box>
          <Typography
            style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            variant="h4"
            sx={{ fontSize: "20px", textAlign: "center" }}
            fontWeight={"600"}
          >
            Congratulations! Your email has been successfully updated. To ensure
            the security of your account, please log in again using your newly
            updated email.
          </Typography>

          <Button
            sx={{
              background:
                "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
              marginX: "auto",
              marginBottom: "40px",
              width: "200px",
              fontSize: "18px",
              marginTop: "40px",
              width: { sm: "200px", xs: "200px" },
              textTransform: "none",
              borderRadius: "10px",
            }}
            disabled={updateLoading}
            variant="contained"
            onClick={async () => {
              await logOutHandler();
              navigate("/login");

              // toast.success("Password reset successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }}
          >
            Ok
          </Button>
        </Box>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}
        open={openUpdatedSms}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "50px",
            height: "450px",
            width: { sm: "600px", xs: "320px" },
            borderRadius: "20px",
          }}
        >
          <Box
            component="img"
            sx={{ width: "200px", marginBottom: "30px" }}
            src={"/images/Frame.png"}
          ></Box>
          <Typography
            style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            variant="h4"
            sx={{ fontSize: "20px", textAlign: "center" }}
            fontWeight={"600"}
          >
            Congratulations! Your mobile number has been successfully updated.
          </Typography>

          <Button
            sx={{
              background:
                "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
              marginX: "auto",
              marginBottom: "40px",
              width: "200px",
              fontSize: "18px",
              marginTop: "40px",
              width: { sm: "200px", xs: "200px" },
              textTransform: "none",
              borderRadius: "10px",
            }}
            disabled={updateLoading}
            variant="contained"
            onClick={async () => {
              navigate("/dashboard");
              handleClose();

              // toast.success("Password reset successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }}
          >
            Ok
          </Button>
        </Box>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}
        open={emailUpdateModalOpen}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 20px",
            // height: "350px",
            width: { sm: "600px", xs: "300px" },
            borderRadius: "20px",
            position: "relative",
          }}
        >
          <Box
            onClick={handleChangeEmailClose}
            sx={{
              position: "absolute",
              top: "15px",
              left: { sm: "567px", xs: "270px" },
              cursor: "pointer",
              borderRadius: "50%",
              backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
              padding: "5px",
            }}
            src="/images/xx.png"
            component="img"
          />
          <Typography
            sx={{
              fontSize: { sm: "25px", xs: "18px" },
              // marginRight: { sm: "60px", xs: "10px" },
            }}
            fontWeight={"600"}
          >
            Authenticate Your Account
          </Typography>
          <Typography
            sx={{
              color: "rgba(22, 30, 41, 0.75)",
              paddingX: "30px",
              fontSize: "14px",
              width: { sm: "432px", xs: "280px" },
              textAlign: "center",
            }}
            style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            variant="subtitle2"
            fontWeight={"300"}
            mt={2}
          >
            Please confirm your account by entering the authentication code send
            to{" "}
            <span style={{ fontWeight: 600 }}>
              {currentStatus === "email" ? email : mobile}.
            </span>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "#d32f2f",
              fontSize: "18px",
            }}
            fontWeight={"500"}
            mt={2}
          >
            {errorMessage}
          </Typography>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType="number"
            renderSeparator={
              <Box display={"inline"} mx={1}>
                {" "}
              </Box>
            }
            containerStyle={{
              width: "100%",
              display: "flex",
              marginTop: "30px",
              flexWrap: "wrap",
              justifyContent: "center",
              margin: "30px 10px 0px 10px",
            }}
            inputStyle={{
              minWidth: "11%",
              height: "10%",
              borderRadius: "10px",
              border: "1px solid rgba(82, 49, 104, 0.60)",
            }}
            renderInput={(props) => (
              <input
                style={{
                  width: "40px", // Set the width as per your design
                  height: "40px", // Set the height as per your design
                  fontSize: "16px", // Set the font size as per your design
                  border: "1px solid #ccc", // Set the border as per your design
                  borderRadius: "4px", // Set the border radius as per your design
                  margin: "4px", // Set the margin as per your design
                  textAlign: "center", // Center the text within the input
                  // Add any other styles you want to customize
                }}
                onKeyPress={handleKeyPress}
                {...props}
              />
            )}
          />
          {emailMinutes > 0 || emailSeconds > 0 ? (
            <Box
              mt={2}
              mx="auto"
              sx={{
                width: `calc(55px * 5)`,
                textAlign: "right",
                color: "purple",
                fontSize: "18px",
              }}
            >
              {String(emailMinutes).padStart(2, "0").replace("-", "")}:
              {String(emailSeconds).padStart(2, "0")}
            </Box>
          ) : (
            <div></div>
          )}
          <Box
            display="flex"
            width={{ sm: "340px", xs: "255px" }}
            justifyContent="space-around"
            mx="auto"
            mt={2}
          >
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                // marginBottom: "40px",
                width: "111px",
                fontSize: "16px",
                textTransform: "none",
                borderRadius: "10px",
              }}
              disabled={loading || !otp || otp?.toString()?.length < 4}
              variant="contained"
              onClick={() => {
                console.log(otp?.toString()?.length, "absvdfjkas");
                if (!otp || otp?.toString()?.length < 4) {
                  toast.error("Otp is required");
                  return;
                }
                otpHandler1();
              }}
            >
              Submit
              {loading && (
                <CircularProgress
                  size={16} // Set the size of CircularProgress
                  sx={{
                    marginLeft: "10px", // Half of the size for horizontal centering
                    color: "white", // Set the color of CircularProgress
                  }}
                />
              )}
            </Button>
          </Box>
          <Typography
            color="rgba(22, 30, 41, 0.75);

"
            style={{ marginTop: "20px" }}
          >
            I don’t receive a code.
          </Typography>
          {/* {emailMinutes <= 0 ||
          (emailSeconds <= 0 && ( */}
          <Button
            onClick={() => {
              // reSendOtp();
              handleChangeEmail();
              // toast.success("OTP resent successsfully.");
            }}
            mx="auto"
            variant="text"
            sx={{
              color: "purple",
              "&:hover": {
                color: " #e55f18",
              },
            }}
            type="submit"
            disabled={emailSeconds > 1 || emailMinutes > 0}
          >
            RESEND?
          </Button>
          {/* ))} */}
        </Box>
        <PasswordEmailChangeModal
          open={changeModal}
          setOpen={setChangeModal}
          // funcToExecute={emailUpdateHandler}
          message={currentMessage}
          type={currentType}
          currentData={currentData}
          currentStatus={currentStatus}
        />
      </Dialog>
    </>
  );
};

export default PasswordResetModal;

// {
//   type === "authenticateSms" && (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         padding: "30px 20px",
//         // height: "350px",
//         minWidth: "500px",
//         borderRadius: "20px",
//         position: "relative",
//       }}
//     >
//       <img
//         onClick={onClose}
//         style={{
//           position: "absolute",
//           top: "15px",
//           left: "467px",
//           cursor: "pointer",
//         }}
//         src="/images/xx.png"
//       />
//       <Typography
//         sx={{ fontSize: "25px", marginRight: "60px" }}
//         fontWeight={"600"}
//       >
//         Authenticate Your Account
//       </Typography>
//       <Typography
//         sx={{
//           color: "rgba(22, 30, 41, 0.75)",
//           width: "432px",
//           paddingX: "30px",
//           fontSize: "14px",
//         }}
//         variant="subtitle2"
//         fontWeight={"300"}
//         mt={2}
//       >
//         Please confirm your account by entering the authentication code send to{" "}
//         <span style={{ fontWeight: 600 }}>{mobile}.</span>
//       </Typography>
//       <OtpInput
//         value={otp}
//         onChange={setOtp}
//         numInputs={4}
//         inputType="number"
//         renderSeparator={
//           <Box display={"inline"} mx={1}>
//             {" "}
//           </Box>
//         }
//         containerStyle={{
//           width: "100%",
//           display: "flex",
//           marginTop: "30px",
//           flexWrap: "wrap",
//           justifyContent: "center",
//           margin: "30px 10px 0px 10px",
//         }}
//         inputStyle={{
//           minWidth: "55px",
//           height: "55px",
//           borderRadius: "10px",
//           border: "1px solid rgba(82, 49, 104, 0.60)",
//         }}
//         renderInput={(props) => (
//           <input
//             style={{
//               width: "40px", // Set the width as per your design
//               height: "40px", // Set the height as per your design
//               fontSize: "16px", // Set the font size as per your design
//               border: "1px solid #ccc", // Set the border as per your design
//               borderRadius: "4px", // Set the border radius as per your design
//               margin: "4px", // Set the margin as per your design
//               textAlign: "center", // Center the text within the input
//               // Add any other styles you want to customize
//             }}
//             onKeyPress={handleKeyPress}
//             {...props}
//           />
//         )}
//       />
//       {minutes > 0 || seconds > 0 ? (
//         <Box
//           mt={2}
//           mx="auto"
//           sx={{
//             width: `calc(55px * 5)`,
//             textAlign: "right",
//             color: "purple",
//             fontSize: "18px",
//           }}
//         >
//           {String(minutes).padStart(2, "0").replace("-", "")}:
//           {String(seconds).padStart(2, "0")}
//         </Box>
//       ) : (
//         <div></div>
//       )}
//       <Box
//         display="flex"
//         width="340px"
//         justifyContent="space-around"
//         mx="auto"
//         mt={2}
//       >
//         <Button
//           sx={{
//             background:
//               "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
//             marginX: "auto",
//             // marginBottom: "40px",
//             width: "111px",
//             fontSize: "16px",
//             textTransform: "none",
//             borderRadius: "10px",
//           }}
//           variant="contained"
//           disabled={loading || otp.length < 4}
//           onClick={() => {
//             otpHandler("sms");
//           }}
//         >
//           Submit
//           {loading && (
//             <CircularProgress
//               size={16} // Set the size of CircularProgress
//               sx={{
//                 marginLeft: "10px", // Half of the size for horizontal centering
//                 color: "white", // Set the color of CircularProgress
//               }}
//             />
//           )}
//         </Button>
//       </Box>
//       <Typography
//         color="rgba(22, 30, 41, 0.75);

// "
//         style={{ marginTop: "20px" }}
//       >
//         I don’t receive a code.
//       </Typography>
//       {/* {minutes <= 0 ||
//             (seconds <= 0 && ( */}
//       <Button
//         onClick={() => {
//           // reSendOtp();
//           authenticateSms();
//         }}
//         mx="auto"
//         variant="text"
//         sx={{
//           color: "purple",
//           "&:hover": {
//             color: " #e55f18",
//           },
//         }}
//         type="submit"
//       >
//         RESEND?
//       </Button>
//       {/* ))} */}
//     </Box>
//   );
// }
// {
//   type === "authenticateEmail" && (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         padding: "30px 20px",
//         // height: "350px",
//         minWidth: "500px",
//         borderRadius: "20px",
//         position: "relative",
//       }}
//     >
//       <img
//         onClick={onClose}
//         style={{
//           position: "absolute",
//           top: "15px",
//           left: "467px",
//           cursor: "pointer",
//         }}
//         src="/images/xx.png"
//       />
//       <Typography
//         sx={{ fontSize: "25px", marginRight: "60px" }}
//         fontWeight={"600"}
//       >
//         Authenticate Your Account
//       </Typography>
//       <Typography
//         sx={{
//           color: "rgba(22, 30, 41, 0.75)",
//           width: "432px",
//           paddingX: "30px",
//           fontSize: "14px",
//         }}
//         variant="subtitle2"
//         fontWeight={"300"}
//         mt={2}
//       >
//         Please confirm your account by entering the authentication code send to{" "}
//         <span style={{ fontWeight: 600 }}>{email}</span>
//       </Typography>
//       <OtpInput
//         value={otp}
//         onChange={setOtp}
//         numInputs={4}
//         inputType="number"
//         renderSeparator={
//           <Box display={"inline"} mx={1}>
//             {" "}
//           </Box>
//         }
//         containerStyle={{
//           width: "100%",
//           display: "flex",
//           marginTop: "30px",
//           flexWrap: "wrap",
//           justifyContent: "center",
//           margin: "30px 10px 0px 10px",
//         }}
//         inputStyle={{
//           minWidth: "55px",
//           height: "55px",
//           borderRadius: "10px",
//           border: "1px solid rgba(82, 49, 104, 0.60)",
//         }}
//         renderInput={(props) => (
//           <input
//             style={{
//               width: "40px", // Set the width as per your design
//               height: "40px", // Set the height as per your design
//               fontSize: "16px", // Set the font size as per your design
//               border: "1px solid #ccc", // Set the border as per your design
//               borderRadius: "4px", // Set the border radius as per your design
//               margin: "4px", // Set the margin as per your design
//               textAlign: "center", // Center the text within the input
//               // Add any other styles you want to customize
//             }}
//             onKeyPress={handleKeyPress}
//             {...props}
//           />
//         )}
//       />
//       {emailMinutes > 0 || emailSeconds > 0 ? (
//         <Box
//           mt={2}
//           mx="auto"
//           sx={{
//             width: `calc(55px * 5)`,
//             textAlign: "right",
//             color: "purple",
//             fontSize: "18px",
//           }}
//         >
//           {String(emailMinutes).padStart(2, "0").replace("-", "")}:
//           {String(emailSeconds).padStart(2, "0")}
//         </Box>
//       ) : (
//         <div></div>
//       )}
//       <Box
//         display="flex"
//         width="340px"
//         justifyContent="space-around"
//         mx="auto"
//         mt={2}
//       >
//         <Button
//           sx={{
//             background:
//               "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
//             marginX: "auto",
//             // marginBottom: "40px",
//             width: "111px",
//             fontSize: "16px",
//             textTransform: "none",
//             borderRadius: "10px",
//           }}
//           disabled={loading || !otp || otp?.toString()?.length < 4}
//           variant="contained"
//           onClick={() => {
//             console.log(otp?.toString()?.length, "absvdfjkas");
//             if (!otp || otp?.toString()?.length < 4) {
//               toast.error("Otp is required");
//               return;
//             }
//             otpHandler("email");
//           }}
//         >
//           Submit
//           {loading && (
//             <CircularProgress
//               size={16} // Set the size of CircularProgress
//               sx={{
//                 marginLeft: "10px", // Half of the size for horizontal centering
//                 color: "white", // Set the color of CircularProgress
//               }}
//             />
//           )}
//         </Button>
//       </Box>
//       <Typography
//         color="rgba(22, 30, 41, 0.75);

// "
//         style={{ marginTop: "20px" }}
//       >
//         I don’t receive a code.
//       </Typography>
//       {/* {emailMinutes <= 0 ||
//             (emailSeconds <= 0 && ( */}
//       <Button
//         onClick={() => {
//           // reSendOtp();
//           authenticateEmail();
//           toast.success("OTP resent successsfully.");
//         }}
//         mx="auto"
//         variant="text"
//         sx={{
//           color: "purple",
//           "&:hover": {
//             color: " #e55f18",
//           },
//         }}
//         type="submit"
//       >
//         RESEND?
//       </Button>
//       {/* ))} */}
//     </Box>
//   );
// }
// {
//   type === "authenticateDisableSms" && (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         padding: "30px 20px",
//         // height: "350px",
//         width: "100%",
//         minWidth: "500px",
//         borderRadius: "20px",
//         position: "relative",
//         "@media(max-width:568px)": {
//           width: "100%",
//           minWidth: "0px",
//         },
//       }}
//     >
//       <img
//         onClick={onClose}
//         style={{
//           position: "absolute",
//           top: "15px",
//           // left: "467px",
//           right: "10px",
//           cursor: "pointer",
//         }}
//         src="/images/xx.png"
//       />
//       <Box style={{ borderRadius: "50%" }}>
//         <LockOpenIcon style={{ fontSize: "100px" }} />
//       </Box>
//       <Typography
//         mt={4}
//         sx={{ fontSize: "20px", textAlign: "center" }}
//         fontWeight={"600"}
//       >
//         Are you sure you want to disable sms authentication?
//       </Typography>

//       <Box
//         sx={{
//           display: "flex",
//           // width: "340px",
//           gap: "20px",
//           justifyContent: "space-around",
//           "@media(max-width:468px)": {
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: "0px",
//           },
//         }}
//         mx="auto"
//         mt={4}
//       >
//         <Button
//           sx={{
//             marginX: "auto",
//             marginBottom: "40px",
//             width: "140px",
//             fontSize: "18px",
//             border: "0.747px solid var(--Linear, #E46703)",
//             color: "black",
//             textTransform: "none",
//           }}
//           variant="outlined"
//           onClick={() => {
//             onClose();
//           }}
//         >
//           No
//         </Button>
//         <Button
//           sx={{
//             background:
//               "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
//             marginX: "auto",
//             marginBottom: "40px",
//             width: "140px",
//             fontSize: "18px",
//             textTransform: "none",
//           }}
//           variant="contained"
//           disable={loading}
//           // onClose={handleClose}
//           onClick={() => {
//             disableauthenticateSms();
//             onClose();
//           }}
//         >
//           Yes
//           {loading && (
//             <CircularProgress
//               size={16} // Set the size of CircularProgress
//               sx={{
//                 marginLeft: "10px", // Half of the size for horizontal centering
//                 color: "white", // Set the color of CircularProgress
//               }}
//             />
//           )}
//         </Button>
//       </Box>
//     </Box>
//   );
// }
// {
//   type === "authenticateDisableEmail" && (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         padding: "30px 20px",
//         // height: "350px",
//         minWidth: "500px",
//         borderRadius: "20px",
//         position: "relative",
//         "@media(max-width:568px)": {
//           width: "100%",
//           minWidth: "0px",
//         },
//       }}
//     >
//       <img
//         onClick={onClose}
//         style={{
//           position: "absolute",
//           top: "15px",
//           // left: "467px",
//           right: "10px",
//           cursor: "pointer",
//         }}
//         src="/images/xx.png"
//       />
//       <Box style={{ borderRadius: "50%" }}>
//         <LockOpenIcon style={{ fontSize: "100px" }} />
//       </Box>
//       <Typography
//         sx={{ fontSize: "20px", textAlign: "center" }}
//         fontWeight={"600"}
//       >
//         Are you sure you want to disable email authentication?
//       </Typography>

//       <Box
//         sx={{
//           display: "flex",
//           // width: "340px",
//           gap: "20px",
//           justifyContent: "space-around",
//           "@media(max-width:468px)": {
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: "0px",
//           },
//         }}
//         mx="auto"
//         mt={4}
//       >
//         <Button
//           sx={{
//             marginX: "auto",
//             marginBottom: "40px",
//             width: "140px",
//             fontSize: "18px",
//             border: "0.747px solid var(--Linear, #E46703)",
//             color: "black",
//             textTransform: "none",
//           }}
//           variant="outlined"
//           onClick={() => {
//             onClose();
//           }}
//         >
//           No
//         </Button>
//         <Button
//           sx={{
//             background:
//               "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
//             marginX: "auto",
//             marginBottom: "40px",
//             width: "140px",
//             fontSize: "18px",
//             textTransform: "none",
//           }}
//           variant="contained"
//           disable={loading}
//           onClick={() => {
//             disableauthenticateEmail();
//             onClose();
//           }}
//         >
//           Yes
//           {loading && (
//             <CircularProgress
//               size={16} // Set the size of CircularProgress
//               sx={{
//                 marginLeft: "10px", // Half of the size for horizontal centering
//                 color: "white", // Set the color of CircularProgress
//               }}
//             />
//           )}
//         </Button>
//       </Box>
//     </Box>
//   );
// }
// {
//   type === "testimonial" && (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "left",
//         width: { sm: "600px", xs: "320px" },

//         borderRadius: "20px",
//         position: "relative",
//         paddingX: "30px",
//       }}
//     >
//       <Box
//         onClick={onClose}
//         sx={{
//           position: "absolute",
//           top: "15px",
//           left: { sm: "560px", xs: "290px" },
//           cursor: "pointer",
//         }}
//         component={"img"}
//         src="/images/xx.png"
//       />
//       <Typography
//         sx={{
//           fontSize: "25px",
//           marginRight: { sm: "410px", xs: "16px" },
//         }}
//         fontWeight={"600"}
//         mt={3}
//       >
//         Testimonial
//       </Typography>
//       <Typography
//         mt={5}
//         sx={{
//           color: "black",
//           width: { sm: "432px", xs: "300px" },
//           paddingX: "30px",
//           textAlign: "left",
//           fontSize: { sm: "18px", xs: "15px" },
//           marginRight: { sm: "170px", xs: "0px" },
//         }}
//         fontWeight={"400"}
//       >
//         Additional Comments/description:
//       </Typography>
//       {/*
//           <FormTextField
//             id="filled-multiline-flexible"
//             placeholder="Type here..."
//             fullWidth
//             multiline
//             inputProps={{
//               style: {
//                 marginBottom: "0px",
//                 height: "26vh",
//                 width: "530px",
//               },
//             }}
//             variant="outlined"
//             // name="firstName"
//             value={description}
//             // onChange={handleChange}
//             onChange={(e) => setDescription(e.target.value)}
//             // onBlur={handleBlur}
//             // error={touched.description && Boolean(errors.description)}
//             // helperText={touched.description && errors.description}
//             FormHelperTextProps={{
//               style: {
//                 marginLeft: "0px",
//               },
//             }}
//           /> */}
//       <TextField
//         id="filled-multiline-flexible"
//         placeholder="Type here..."
//         fullWidth
//         multiline
//         inputProps={{
//           maxLength: 300,
//           style: {
//             marginBottom: "0px",
//             height: "26vh",
//             width: "530px",
//           },
//         }}
//         variant="outlined"
//         value={description}
//         // onChange={handleChange}
//         onChange={handleDescriptionChange}
//         // onBlur={handleBlur}
//         // error={touched.description && Boolean(errors.description)}
//         // helperText={touched.description && errors.description}
//         FormHelperTextProps={{
//           style: {
//             marginLeft: "0px",
//           },
//         }}
//       />
//       {limitExceeded && (
//         <FormHelperText sx={{ color: "red" }}>
//           Word limit exceeded (maximum 300 words).
//         </FormHelperText>
//       )}
//       <Button
//         sx={{
//           background: "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
//           marginX: "auto",
//           marginY: "30px",
//           width: "110px",
//           fontSize: "16px",
//           textTransform: "none",
//           borderRadius: "10px",
//         }}
//         variant="contained"
//         disabled={isLoading || description.length < 1}
//         onClick={() => {
//           //
//           giveTestimonials();
//           // onClose();
//         }}
//       >
//         Submit
//         {updateLoading && (
//           <CircularProgress
//             size={16} // Set the size of CircularProgress
//             sx={{
//               marginLeft: "10px", // Half of the size for horizontal centering
//               color: "white", // Set the color of CircularProgress
//             }}
//           />
//         )}
//       </Button>
//     </Box>
//   );
// }

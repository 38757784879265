import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import LearningLives from "./LearningLives";
import MonitorMeasure from "./MonitorMeasure";
import Welcome from "./Welcome";
import Discovery from "./Discovery";
import ActionPlanning from "./ActionPlanning";
import { useSelector } from "react-redux";
import LiveAnnouncement from "./LiveAnnouncement";
import Cookies from "js-cookie";
import CookieModal from "../../components/CookieModal";

export default function Home() {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isModalOpen, setModalOpen] = useState(false);

  const userData = {
    userId: 123,
    userName: "exampleUser",
    userPreferences: {
      language: "en",
      theme: "dark",
    },
  };

  // const setCookies = () => {
  //   Cookies.set("userData", JSON.stringify(userData), {
  //     expires: 7,
  //     path: "/",
  //   });
  //   setModalOpen(false);
  // };

  // useEffect(() => {
  //   // Check if the cookie already exists
  //   const existingUserData = Cookies.get("userData");
  //   if (existingUserData) {
  //     setModalOpen(false); // If cookie exists, close the modal
  //   } else {
  //     setModalOpen(true); // If cookie doesn't exist, open the modal
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div style={{ background: currentTheme === "DARK" ? "#151515" : "#fff" }}>
      <Welcome />
      <Discovery />
      {/* <LiveAnnouncement /> */}
      <LearningLives />
      <ActionPlanning />
      <MonitorMeasure />
{/* 
      {isModalOpen && (
        <CookieModal
          setCookies={setCookies}
          open={isModalOpen}
          // onClose={handleCloseModal}
        />
      )} */}
    </div>
  );
}
